import { Formik } from 'formik';
import React, { memo } from 'react';
import { ExploreModal } from '../modal';
import styles from './filterPage.module.scss';
import classnames from 'classnames';
import firebaseService from '../../firebase/firebase';
import { ExploreDataType } from '../../constants/types';
import { FilterForm } from './filterForm';

interface FilterPageProps {
  isMobile: boolean,
  active: boolean,
  setActive: (value: boolean) => void,
  handleClose: (value?: () => void) => void,
  setAllData: (value: Map<string, ExploreDataType> | null) => void,
}

interface ValuesType {
  name: string,
  location: string,
  discipline: string,
}

export const FilterPage: React.FC<FilterPageProps> = memo(({
  isMobile,
  active,
  setActive,
  handleClose,
  setAllData
}) => {

  const handleClear = (reset: (value: any) => void) => {
    reset({
      values: {
        name: '',
        location: '',
        discipline: '',
      }
    });
    firebaseService.getSurveyDocuments().then( (data: Map<string, ExploreDataType>) => {
      setAllData(data);
      document.dispatchEvent(new CustomEvent('grace_request_data_response',{detail:data}));
    })
    .catch((error) => {
        console.log("Error getting Survey documents: ", error);
      });
    handleClose();
  }

  const filterVoices = (values: ValuesType):Promise<any> => {
    // console.log(`Values for filters: ${values.discipline}`);
    // console.log(values);
    const filterData: any = [
      {
        path: 'userData.fullName',
        value: values.name.toLowerCase(),
      },
      {
        path: 'userData.location',
        value: values.location,
      },
      {
        path: 'userData.discipline',
        value: values.discipline,
      },
    ]
    return firebaseService.getFilteredSurveyDocuments(filterData);
      // .then((data) => {
      //   return data;
      // })
      // .catch((error) => console.log(error.message));
  }
  return (
    <ExploreModal
      isMobile={isMobile}
      active={active}
      setActive={() => handleClose()}
      title='Close filters'
      hideButton={isMobile}
    >
      <div className={classnames(styles.filterPage_wrapper, isMobile ? styles.filterPage_mobile : '')}>
        <div className={styles.filterPage_title}>
          Filter Results
        </div>
        <Formik
          initialValues={{
            name: '',
            location: '',
            discipline: '',
          }}
          onSubmit={(values, {setSubmitting}) => {
            // console.log(`Formnik submit`);
            // console.log(values);
            filterVoices(values)
              .then((data) => {
                setAllData(data);
                document.dispatchEvent(new CustomEvent('grace_request_data_response',{detail:data}));
              })
              handleClose();
              setSubmitting(false);
          }}
        >
          {({values, setFieldValue, resetForm, touched}) => (
            <FilterForm
              isMobile={isMobile}
              values={values}
              setFieldValue={setFieldValue}
              handleClear={handleClear}
              resetForm={resetForm}
              handleClose={() => handleClose()}
              filterVoices={filterVoices}
            />
          )}
        </Formik>
      </div>
    </ExploreModal>
  )
});