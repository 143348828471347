import React, { memo } from 'react';
import styles from './footer.module.scss';
import classnames from 'classnames';
import { Audio } from '../audio';
import { useLocation } from 'react-router-dom'
import { SocialButtons } from './socialButtons';
import { pathName } from '../../constants/constants';

interface FooterProps {
  modalOpen: boolean,
  playAudio: boolean,
  setPlayAudio: () => void,
  isMobile: boolean,
  breakHeight: boolean,
}

export const Footer: React.FC<FooterProps> = memo(({
  modalOpen,
  playAudio,
  setPlayAudio,
  isMobile,
  breakHeight,
}, props) => {
  const location = useLocation();
  const buttonStyle = !modalOpen ? styles.close : styles.open;

  return (
    <>
      {
        location.pathname === pathName.questionPage || location.pathname === pathName.submit 
        ? null
        : (
          <div className={classnames(styles.socialWrapper, breakHeight ? styles.socialSmall : '')}>
            <SocialButtons
              isMobile={isMobile}
              isExploreUrl={false}
              className={modalOpen ? styles.disabled : ''}
            />
          </div>
        )
      }
      <div className={classnames(styles.audio, breakHeight ? styles.audioSmall : '')}>
        {
          location.pathname === pathName.questionPage || location.pathname === pathName.submit 
          ? (
             <SocialButtons
              isMobile={isMobile}
              isExploreUrl={false}
              className={modalOpen ? styles.disabled : ''}
            />
          )
          : null
        }
        <Audio 
          className={classnames(styles.menuButton, buttonStyle)}
          playAudio={playAudio}
          setPlayAudio={setPlayAudio}
        />
      </div>
    </>
  )
})