
import * as THREE from 'three';
import { TweenMax, Back, Expo, Sine, Bounce } from 'gsap';

export default class Spritesheet {

    constructor() {

        this.x             = 0;
        this.y             = 0;
        this.count         = 0;
        this.endFrame      = null;
        this.framesX       = null;
        this.framesY       = null;
        this.frameWidth    = null;
        this.frameHeight   = null;
        this.img           = null;
        this.ctx           = null;
        this.canvasTexture = null;
        this.loaded        = false;
        this.timer         = null;
        this.show          = true;
        this.baseSprite    = null;
        this.root          = null;
        this.rotationSpeed = 0;
        this.focus         = 0;
        this.up            = true;
        this.filterCanvas  = false;
        this.filterObject  = { blur:0, opacity:1.0 };
    }

    init( imageURL, framesX, framesY, frameDelay, _endFrame, nr )
    {

        this.focus = nr;
        const queryString = window.location.search;
        const urlParams   = new URLSearchParams(queryString);

        let frameWidth, frameHeight;

        let startFrame = 0; 
        this.rotationSpeed = 0.1 + Math.random() * 0.2;
        this.endFrame  = _endFrame || framesX * framesY;
        this.framesX   = framesX;
        this.framesY   = framesY;
        let canvas     = document.createElement('canvas');   
        this.ctx       = canvas.getContext('2d');

        this.canvasTexture = new THREE.CanvasTexture(canvas);
        this.img           = imageURL.image;
        let _self = this;
        //this.img.src = imageURL.image;
        //this.img.crossOrigin = "Anonymous"
        //this.img.onload = function(){
        canvas.width  = _self.frameWidth = _self.img.width / framesX;
        canvas.height = _self.frameHeight = _self.img.height / framesY;
        _self.timer = setInterval( function() {

            if( _self.show ) {
                _self.loaded = true;
            }
            if( _self.loaded ) {

                _self._nextFrame();
                const queryString = window.location.search;
                const urlParams   = new URLSearchParams(queryString);

                if( urlParams.get('anim') ) {
                    
                    _self.loaded = true;

                } else {

                    _self.loaded = false;
                }

                if( _self.baseSprite && _self.root ) {

                    if( !_self.root.contentOpened ) _self.baseSprite.material.rotation += _self.rotationSpeed;
            
                }

            }

            //if( _self.show ) _self.show = false;


        }, frameDelay );
        _self.loaded = true;
        //}



    }

    _nextFrame()
    {   

        let _self = this;

        if( _self.root && !_self.root.contentOpened ) this.count++;


        if( this.count >= this.endFrame ) {
            this.count = 0;
        };

        this.x = (this.count % this.framesX) * this.frameWidth;
        this.y = ((this.count / this.framesY)|0) * this.frameHeight;

        this.ctx.clearRect(0, 0, this.frameWidth, this.frameHeight);
        this.ctx.drawImage(this.img, this.x, this.y, this.frameWidth, this.frameHeight, 0, 0, this.frameWidth, this.frameHeight);

        this.canvasTexture.needsUpdate = true;


    }

    _addRoot( sprite, root )
    {

        this.baseSprite = sprite;
        this.root = root;

    }


}
