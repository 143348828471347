import React, { memo, useEffect, useState } from 'react';
import Button from '../button';
import classnames from 'classnames';
import styles from './footer.module.scss';
import { Link, Facebook, Linkedin, Twitter } from '../svgIcons';

interface SocialButtonsProps {
  isMobile: boolean,
  isExploreUrl: boolean,
  className?: string,
  docId?: string | null,
}

export const SocialButtons: React.FC<SocialButtonsProps> = memo(({
  isMobile,
  isExploreUrl,
  className,
  docId,
}) => {
  const [isShare, setIsShare] = useState(false);

  useEffect(() => {
    if (navigator.share !== undefined) {
      setIsShare(true);
    } else {
      setIsShare(false);
    }
  }, []);

  const urlPrefix = isExploreUrl ? 'https://extraordinarygrace.com/explore' : 'https://extraordinarygrace.com';
  const getShareUrl = ():string => {
    return `${urlPrefix}/${docId? "/"+docId : ""}`;
  }

  const share = () => {
    if (isShare) {
      navigator.share({
        title: 'Extraordinary Grace: The future of science',
        text: "Representing diversity, inclusion, and science's next-generation, Grace's questions collect the global perspectives that paint a true picture of science.",
        url: getShareUrl(),
      }).catch(error => console.log(error.message));
    }
  }
  return (
    <div className={classnames(styles.socialButtonWrapper, className)}>
      {
        isMobile
        ? (
          <Button
            onClick={share}
            className={classnames(styles.socialButton)}
          >
            <Link />
          </Button>
        )
        : null
      }
      <Button
        onClick={() => {
          window.open(`https://www.facebook.com/sharer.php?u=${encodeURIComponent(getShareUrl())}`, "_blank");
        }}
        className={styles.socialButton}
        >
        <Facebook />
      </Button>
      <Button
        onClick={() => {
          window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(getShareUrl())}`, "_blank");
        }}
        className={classnames(styles.socialButton)}
        >
        <Linkedin />
      </Button>
      <Button
        onClick={() => {
          // window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent("https://extraordinarygrace.com")}&text=${}&via=${}&hashtags=${}}`, "_blank");
          window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(getShareUrl())}`, "_blank");
        }}
        className={classnames(styles.socialButton)}
        >
        <Twitter />
      </Button>
    </div>
  )
});