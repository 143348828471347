import React, { MouseEvent, useRef, useState } from 'react';

interface ScrollDragProps {
  children: JSX.Element,
  className: string,
}

export const ScrollDrag: React.FC<ScrollDragProps> = ({children, className}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [clientX, setClientX] = useState(0);
  const [scrollX, setScrollX] = useState(0);

  const onMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    setIsScrolling(true);
    setClientX(event.clientX);
  }

  const onMouseUp = (event: MouseEvent<HTMLDivElement>) => {
    setIsScrolling(false);
  }

  const onMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    if (isScrolling && ref.current) {
      const dragScroll = scrollX - event.clientX + clientX;
      if (dragScroll > 0 && dragScroll < ref.current.scrollWidth - ref.current.offsetWidth) {
        ref.current.scrollLeft = dragScroll;
        setScrollX(dragScroll);
        setClientX(event.clientX);
      }
    }
  }
  return (
    <div
      ref={ref}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      className={className}
    >
      {children}
    </div>
  )
};