import * as THREE from 'three';

export default class ParticleBackground {

    constructor(scene){
        this.scene = scene;
        this.init();
    }

    init(){
        let texture = new THREE.TextureLoader().load('./images/dust.png')
        let uniforms = {
            diffuseMap: { value: texture },
            posScale: { value: 1.0 },
            customSize: { value: 20.0 },
            baseSize: { value: 1650.0 },
            time: { type: 'f', value: 0.1 },
          };
  
          let shaderMaterial = new THREE.ShaderMaterial({
            uniforms: uniforms,
            vertexShader: `
  
                attribute float size;
  
                uniform float time;
                uniform float posScale;
                uniform float baseSize;
                uniform float customSize;
                varying vec3 vColor;
                varying float eAlpha;
                // varying float pAlpha;
  
                void main() {
  
                  vColor = color;
  
                  vec3 nposition  = position * posScale;
                  vec4 mvPosition = modelViewMatrix * vec4( nposition, 1.0 );
  
                  gl_PointSize = size * ( baseSize / -mvPosition.z ) * customSize;
                  gl_Position = projectionMatrix * mvPosition;
  
                }
            `,
            fragmentShader: `
  
                uniform sampler2D diffuseMap;
  
                varying vec3 vColor;
                varying float eAlpha;
                // varying float pAlpha;
  
                void main() {
  
                  gl_FragColor = texture2D( diffuseMap, gl_PointCoord );
  
                }
            `,
  
            depthTest: false,
            transparent: true,
            vertexColors: true,
          });
  
          shaderMaterial.uniforms['diffuseMap'].value = texture;
          shaderMaterial.uniforms['customSize'].value = 2.0;
  
          this.geometry_ = new THREE.BufferGeometry();
  
          let positions = [];
          let speed = [];
          let colors = [];
          let sizes = [];
          this.scale = [];
  
          let color = new THREE.Color(0x0043cf);
  
          for (let i = 0; i < 5000; i += 3) {
            positions.push(
              -300 + Math.random() * 600,
              -100 + Math.random() * 200,
              -300 + Math.random() * 600
            );
            speed.push(
              (-1.5 + Math.random() * 3.0) * Math.random() * 0.1,
              (-1.5 + Math.random() * 3.0) * Math.random() * 0.1,
              (-1.5 + Math.random() * 3.0) * Math.random() * 0.1
            );
            colors.push(color.r, color.g, color.b);
            sizes.push(1.19);
            this.scale.push([0.5 + Math.random() * 2, Math.random() * 0.008]);
          }
  
          this.geometry_.setAttribute(
            'position',
            (new THREE.Float32BufferAttribute(positions, 3)).setUsage(
              THREE.DynamicDrawUsage
            )
          );
          this.geometry_.setAttribute(
            'speed',
            (new THREE.Float32BufferAttribute(speed, 3)).setUsage(
              THREE.DynamicDrawUsage
            )
          );
          this.geometry_.setAttribute(
            'color',
            new THREE.Float32BufferAttribute(colors, 3)
          );
          this.geometry_.setAttribute(
            'size',
            (new THREE.Float32BufferAttribute(sizes, 1)).setUsage(
              THREE.DynamicDrawUsage
            )
          );
  
          this.dust  = new THREE.Points( this.geometry_, shaderMaterial);
  
          this.spriteBase = new THREE.Object3D();
          let sprites = [];
  
          this.scene.add(this.spriteBase);
          this.spriteBase.add(this.dust);
    }

    update(){
        // this.spriteBase.rotation.y += 0.0015;
        this.spriteBase.rotation.y += 0.0002;

	    let attributes = this.dust.geometry.attributes;
	    var k = 0;

	    for (let u = 0; u < attributes.position.array.length; u += 3) {
	      var p = new THREE.Vector3(
	        attributes.position.array[u],
	        attributes.position.array[u + 1],
	        attributes.position.array[u + 2]
	      );

	      attributes.position.array[u] = p.x + attributes.speed.array[u];
	      attributes.position.array[u + 1] = p.y + attributes.speed.array[u + 1];
	      attributes.position.array[u + 2] = p.z + attributes.speed.array[u + 2];

	      if (attributes.position.array[u] > 299.5)
	        attributes.position.array[u] = -299.5;
	      if (attributes.position.array[u] < -299.5)
	        attributes.position.array[u] = 299.5;

	      if (attributes.position.array[u + 1] > 99.5)
	        attributes.position.array[u + 1] = -99.5;
	      if (attributes.position.array[u + 1] < -99.5)
	        attributes.position.array[u + 1] = 99.5;

	      if (attributes.position.array[u + 2] > 299.5)
	        attributes.position.array[u + 2] = -299.5;
	      if (attributes.position.array[u + 2] < -299.5)
	        attributes.position.array[u + 2] = 299.5;

	      let getSize = attributes.size.array[k];

	      if (this.scale[k][0] > 0 && getSize < 0.35) {
	        getSize += this.scale[k][1];
	        attributes.size.array[k] = getSize;
	      } else {
	        this.scale[k][0] = -1;
	      }

	      if (this.scale[k][0] < 0 && getSize > 0.001) {
	        getSize -= this.scale[k][1];
	        attributes.size.array[k] = getSize;
	      } else {
	        this.scale[k][0] = 1;
	      }
	      k++;
	    }
	    attributes.position.needsUpdate = true;
	    attributes.size.needsUpdate = true;
    }
}