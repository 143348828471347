
import WebGLView from './webgl/WebGLView';
import { getGPUTier } from 'detect-gpu';
import { dispatchKeynoteParticleCanvasImageChange } from '../../../helpers/util';

class KeynoteParticleCanvasApp {

	constructor() {
		this.currentImage = "";
		this.isInitialised = false;
		this.particleRandomness = 10.0;
		this.scrollLerp = 0;
		this.webgl = null;
	}

	init(className, imageReference, particleRandomness, scrollLerp) {
		// console.log(this);
		let _self = this;
		(async () => {
			const tiers = await getGPUTier();
			_self.particleRandomness = particleRandomness;
			_self.scrollLerp = scrollLerp;
			_self.className = className;
		   _self.initWebGL( tiers );
		   _self.initCanvas(className);
		   _self.addListeners();
		   _self.animate();
		   _self.resize();
		   _self.isInitialised = true;
		   _self.currentImage = imageReference;
		   _self.webgl.goto(_self.currentImage);
		   _self.animate();
	   })();
	}

	suspend(){
		// console.log("PARTICLE BACKGROUND SUSPENDING");
		// TODO: stop rendering, processing and clear the data
		this.webgl.isSuspended = true;
		this.raf = null;
	}

	resume(){
		// console.log("PARTICLE BACKGROUND RESUMING");
		// TODO: resume rendering, processing and also get the data again
		this.webgl.isSuspended = false;
		this.initCanvas(this.className);
		this.animate();
	}

	initWebGL(tiers) {
		this.webgl = new WebGLView(this, tiers, this.particleRandomness);
	}
	
	initCanvas(className){
		document.querySelector(`.${className}`).appendChild(this.webgl.renderer.domElement);
	}

	addListeners() {
		this.handlerAnimate = this.animate.bind(this);
		window.addEventListener('resize', this.resize.bind(this));
		// const el = this.webgl.renderer.domElement;
		// document.addEventListener('grace_request_keynote_image_change', this.requestImageHandler.bind(this));
		// document.addEventListener('grace_request_keynote_image_removal', this.requestImageRemoveHandler.bind(this));
	}

	animate() {
		this.update();
		this.draw();
		this.raf = requestAnimationFrame(this.handlerAnimate);
	}

	// ---------------------------------------------------------------------------------------------
	// PUBLIC
	// ---------------------------------------------------------------------------------------------

	update() {
		// console.log(`${this.currentImage} / ${this.scrollLerp}`);
		if (this.webgl) this.webgl.update(this.particleRandomness, this.scrollLerp);
	}

	draw() {
		if (this.webgl) this.webgl.draw();
	}

	// ---------------------------------------------------------------------------------------------
	// EVENT HANDLERS
	// ---------------------------------------------------------------------------------------------

	resize() {
		if (this.webgl) this.webgl.resize();
	}

	// requestImageHandler(e) {
	// 	if(this.currentImage !== e.detail.imageReference){
	// 		// console.log(`Question number: ${e.detail.question}`);
	// 		this.currentImage = e.detail.imageReference;
	// 		this.webgl.goto(this.currentImage);
	// 	}
	// }
		
	// requestImageRemoveHandler(e) {
	// 	this.webgl.hideCurrentImage();
	// }
}

// const particleApp = new KeynoteParticleCanvasApp();
export default KeynoteParticleCanvasApp;
