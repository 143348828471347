import React, { memo } from 'react';
import { Answer } from '../../constants/types';
import Button from '../button';
import styles from './submitPage.module.scss';
import classnames from 'classnames';
import { Tick } from '../svgIcons';
import { SubmitSelect } from './form/submitSelect';
import { SubmitInput } from './form/submitInput';
import { AudioMessage } from './audioMessage';
import { Privacy } from '../policyPages';
import { RecordSound } from '../audio';
import { MapOfAnswer } from '../../redux/reducers/questionReducer/questionReducer';
import { FormikErrors, FormikTouched } from 'formik';
import { formData } from '../../constants/constants';
import { FormRow, Sphere } from '../svgIcons/icons';
import { goToPage } from '../../helpers/util';

interface DesktopSubmitProps {
  answer: Answer | null,
  values: {
    [key: string]: string | number,
  },
  audio: string,
  setFieldValue: (type: string, value: string | number) => void,
  profileConsent: boolean,
  setProfileConsent: (value: boolean) => void,
  termsConsent: boolean,
  setTermsConsent: (value: boolean) => void,
  setOpenModal: () => void,
  setCurrentPolicy: (value: JSX.Element) => void,
  edit: boolean,
  setEdit: () => void,
  start: (value: number) => void,
  stop: (value: number) => void,
  deleteAudio: () => void,
  isRecording: boolean,
  setEntryId: (value: string) => void,
  answers: MapOfAnswer | null,
  isMobile: boolean,
  errors: FormikErrors<{[key: string]: string}>,
  touched: FormikTouched<{[key: string]: string}>,
  submitLoading: boolean,
  setSubmitLoading: (value: boolean) => void,
  isBlocked: boolean,
}

export const DesktopSubmit: React.FC<DesktopSubmitProps> = memo(({
  answer,
  values,
  setFieldValue,
  audio,
  profileConsent,
  setProfileConsent,
  termsConsent,
  setTermsConsent,
  setOpenModal,
  setCurrentPolicy,
  edit,
  setEdit,
  start,
  stop,
  deleteAudio,
  isRecording,
  setEntryId,
  answers,
  isMobile,
  errors,
  touched,
  submitLoading,
  setSubmitLoading,
  isBlocked,
}) => {

  const openModal = (policy: JSX.Element) => {
    setCurrentPolicy(policy);
    setOpenModal();
  }

  const handleChangeAudio = () => {
    deleteAudio();
  }

  const findError = (): boolean => {
    const touchedData = Object.keys(touched);
    const errorData = Object.keys(errors);

    for (let i = 0; i < touchedData.length; i++) {
      for (let j = 0; j < errorData.length; j++) {
        if (touchedData[i] === errorData[j]) {
          return true;
        }
      }
    }
    return false;
  }
  return (
    <div className={isMobile ? styles.mobileSubmit : styles.desktopSubmit}>
      <div className={styles.description}>
        <Sphere />
        <div className={styles.thank}>
          THANK YOU! YOUR PERSPECTIVE IS SO IMPORTANT.
        </div>
        <div className={styles.body}>
          I just need a few final details from you so I can keep 
          you informed on my findings, but of course, only share 
          what you’re comfortable with. The more you can tell me, 
          however, the clearer a picture of the science community 
          we will create.
        </div>
        <div className={styles.body}>
          Together, we can take important steps forward in making 
          science an industry where everyone feels they belong.
        </div>
        { !isMobile
          ? !edit
          ? (
            <AudioMessage
              answer={answer}
              audio={audio}
              setEdit={handleChangeAudio}
            />
          )
          : (
            <RecordSound
              active={isRecording}
              onClick={isRecording ? stop : start}
              blob={answer ? answer.data.answer : ''}
              deleteAudio={deleteAudio}
              isMobile={isMobile}
              answerTime={answer?.data.time}
              isBlocked={isBlocked}
            />
          )
          : null
          }
      </div>
      {
        isMobile ? null : (
          <div className={styles.formRow}>
            <FormRow />
          </div>
        )
      }
      <div className={styles.form}>
        <div className={styles.form_title}>
            Please enter your name and location as you`d like them to appear
            alongside your message. This information will not be used in
            identifying survey answers.
        </div>
        <div className={styles.form_row}>
          <div className={styles.input_wrapper}>
            <SubmitInput 
              title='First name'
              name='firstName'
              type='text'
              error={errors.firstName || ''}
              touched={touched.firstName || ''}
              required={styles.required}
              className={styles.input}
              isMobile={isMobile}
              placeholder='E.g. John'
            />
          </div>
          <SubmitInput 
            title='Last name'
            name='lastName'
            type='text'
            error={errors.lastName || ''}
            touched={touched.lastName || ''}
            required={styles.required}
            className={styles.input}
            isMobile={isMobile}
            placeholder='E.g. John'
          />
        </div>
        <SubmitInput 
          title='Email address'
          name='email'
          type='email'
          error={errors.email || ''}
          touched={touched.email || ''}
          required={styles.required}
          className={styles.input}
          isMobile={isMobile}
          placeholder='e.g. john.doe@gmail.com'
        />
        <SubmitInput 
          title='location'
          name='location'
          type='text'
          error={errors.location || ''}
          touched={touched.location || ''}
          required={styles.required}
          className={styles.input}
          placeholder='e.g. London, United Kingdom'
          isMobile={isMobile}
        />
        <SubmitInput 
          title='company/organization'
          name='company'
          type='text'
          className={styles.input}
          touched={touched.company || ''}
          placeholder='e.g. SCIEX'
          isMobile={isMobile}
        />
        <SubmitSelect
          isMobile={isMobile} 
          current={String(values.discipline)}
          placeholder={formData.discipline.placeholder}
          data={formData.discipline.values}
          error={errors.discipline || ''}
          touched={touched.discipline || ''}
          required={styles.required}
          other={formData.discipline.values[formData.discipline.values.length - 1]}
          comment={values.discipline_comment}
          setComment={(value: string) => setFieldValue('discipline_comment', value)}
          title={formData.discipline.title}
          setValue={(value: string | number) => setFieldValue('discipline', value)}
        />
        <div className={styles.form_title}>
            Please tell us a little about you. This data will only be
            used in aggregate to analyse survey responses.
        </div>
        <SubmitSelect
          isMobile={isMobile} 
          current={String(values.gender)}
          data={formData.gender.values}
          title={formData.gender.title}
          placeholder={formData.gender.placeholder}
          error={errors.gender || ''}
          touched={touched.gender || ''}
          required={styles.required}
          other={formData.gender.values[formData.gender.values.length - 1]}
          comment={values.gender_comment}
          setComment={(value: string) => setFieldValue('gender_comment', value)}
          setValue={(value: string | number) => setFieldValue('gender', value)}
        />
        <SubmitSelect
          isMobile={isMobile} 
          current={String(values.age)}
          data={formData.age.values}
          title={formData.age.title}
          placeholder={formData.age.placeholder}
          error={errors.age || ''}
          touched={touched.age || ''}
          required={styles.required}
          setValue={(value: string | number) => setFieldValue('age', value)}
        />
        <SubmitSelect
          isMobile={isMobile} 
          current={String(values.inclusion)}
          data={formData.inclusion.values}
          title={formData.inclusion.title}
          placeholder={formData.inclusion.placeholder}
          error={errors.inclusion || ''}
          touched={touched.inclusion || ''}
          required={styles.required}
          setValue={(value: string | number) => setFieldValue('inclusion', value)}
        />
        {isMobile
          ? !edit
          ? (
            <AudioMessage
              answer={answer}
              audio={audio}
              setEdit={handleChangeAudio}
            />
          )
          : (
            <RecordSound
              active={isRecording}
              onClick={isRecording ? stop : start}
              blob={answer ? answer.data.answer : ''}
              deleteAudio={deleteAudio}
              isMobile={isMobile}
              answerTime={answer?.data.time}
              isBlocked={isBlocked}
            />
          )
          : null
          }
        <div className={styles.checkButtons}>
          <div className={styles.checkWrapper}>
            <Button
              className={termsConsent ? classnames(styles.checkButton, styles.checkButton_active) : styles.checkButton}
              onClick={() => setTermsConsent(!termsConsent)}
              buttonStyles={styles.checkButtonWrapper}
            >
              {
                termsConsent ? <Tick /> : ''
              }
            </Button>
              <p>
                I consent to being contacted with the survey results
              </p>
          </div>
          <div className={styles.checkWrapper}>
            <Button
              className={profileConsent ? classnames(styles.checkButton, styles.checkButton_active) : styles.checkButton}
              onClick={() => setProfileConsent(!profileConsent)}
              buttonStyles={styles.checkButtonWrapper}
            >
              {
                profileConsent ? <Tick /> : ''
              }
            </Button>
              <p>
                I consent to receive marketing communications from Sciex as per the 
                <span className={styles.check_terms} onClick={() => goToPage('https://sciex.com/legal-pages/privacy-policy')}>
                  {`privacy policy`}
                </span>
              </p>
          </div>
        </div>
        
        <div className={styles.submitContainer}>
            <Button
            type='submit'
            className={classnames(styles.buttonNext, styles.submitButton, submitLoading ? styles.submitLoading : '', findError() ? styles.submitError : '')}
            buttonStyles={findError() ? '' : styles.buttonNextWrapper}
            disabled={submitLoading || findError()}
          >
            {
              submitLoading ? 'LOADING' : 'SUBMIT'
            }
          </Button>
          <div className={styles.required_before}>
            Required Fields
          </div>
        </div>
        <div className={styles.legalDisclaimer}>
          <div>
            All data collected in the survey will be processed and stored securely and anonymously, 
            and used only for the purpose of this survey and the presentation of survey results. Gender 
            and age information will only be used in aggregate within the survey results to explore the 
            variety of advice given to Grace between age groups and genders. Please refer to our privacy policy.
          </div>
          <div>
            Voice/ text responses to question 12 will be published to the “Explore” section of the 
            site along with name, location and scientific discipline.
          </div>
        </div>
      </div>
    </div>
  )
});