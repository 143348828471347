import React from 'react';
import { Answer, Question } from '../../../constants/types';
import styles from '../questionsType.module.scss';
import classnames from 'classnames'
import Button from '../../button';
import { PencilIcon } from '../../svgIcons/icons';
import { Comment } from './comment';
import cloneDeep from 'lodash.clonedeep'

interface ConfirmProps {
  question: Question,
  answer: Answer | undefined,
  setIsComplete: (value: boolean) => void,
  setAnswer: (value: Answer) => void,
  children?: JSX.Element,
  isMobile?: boolean,
}

export const Confirm: React.FC<ConfirmProps> = ({
  question,
  answer,
  setIsComplete,
  setAnswer,
  children,
  isMobile = false,
}) => {
  const setComment = (value: string) => {
    if (answer) {
      const newAnswer = cloneDeep(answer);
      newAnswer.data.comment = value;
      setAnswer(newAnswer);
    }
  }

  const handleCLick = () => {
    document.dispatchEvent(new CustomEvent('scroll_to_zero', {
      detail: {
        scroll: true,
      }
    }));
    setIsComplete(false);
  }
  return (
    <div>
      <div className={classnames(styles.question_title, styles.completeTitle)}>
        {
          question.data.question.map((q, index) => (
            <div key={index} className={styles.inner_title}>
              {q}
            </div>
          ))
        }
      </div>
      {
        answer && (
          <div className={styles.answersWrapper}>
            <div className={styles.selectButtons}>
              <Button
                buttonStyles={styles.pencilWrapper}
                onClick={handleCLick}
                className={isMobile ? classnames(styles.pencilButton, styles.pencil_mobile) : styles.pencilButton}
              >
                <PencilIcon />
              </Button>
              {children}
            </div>
            <Comment
              label={question.data.label}
              setComment={setComment}
              comment={answer.data.comment}
              answer={answer ? answer.data.answer : ''}
            />
          </div>
        )
      }
    </div>
  )
};