import React, { memo, useEffect, useState } from 'react';
import styles from './submitPage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionState, setAnswer } from '../../redux/reducers/questionReducer/questionReducer';
import { DesktopSubmit } from './desktopSubmit';
import { Answer, UserData } from '../../constants/types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { pathName } from '../../constants/constants';
import { useRecorder } from '../../hooks';
import cloneDeep from 'lodash.clonedeep';
import { checkMicrophone, createFirebaseData } from '../../helpers/util';
import { audioTrackConstraints } from '../../helpers/util';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
import firebase from 'firebase/app';
import firebaseService from '../../firebase/firebase';
import 'firebase/firestore';

interface SubmitPageProps {
  isMobile: boolean,
  setOpenModal: () => void,
  setCurrentModalData: (value: JSX.Element) => void,
  openModal: boolean,
}

export const SubmitPage: React.FC<SubmitPageProps> = memo(({
  isMobile,
  setOpenModal,
  setCurrentModalData,
  openModal,
}) => {
  const answers = useSelector<QuestionState, QuestionState['answers']>(state => state.answers);
  const times = useSelector<QuestionState, QuestionState['times']>(state => state.times);
  const skipped = useSelector<QuestionState, QuestionState['skipped']>(state => state.skipped);
  const [audio, setAudio] = useState('');
  const [profileConsent, setProfileConsent] = useState(false);
  const [termsConsent, setTermsConsent] = useState(false);
  //const [recorder] = useRecorder(160);
  const [isRecording, setIsRecording] = useState(false);
  const[edit, setEdit] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [entryId, setEntryId] = useState<string>('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [recordState, setRecordState] = useState<RecordState | null>(null);

  const start = async (time: number) => {
    const isMicrophone = await checkMicrophone(setIsBlocked);
    if (isMicrophone) {
      try {
        setRecordState(RecordState.START)
        setIsRecording(true);
        setIsBlocked(false);
      } catch (error) {
        setIsBlocked(true);
      }
    }
    // if (recorder) {
    //   recorder
    //     .start()
    //     .then(() => {
    //       setIsRecording(true);
    //       setIsBlocked(false);
    //     }).catch(error => {
    //       setIsBlocked(true);
    //       console.log(error.message);
    //     });
    // }
  }

  const stop = (time: number) => {
    if (answers) {
      try {
        setRecordState(RecordState.STOP)
        setIsRecording(false);
        setIsBlocked(false);
        const arrayAnswers = Object.entries(answers);
        const audioAnswer = arrayAnswers[arrayAnswers.length - 1][1];
        if (audioAnswer) {
          const newAnswer = cloneDeep(audioAnswer);
          newAnswer.data.time = time;
          dispatch(setAnswer(String(arrayAnswers.length), newAnswer));
        }
      } catch (error) {
        setIsBlocked(true);
      }
    }
    // if (answers && recorder) {
    //   recorder
    //   .stop()
    //   .getMp3()
    //   .then(([buffer, blob]) => {
    //     const mp3Blob = URL.createObjectURL(blob);
    //     const arrayAnswers = Object.entries(answers);
    //     const audioAnswer = arrayAnswers[arrayAnswers.length - 1][1];
    //     if (audioAnswer) {
    //       const newAnswer = cloneDeep(audioAnswer);
    //       newAnswer.data.answer = mp3Blob;
    //       newAnswer.data.time = time;
    //       dispatch(setAnswer(String(arrayAnswers.length), newAnswer));
    //     }
    //     setIsBlocked(false);
    //     setIsRecording(false);
    //   }).catch(error => {
    //     setIsBlocked(true);
    //     console.log(error.message);
    //   });
    // }
  }

  const onStop = (audioData) => {
    const mp3Blob = URL.createObjectURL(audioData.blob);
    if (answers) {
      const arrayAnswers = Object.entries(answers);
        const audioAnswer = arrayAnswers[arrayAnswers.length - 1][1];
        const mp3Blob = URL.createObjectURL(audioData.blob);
        if (audioAnswer) {
          const newAnswer = cloneDeep(audioAnswer);
          newAnswer.data.answer = mp3Blob;
          dispatch(setAnswer(String(arrayAnswers.length), newAnswer));
        }
    }
  }

  const deleteAudio = async () => {
    if (answers) {
      const arrayAnswers = Object.entries(answers);
      if (arrayAnswers.length > 0) {
        const audioAnswer = arrayAnswers[arrayAnswers.length - 1][1];
        const newAnswer = cloneDeep(audioAnswer);
        newAnswer.data.answer = null;
        newAnswer.data.time = null;
        await dispatch(setAnswer(String(arrayAnswers.length), newAnswer));
        setEdit(true);
      }
    }
  }

  const getAnswer = (): Answer | null => {
    if (answers) {
      const allAnswers = Object.entries(answers);
      if (allAnswers.length > 0) {
        return allAnswers[allAnswers.length - 1][1];
      } else {
        return null;
      }
    } else {
      return null;
    }
    
  }

  useEffect(() => {
    try {
      if (navigator && navigator.mediaDevices) {
        navigator.mediaDevices.getUserMedia({ audio: audioTrackConstraints
        })
        .then(() => {
          // console.log('Permission Granted');
          setIsBlocked(false);
        })
        .catch((error) => {
          console.log(error.message);
          setIsBlocked(true);
        })
      } else {
        // console.log('media device stuff not available in this browser');
      }
    } catch (error) {
      if (error instanceof Error){
        console.log(error.message);
      }
    }
  }, [])

  useEffect(() => {
    try {
      if (answers) {
        const arrayAnswers = Object.entries(answers);
        if(arrayAnswers.length) {
          const newAudio = arrayAnswers[arrayAnswers.length - 1][1].data.answer;
          setAudio(newAudio);
        }
      }
    } catch (error) {
      if(error instanceof Error){
        console.log(error.message);
      }
    }
  }, [answers]);

  return (
    <div className={styles.submitPage}>
      <div style={{opacity: 0, position: 'absolute'}}>
        <AudioReactRecorder state={recordState} onStop={onStop} />
      </div>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            location: '',
            company: '',
            discipline: '',
            discipline_comment: '',
            age: '',
            gender: '',
            gender_comment: '',
            inclusion: '',
          }}
          validationSchema={
            Yup.object({
              email: Yup.string().required('Enter the email').email('Incorrect email'),
              firstName: Yup.string().required('First name must be filled'),
              lastName: Yup.string().required('Last name must be filled'),
              location: Yup.string().required('Location must be filled'),
              age: Yup.string().required('Age must be filled'),
              discipline: Yup.string().required('Discipline must be filled'),
              inclusion: Yup.string().required('This field must be filled'),
              gender: Yup.string().required('This field must be filled'),
            })
          }
          onSubmit={(values, {setSubmitting, setTouched}) => {
              if (answers) {
                try {
                  const submitData = createFirebaseData(answers);
                  const userData: UserData = {
                    age: values.age,
                    location: values.location,
                    company: values.company,
                    discipline: values.discipline_comment ? values.discipline_comment : values.discipline,
                    contactConsent: termsConsent,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    fullName: `${values.firstName.toLowerCase()} ${values.lastName.toLowerCase()}`,
                    email: values.email,
                    emailConsent: profileConsent,
                    gender: values.gender,
                    genderComment: values.gender_comment,
                    diversity: values.inclusion,
                  }
                  submitData.userData = userData;
                  submitData.submissionDate = firebase.firestore.Timestamp.fromDate(new Date());;
                  submitData.analytics.dwellTime = times;
                  submitData.analytics.skipped = skipped;
                  setSubmitLoading(true);
                  fetch(submitData.surveyData['q12_1'])
                  .then(r => r.blob()).then(blobData => {
                    const audioBlob:Blob | null = submitData.surveyData['q12_1'] === null ? null : blobData;
                    
                    firebaseService.uploadAudioFileAndSurveyData(audioBlob, submitData).then((response) => {
                      setTouched({});
                      setSubmitLoading(false);
                      setEntryId(response.id);
                      history.push(pathName.success);
                    }).catch(error => console.log(error.message));
                  });
                } catch (error) {
                  setSubmitLoading(false);
                  console.log(error);
                }
              }
            setSubmitting(false);
          }}
        >
          {({values, setFieldValue, errors, touched}) => (
            <Form className={styles.formContainer}>
                  <DesktopSubmit 
                    answer={getAnswer()}
                    setFieldValue={setFieldValue}
                    values={values}
                    audio={audio}
                    profileConsent={profileConsent}
                    setProfileConsent={setProfileConsent}
                    termsConsent={termsConsent}
                    setTermsConsent={setTermsConsent}
                    setOpenModal={setOpenModal}
                    setCurrentPolicy={setCurrentModalData}
                    isRecording={isRecording}
                    deleteAudio={deleteAudio}
                    edit={edit}
                    setEdit={deleteAudio}
                    start={start}
                    stop={stop}
                    setEntryId={setEntryId}
                    answers={answers}
                    isMobile={isMobile}
                    errors={errors}
                    touched={touched}
                    submitLoading={submitLoading}
                    setSubmitLoading={setSubmitLoading}
                    isBlocked={isBlocked}
                  />
            </Form>
          )}
        </Formik>
    </div>
  )
});