import { SelectAll, SelectOne, RadioQuestion, SeveralQuestion, AudioQuestion, OneRadio, EnterTextArea } from '../components/questionsType';
import { Confirm } from '../components/questionsType/isComplete/confirm';
import { QuestionModal } from '../components/questionsType/isComplete/questionModal';
import { MapOfQuestion } from '../redux/reducers/questionReducer/questionReducer';
import { ThinkerData } from './types';
export const MOBILE_LAYOUT_BREAKPOINT = 768;
export const HEIGHT_LAYOUT_BREAKPOINT = 700;
export const HIDDEN_ERROR_BREAKPOINT = 1250;

export const testData: ThinkerData[] = [
  {
    id: 1,
    avatar: 'images/thinkers/profiles/all.jpg',
    firstName: 'All',
    lastName: 'Reactions',
    cta: "See all reactions",
    voice: null,
    deeplink: null,
    video: 'https://youtu.be/TSAjeTYcCzM',
    story: `Listen to science’s thought leaders share their perspective on who Grace is to them, as she asks questions about where she belongs in the industry.`,
  },
  {
    id: 2,
    avatar: 'images/thinkers/profiles/Brigit_Schilling.jpg',
    firstName: 'Birgit',
    lastName: 'Schilling',
    cta: "Hear Birgit's perspective",
    voice: null,
    deeplink: "https://extraordinarygrace.com/explore/G5ZBVH3zg1SoQ1Eg72me",
    video: null,
    story: `Dr. Birgit Schilling works at the Buck Institute for Research on Aging in the San Francisco Bay Area since 2000, where she has her own lab as Associate Professor and is Director of the Mass Spectrometry Technology Center. Dr Schilling is interested in translational research and any research that may aim towards therapeutic interventions to improve human aging or disease.`,
  },
  {
    id: 3,
    avatar: 'images/thinkers/profiles/Daniel_Hornburg.jpg',
    firstName: 'Daniel',
    lastName: 'Hornburg',
    cta: "Hear Daniel's perspective",
    voice: null,
    deeplink: "https://extraordinarygrace.com/explore/EKUQpZNRPuzM3ICNeWHz",
    video: null,
    story: `Daniel Hornburg, Ph.D. is a Principal Scientist at Seer, he and his team focus on the development of transformative multi-omics solutions for biomedical research at the intersection of bioinformatics, mass spectrometry, and nano-technology. Before Seer, Daniel worked with Mike Snyder at Stanford on lipidomics, metabolomics, and proteomics technologies and computational strategies to interrogate complex multi-omics datasets. His postdoctoral- and Ph.D. research he conducted in the lab Matthias Mann and Felix Meissner at the Max Planck Institute of Biochemistry, where he investigated proteome perturbations associated with neurodegenerative disorders and immune signaling.`,
  },
  {
    id: 4,
    avatar: 'images/thinkers/profiles/Grace_Van_der_Gugten.jpg',
    firstName: 'Grace',
    lastName: 'van der Gugten',
    cta: "Hear Grace's perspective",
    voice: null,
    deeplink: "https://extraordinarygrace.com/explore/NuAh4QUZSlvuEKVQPVIv",
    video: null,
    story: `Grace van der Gugten is the LC-MS/MS Assay Development Specialist in the clinical chemistry laboratory at St Paul’s Hospital in Vancouver, BC, Canada. She is passionate about mass spectrometry and that this technology is utilized in the clinical lab to help patients.`,
  },
  {
    id: 5,
    avatar: 'images/thinkers/profiles/Isabelle_Kohler.jpg',
    firstName: 'Isabelle',
    lastName: 'Kohler',
    cta: "Hear Isabelle's perspective",
    voice: null,
    deeplink: "https://extraordinarygrace.com/explore/Iq60zxzBnKvV8gWEtlhq",
    video: null,
    story: `Isabelle Kohler is a Tenure Track Assistant Professor at the Vrije Universiteit Amsterdam in the Netherlands.  Her major research interests lie at the intersection between bioanalysis, metabolomics, and forensic/clinical toxicology. She is an enthusiastic supervisor, advisor and mentor of (PhD) students, and actively advocating for more diversity and inclusion in sciences.`,
  },
  {
    id: 6,
    avatar: 'images/thinkers/profiles/Jessica_Prenni.jpg',
    firstName: 'Jessica',
    lastName: 'Prenni',
    cta: "Read Jessica's perspective",
    voice: null,
    deeplink: "https://extraordinarygrace.com/explore/a1BZxFp6fHZTAlW6sqP0",
    video: null,
    story: `Dr. Jessica Prenni is an Associate Professor at Colorado State University.  Her research focuses on the use of mass spectrometry tools to address important issues related to the quality and safety of food and other agricultural products.`,
  },
  {
    id: 7,
    avatar: 'images/thinkers/profiles/Joanna_Bons.jpg',
    firstName: 'Joanna',
    lastName: 'Bons',
    cta: "Hear Joanna's perspective",
    voice: null,
    deeplink: "https://extraordinarygrace.com/explore/vBpAXOAQWJJkGi8yUQiu",
    video: null,
    story: `After an engineer degree in Biotechnology, Joanna Bons integrated into the team of Dr. Christine Carapito at the Laboratory of BioOrganic Mass Spectrometry (LSMBO, Strasbourg, France) as a PhD student, where she worked on improving proteome quantification and characterization using quantitative MS. She received her PhD in December 2019 and continued as postdoctoral fellow within Dr. Christine Carapito’s team to develop MS-based strategies to accurately quantify and characterize very low-abundance proteins. In September 2020, she joined Dr. Birgit Schilling’s lab at the Buck Institute for Research on Aging as postdoctoral fellow, where her research work focuses on developing and optimizing innovative DIA strategies for discovering and verifying candidate biomarkers, and highlighting PTM patterns in various biological contexts.`,
  },
  {
    id: 8,
    avatar: 'images/thinkers/profiles/Lotte_Schreuders.jpg',
    firstName: 'Lotte',
    lastName: 'Schreuders',
    cta: "Hear Lotte's perspective",
    voice: null,
    deeplink: "https://extraordinarygrace.com/explore/WDbCbJrVOW5m7MfOoM1Z",
    video: null,
    story: `Lotte is working at the University of Amsterdam in educational development for the master Chemistry, track Analytical Sciences since April 2020. Earlier that year, she finished this master track herself, after obtaining her bachelors degree in Chemistry. Within her position, she was hired to improve and maintain the quality of the teaching. Next to that, she is a proud one-third of the Sisters in Science (@SistersinScience_NL). This instagram platform was founded in January 2021 with Mimi den Uijl and Noor Abdulhussain and aims to show that if you can see it, you can be it.`,
  },
  {
    id: 9,
    avatar: 'images/thinkers/profiles/Maarten_Dhaenens.jpg',
    firstName: 'Maarten',
    lastName: 'Dhaenens',
    cta: "Hear Maarten's perspective",
    voice: null,
    deeplink: "https://extraordinarygrace.com/explore/VfRbSlQaqgVXtVkH8yib",
    video: null,
    story: `Dr. Maarten Dhaenens is heading the proteomics department of ProGenTomics at the Laboratory of Pharmaceutical Biotechnology, Ghent University, Belgium, where he focuses on broadening our perspective on the “histone code” by analyzing it with data-independent acquisition (DIA) technologies on QTOF instrument designs. He is very much engaged in helping young scientists develop their career as well as in building a tighter international proteomics community. The recent Cov-MS assay was a first targeted exercise that will have far-reaching impact on mass spectrometry in the clinic.`,
  },
  {
    id: 10,
    avatar: 'images/thinkers/profiles/Mimi_den_Uyl.jpg',
    firstName: 'Mimi',
    lastName: 'Den Uyl',
    cta: "Hear Mimi's perspective",
    voice: null,
    deeplink: "https://extraordinarygrace.com/explore/gZDHpJzrjBW54RiOiGXd",
    video: null,
    story: `Mimi obtained her bachelors and masters degree in analytical chemistry from the University of Amsterdam, where she is pursuing a PhD in the group of Peter Schoenmakers. This PhD project is focussed on the implementation of photodegradation reactions within multidimensional liquid chromatography. Next to her passion for analytical chemistry, she is one of the three Sisters in Science (@SistersinScience_NL). This instagram page, founded in January 2021 with Lotte Schreuders and Noor Abdulhussain, aims to make life at university more accessible and to make science more fun!`,
  },
  {
    id: 11,
    avatar: 'images/thinkers/profiles/Natalie_Panek.jpg',
    firstName: 'Natalie',
    lastName: 'Panek',
    cta: "Hear Natalie's perspective",
    voice: null,
    deeplink: "https://extraordinarygrace.com/explore/ueAL07zSK2253xOxLRl9",
    video: null,
    story: `Natalie is a rocket scientist, adventurer, and advocate for women in technology. As a Member of Technical Staff at MDA’s Robotics and Automation division, Natalie works on the next generation of Canadian space robotics and space exploration programs, including work on the Rosalind Franklin/ExoMars rover.`,
  },
  {
    id: 12,
    avatar: 'images/thinkers/profiles/Nathan_Basisty.jpg',
    firstName: 'Nathan',
    lastName: 'Basisty',
    cta: "Hear Nathan's perspective",
    voice: null,
    deeplink: "https://extraordinarygrace.com/explore/kjgP1bC8GgwaOP3IXRSN",
    video: null,
    story: `Nathan Basisty is a K99 postdoctoral fellow in Dr. Birgit Schilling’s lab at the Buck Institute for Research on Aging in California. He received his PhD and BS degrees from the University of Washington in Seattle. His research combines two of his scientific passions – proteomics and geroscience – to help us better understand and treat age-related diseases. A major aim of his research is to discover biomarkers of cellular senescence and develop translationally relevant approaches to study senescent cells in humans, enabled by the application of proteomics.`,
  },
  {
    id: 13,
    avatar: 'images/thinkers/profiles/Noor_Abdulhussain.jpg',
    firstName: 'Noor',
    lastName: 'Abdulhussain',
    cta: "Hear Noor's perspective",
    voice: null,
    deeplink: "https://extraordinarygrace.com/explore",
    video: null,
    story: `Noor Abdulhussain is a PhD student in the ERC STAMP (Separation Technology for A Million Peaks) project at the University of Amsterdam. While her current work mainly focuses on functionality assessments of 3D-printed devices for application to multi-dimensional separations, Abdulhussain remains involved in giving a glimpse into the life of a chemist at the university and thereby inspire and motivate others on their Sisters in Science platform together with two of her friends (Mimi den Uijl and Lotte Schreuders).`,
  },
  {
    id: 14,
    avatar: 'images/thinkers/profiles/Raven_Baxter.jpg',
    firstName: 'Raven',
    lastName: 'Baxter',
    cta: "Hear Raven's perspective",
    voice: null,
    deeplink: "https://extraordinarygrace.com/explore/6XPSapLymG61WsgupNwJ",
    video: null,
    story: `
    <p>Raven Baxter, also known as Raven the Science Maven, is an award-winning and internationally acclaimed science educator and molecular biologist creating science spaces that are inclusive, educational, and real. She is an entertainer and content creator known for her unique style of combining science and music that teaches and empowers those in STEM and beyond. Raven speaks about innovation in science education and social change in STEM. She is completing a doctorate in science education in May 2021.</p>
    <p>Raven is the creator and co-host of a STEM talk show, STEMbassy, and also Black In Science Communication, a group that works to build relationships in the science community, equipping others with the knowledge and resources necessary to share science with the world in their own flavor. Raven also owns and operates Smarty Pants, a clothing company that sells fun and stylish STEM-themed apparel and accessories. Smarty Pants also contributes a portion of its sales toward an annual scholarship for STEM students.</p>
    <p>Raven has quickly developed a reputation as a strong voice in science and has been recognized as a global influencer in several publications, including Fortune Magazine’s 40 Under 40 list. Raven has also earned the State University of New York Chancellor’s Award for Student Excellence in honor of her service in educating the public about COVID-19.</p>
    `,
  },
];

export const pathName = {
  startPage: '/',
  keynotePage: '/keynote',
  teaWithRichPage: '/tea-with-rich',
  questionPage: '/questions',
  explore: '/explore',
  thinkers: '/featured-voices',
  about: '/about',
  privacy: '/privacy-policy',
  cookie: '/cookie-policy',
  terms: '/terms',
  submit: '/submit',
  success: '/success',
  voices: '/voices',
  thanks: '/thanks',
};

export const formData = {
  discipline: {
    values: [
      'Chemical Manufacturing',
      'Clinical/ Healthcare',
      'Contract Research Organization',
      'Environmental Testing',
      'Food/ Beverage Testing',
      'Forensic Science',
      'Life Science Research- Academic',
      'Life Science Research- Industrial',
      'Pharmaceuticals - Small Molecules',
      'Pharmaceuticals - Biopharmaceuticals',
      'Something else?',
    ],
    placeholder: 'Select...',
    title: 'Which of the following best describes the scientific discipline you work in?'
  },
  country: {
    values: [
            "Afghanistan",
            "Albania",
            "Algeria",
            "Andorra",
            "Angola",
            "Antigua and Barbuda",
            "Argentina",
            "Armenia",
            "Australia",
            "Austria",
            "Azerbaijan",
            "Bahamas",
            "Bahrain",
            "Bangladesh",
            "Barbados",
            "Aruba",
            "Anguilla",
            "Belgium",
            "Belize",
            "Benin",
            "Bermuda",
            "Bhutan",
            "Bolivia",
            "Bosnia and Herzegovina",
            "Botswana",
            "Bouvet Island",
            "Brazil",
            "British Indian Ocean Territory",
            "Brunei",
            "Bulgaria",
            "Burkina Faso",
            "Burundi",
            "Cambodia",
            "Cameroon",
            "Canada",
            "Cape Verde",
            "Cayman Islands",
            "Central African Republic",
            "Chad",
            "Chile",
            "People’s Republic of China",
            "Christmas Island",
            "Cocos (Keeling) Islands",
            "Colombia",
            "Comoros",
            "Congo, Democratic Republic of the",
            "Congo, Republic of the",
            "Cook Islands",
            "Costa Rica",
            "Cote D`Ivoire",
            "Croatia",
            "Cuba",
            "Cyprus",
            "Czech Republic",
            "Denmark",
            "Djibouti",
            "Dominica",
            "Dominican Republic",
            "Ecuador",
            "Egypt",
            "El Salvador",
            "Equatorial Guinea",
            "Eritrea",
            "Estonia",
            "Ethiopia",
            "Falkland Islands",
            "Faroe Islands",
            "Fiji",
            "Finland",
            "France",
            "French Guiana",
            "French Polynesia",
            "French Southern Territories",
            "Gabon",
            "Gambia",
            "Georgia",
            "Germany",
            "Ghana",
            "Gibraltar",
            "Greece",
            "Greenland",
            "Grenada",
            "Guadeloupe",
            "Guam",
            "Guatemala",
            "Guernsey",
            "Guinea",
            "Guinea-Bissau",
            "Guyana",
            "Haiti",
            "Heard Island And Mcdonald Islands",
            "Holy See (Vatican City)",
            "Honduras",
            "Hong Kong SAR of China",
            "Hungary",
            "Iceland",
            "India",
            "Indonesia",
            "Iraq",
            "Ireland",
            "Israel",
            "Italy",
            "Ivory Coast",
            "Jamaica",
            "Japan",
            "Jordan",
            "Kazakhstan",
            "Kenya",
            "Kiribati",
            "Kosovo",
            "Kuwait",
            "Kyrgyzstan",
            "Laos",
            "Latvia",
            "Lebanon",
            "Lesotho",
            "Liberia",
            "Libya",
            "Liechtenstein",
            "Lithuania",
            "Luxembourg",
            "Macao SAR of China",
            "Macedonia",
            "Madagascar",
            "Malawi",
            "Malaysia",
            "Maldives",
            "Mali",
            "Malta",
            "Marshall Islands",
            "Martinique",
            "Mauritania",
            "Mauritius",
            "Mayotte",
            "Mexico",
            "Micronesia",
            "Moldova",
            "Monaco",
            "Mongolia",
            "Montserrat",
            "Montenegro",
            "Morocco",
            "Mozambique",
            "Myanmar",
            "Namibia",
            "Nauru",
            "Nepal",
            "Netherlands",
            "Netherlands Antilles",
            "New Caledonia",
            "New Zealand",
            "Nicaragua",
            "Niger",
            "Nigeria",
            "Norfolk Island",
            "Northern Mariana Islands",
            "Norway",
            "Oman",
            "Pakistan",
            "Palau",
            "Panama",
            "Papua New Guinea",
            "Paraguay",
            "People's Republic of China",
            "Peru",
            "Philippines",
            "Pitcairn",
            "Poland",
            "Portugal",
            "Puerto Rico",
            "Qatar",
            "Reunion",
            "Romania",
            "Rwanda",
            "Saint Helena",
            "Saint Kitts And Nevis",
            "Saint Lucia",
            "Saint Pierre and Miquelon",
            "Saint Vincent and the Grenadines",
            "Samoa",
            "San Marino",
            "Sao Tome And Principe",
            "Saudi Arabia",
            "Senegal",
            "Serbia",
            "Seychelles",
            "Sierra Leone",
            "Singapore",
            "Slovakia",
            "Slovenia",
            "Solomon Islands",
            "Somalia",
            "South Africa",
            "South Georgia and the South Sandwich Islands",
            "South Korea",
            "Spain",
            "Sri Lanka",
            "Standardized Country",
            "Sudan",
            "Suriname",
            "Swaziland",
            "Sweden",
            "Switzerland",
            "Syria",
            "Taiwan Region",
            "Tajikistan",
            "Tanzania",
            "Thailand",
            "Timor-Leste",
            "Togo",
            "Tokelau",
            "Tonga",
            "Trinidad And Tobago",
            "Tunisia",
            "Turkey",
            "Turkmenistan",
            "Turks And Caicos Islands",
            "Tuvalu",
            "Uganda",
            "Ukraine",
            "United Arab Emirates",
            "United Kingdom",
            "United States",
            "United States Minor Outlying Islands",
            "Uruguay",
            "Uzbekistan",
            "Vanuatu",
            "Venezuela",
            "Vietnam",
            "Virgin Islands, British",
            "Virgin Islands, U.S.",
            "Wallis And Futuna",
            "Western Sahara",
            "Zambia",
            "Zimbabwe",
            "日本",
            "中国",
            "대한민국",
            "남한",
    ],
    placeholder: 'Select...',
    title: 'Country'
  },
  interest: {
    values: [
      'Life Science Research',
      'Clinical',
      'Environmental/Industrial',
      'Food/Beverage',
      'Forensic',
      'Pharma',
      'Biopharma',
    ],
    placeholder: 'Select...',
    title: 'Area of interest'
  },
  age: {
    values: [
      'Under 25',
      '26-35',
      '36-45',
      '46-55',
      '56-65',
      '66-75',
      '76+',
      'Prefer not to answer',
    ],
    placeholder: 'Select...',
    title: 'Which age group do you currently fall into?',
  },
  gender: {
    values: [
      'Male including transgender male',
      'Female including transgender female',
      'Prefer not to say',
      'I prefer to self-describe',
    ],
    placeholder: 'Select...',
    title: 'What is your gender?',
  },
  race: {
    values: [
      'Black',
      'Asian',
      'South Asian',
      'Middle Eastern',
      'White',
      'Native Hawaiian/Pacific Islander',
      'Native American',
      'Latino',
      'Hispanic',
      'Prefer not to answer',
      'Anything that we missed?',
    ],
    placeholder: 'Select...',
    title: 'Which of the following best describes your ethnic group?',
  },
  inclusion: {
    values: [
      'Strongly agree',
      'Agree',
      'no opinion',
      'Disagree',
      'Strongly Disagree',
    ],
    placeholder: 'Select...',
    title: 'To what extent do you agree that diversity and inclusion issues are taken seriously in science?',
  }
}

export const testQuestions: MapOfQuestion = {
  1: {
    id: 1,
    Component: SelectAll,
    Confirm: Confirm,
    data: {
      question: [`I think I'd like to become a scientist when I'm older,
        but I'm not sure I have the qualities of one. 
        What would you say are a scientist’s most important qualities?`,
        `Pick the qualities that you feel apply: `],
      attributes: [ 'Creative', 'Curious', 'Honest', 'Empathetic', 'Loud',
        'Forceful', 'Persistent', 'Positive', 'Open-minded', 'Driven', 'Critical',
        'Demanding', 'Approachable', 'perceptive', 'Focused', 'Particular', 'Clever',
        'Selective', 'Decisive', 'Intuitive', 'Intimidating', 'Stubborn', 'Visionary' ],
      label: 'Are there any other important qualities?',
      age: 12,
    }
  },
  2: {
    id: 2,
    Component: SelectOne,
    Confirm: Confirm,
    data: {
      question: [`If you could go back to school, would you still pursue a career as a scientist?`],
      attributes: [ 'Yes', 'No' ],
      label: 'Why?',
      age: 12,
    }
  },
  3: {
    id: 3,
    Component: SelectOne,
    Confirm: Confirm,
    data: {
      question: [
        `I'm really happy to have you as my mentor. When you were younger, did you have a role
        model who inspired you to pursue science?`
      ],
      attributes: [ 'Yes', 'No' ],
      label: {
        ['Yes']: 'How did their role have an effect on your career in science?',
        ['No']: 'Do you wish you had a role model?',
      },
      age: 13,
    }
  },
  4: {
    id: 4,
    Component: OneRadio,
    Confirm: null,
    data: {
      question: [`My chemistry teacher seems to only pick boys to demonstrate experiments in class,
        but I want a turn as well.`, `What should I do?`],
      attributes: [`Talk to the teacher before class about how I feel`, `Stand up in class and say I want
        to do it`, `March up to the front whether he chooses me or not`, `Say nothing because I'll probably
        get my turn eventually`, `Ask one of the boys to suggest me`],
      label: 'Try something else',
      age: 15,
    }
  },
  5: {
    id: 5,
    Component: EnterTextArea,
    Confirm: null,
    data: {
      question: [`A lot of people are starting to drop out of my university course, including my best friend.`,
        `Today I learned that only 50% of STEM students complete their degree, and even more worryingly,
        women and people of colour are more likely to make up this 50% drop-off.`, 'Why do you think that is?'],
      age: 15,
      step: true,
    }
  },
  6: {
    id: 6,
    Component: EnterTextArea,
    Confirm: null,
    data: {
      question: [`A couple of recent bad grades have me feeling down. I'm not sure I'm
        cut out for this.`, `What's the one thing you always return to that picks you back up?`],
      age: 16,
      step: true,
    },
  },
  7: {
    id: 7,
    Component: RadioQuestion,
    Confirm: null,
    data: {
      question: [`My first job interview is tomorrow and I've spent countless hours laying out clothes and
        practicing in front of the mirror. I feel my best when I’m wearing bright colours and expressing myself through my hair, but I’m worried I might be judged as not looking professional, so I would love your opinion on this and a few other things I'm nervous about...`,
        `Select your preferred option:`],
      attributes: [ [ 'I should wear my natural hair out', 'I should straighten my hair' ],
      [ 'I should wear black and play it safe', 'I should wear something colorful' ],
      [ 'I should mention my hopes of having a family one day', 'I should keep quiet about my hopes of having a family one day' ],
      [ 'I should pronounce my difficult surname upfront', 'I should let them run with my surname however it`s said, for ease`s sake'],
      [ `I should shake the interviewer's hand`, `I shouldn't shake the interviewer's hand` ] ],
      age: 18,
      under: ['I just want to be seen for my potential instead of my appearance.'],
    }
  },
  8: {
    id: 8,
    Component: EnterTextArea,
    Confirm: null,
    data: {
      question: [`I keep being passed over for promotion, while male colleagues with less experience and accolades are being
        promoted.`, `When I asked about it, my seniors told me they 'feel they didn't know me well enough'.`,
        'Have you experienced anything similar or seen it happen to a colleague, and if so, what happened?'],
      age: 27,
    }
  },
  9: {
    id: 9,
    Component: EnterTextArea,
    Confirm: null,
    data: {
      question: [`I recently finished writing a paper that has a good chance of being published in a high impact
        journal. However, a colleague of mine who has previously worked with the editor, says we have a better chance
        of getting published if they're named as the first author instead of me.`, `What do you think
        I should do in this situation?`],
      age: 27,
    }
  },
  10: {
    id: 10,
    Component: EnterTextArea,
    Confirm: null,
    data: {
      question: [`IT’S A BIG WEEK! I’m going to be presenting in front of a funding panel for the first time. 
        I truly believe our 5 year research plan could change the face of analytical science.`, `I'm really passionate about this
        so I'm looking for all the help I can get. Did you know less than 20% of applications for scientific
        research funding are approved?!`, `So, what's the one most important thing I can do to make an impression with the panel?`],
      age: 28,
    }
  },
  11: {
    id: 11,
    Component: SeveralQuestion,
    Confirm: QuestionModal,
    data: {
      question: [ ['Black applicants are significantly less likely than white applicants to be funded'],
        [`Black scientists spend twice the amount of time grant-writing to achieve the same funding level as white PI’s`], 
        [`Diverse teams are prioritised for funding`] ],
      attributes: [ [`True`, `False`], [`True`, `False`], [`True`, `False`] ],
      correct: ['True', 'True', 'False'],
      label: 'Play this little game of true or false with me...',
      under: ['Now guess what.. we won the grant! Can you believe it!? Change is coming!!'],
      age: 28,
    }
  },
  12: {
    id: 12,
    Component: AudioQuestion,
    Confirm: null,
    data: {
      question: [ `Since we last spoke, the results from my research have come in. There's talk
        of a nobel prize, which would make me the first black scientist to win!`, `I want to inspire
        others, as I know I wouldn't be in this position without your guidance. So, I've created an
        initiative that I hope becomes the shared voice of science, and I want you to be a part of it.`,
        `My final question needs your unique perspective.`, `Give an idea, piece of advice, or solution that could 
        improve science for future generations, and in turn, help create a true picture of our industry.` ],
      label: `Record a voice message to share with the Science community or leave a written message`,
      age: 28
    }
  },
}