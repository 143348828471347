
import * as THREE from 'three';
import { TweenMax, Back, Expo, Sine, Bounce } from 'gsap';

export default class Audio {

    constructor() {
        this.audioListener = null;
        this.audio         = null;
        this.frequencyData = null;
        this.audioBaseObj  = null;
        this.currentObj    = null;
        this.objects       = null;
        this.counter       = null;
        this.doesPlay      = false;
        this.close         = true;
        this.endIsActive   = false;

    }

    init( audioBaseObj, tiers )
    {
        this.audioBaseObj = audioBaseObj;
        this.tiers        = tiers;

        this.audioListener = new THREE.AudioListener();
        this.audio = new THREE.Audio(this.audioListener);
        this.audio.crossOrigin = "anonymous";

        // get the <audio> element
        const audioElement = document.querySelector('#explore-audio');
        this.audio.setMediaElementSource(audioElement);
    }

    setup() {
        
        let r=4;
        let s_r=r/20+Math.sin(0)*r/20;
        let num_of_corners=17;
        let obj_resolution=360;
        let linewidth=0.03;
        
        let group = new THREE.Object3D();
        let sub_group = new THREE.Object3D();
        let all_vertices=[];
        let all_sub_vertices=[];
        let vertices;

        this.objects=[];
        var sub_objects=[];
        var num=3;
        var dstnc=0.2;
        var border=0.04;
        this.counter=0;
        var colors=[0x379392,0x2E4952,0x0BC9C7];
        var points;
        var points2;
        var geometry;

        var _self = this;

        function create_mesh(clr,r_coof,ver_arr,wave_type){
            var geometry = new THREE.BufferGeometry();
            points   = generate_points(r,s_r,5,wave_type);
            points2  = generate_points(r*(1-linewidth),s_r,5,wave_type);
            vertices = generate_vertices(points,points2);
            ver_arr.push(vertices);
            geometry.addAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
            var material = new THREE.MeshBasicMaterial( { color: clr,wireframe:false, side:THREE.DoubleSide } );
            var mesh = new THREE.Mesh( geometry, material );
            mesh.anim_shape=num_of_corners;
            mesh.anim=-1;
            mesh.r_coof=r_coof;
            mesh.r = r;
            mesh.s_r = s_r;
            mesh.generate_points = generate_points;
            mesh.all_vertices = ver_arr;
            mesh.wave_type=wave_type;
            return mesh;
        }

        function generate_points(radius,wave_height,anim_shape,wave_type){

            var new_positions=[];
            for (var i = 0; i <=  obj_resolution; i++) {
              var angle=2*Math.PI/obj_resolution*i;
              var radius_addon=0;
              var speed_incrementer=_self.counter/40;
              var sine_pct=0.5;

              if(i<sine_pct*obj_resolution||i==obj_resolution){
                var smoothing_amount=0.14;
                var smooth_pct=1;
                if(i<sine_pct*obj_resolution*smoothing_amount)smooth_pct=i/(sine_pct*obj_resolution*smoothing_amount);
                if(i>sine_pct*obj_resolution*(1-smoothing_amount)&&i<=sine_pct*obj_resolution)smooth_pct=(sine_pct*obj_resolution-i)/(sine_pct*obj_resolution*smoothing_amount);
                if(i==obj_resolution)smooth_pct=0;

                if(wave_type==1) radius_addon=wave_height*smooth_pct*Math.cos((angle+speed_incrementer)*anim_shape);
                if(wave_type==0) radius_addon=wave_height*smooth_pct*Math.sin((angle+speed_incrementer)*anim_shape);
                if(wave_type==2) radius_addon=wave_height*smooth_pct*Math.cos((angle+Math.PI/180*120+speed_incrementer)*anim_shape);
              }

              var x = (radius+radius_addon) * Math.cos(angle+speed_incrementer);
              var y = (radius+radius_addon) * Math.sin(angle+speed_incrementer);
              var z=0;

              new_positions.push([x,y,z]);
            }

            return new_positions;
        }


        function generate_vertices(points,points2){
            var vertexPositions=[];
            var center_point=[0,0,0];

            for (var i = 0; i <  points.length-1; i++) {
            vertexPositions.push(points[i],points2[i],points[i+1]);
            vertexPositions.push(points2[i],points2[i+1],points[i+1]);
            }
            vertexPositions.push(points[ points.length-1],points2[points.length-1],points[0]);
            vertices = new Float32Array( vertexPositions.length * 3 ); 

            for ( var i = 0; i < vertexPositions.length; i++ )
            {
            vertices[ i*3 + 0 ] = vertexPositions[i][0];
            vertices[ i*3 + 1 ] = vertexPositions[i][1];
            vertices[ i*3 + 2 ] = vertexPositions[i][2];
            }

            return vertices;
        }

        for(var i=0;i<num;i++){
            var obj=create_mesh(colors[i],1+linewidth*0.8*i,all_vertices,i);
            this.objects.push(obj);
            sub_group.add(obj);
            obj.rotation.y =Math.PI/180*180;
        } 

        group.rotation.x = sub_group.rotation.x = Math.PI/180*360;

        group.scale.set(0.02,0.02,0.02);
        sub_group.scale.set(0.02,0.02,0.02);

        let fftSize  = 8192;
        this.analyser = new THREE.AudioAnalyser( this.audio, fftSize );

        this.frequencyData = this.analyser.data;
    }

    update( lineMesh, lineVector, baseVector ) {

        if( lineMesh.children[0].material.opacity < 1.0 ) lineMesh.children[0].material.opacity += 0.005;
        if( lineMesh.children[1].material.opacity < 1.0 ) lineMesh.children[1].material.opacity += 0.005;
        if( lineMesh.children[2].material.opacity < 1.0 ) lineMesh.children[2].material.opacity += 0.005;
        if( lineMesh.children[3].material.opacity < 1.0 ) lineMesh.children[3].material.opacity += 0.005;
        
        const skipFrequencies = 620;
        if( this.frequencyData && lineVector ) {

            this.doesPlay = true;
                
            lineMesh.position.x = this.currentObj.position.x;
            lineMesh.position.y = this.currentObj.position.y;
            lineMesh.position.z = this.currentObj.position.z;
            
            lineMesh.children[0].visible = true;
            lineMesh.children[0].rotation.z += 0.01;

            lineMesh.children[1].visible = true;
            lineMesh.children[1].rotation.z -= 0.005;  

            lineMesh.children[2].visible = true;
            lineMesh.children[2].rotation.z += 0.001;                                

            lineMesh.children[3].visible = true;
            lineMesh.children[3].rotation.z += 0.02;                                

            let obj3d = lineMesh;

            if( obj3d ) obj3d.scale.set( 0.2, 0.2, 0.2 );

            this.analyser.getFrequencyData(this.frequencyData);
            let factor = 0;

            for (let i = 0; i < baseVector.length; i++) {

                    if ( i < this.frequencyData.length ) {

                        let vec  = lineVector[i];
                        let base = baseVector[i];
                        
                        factor    = this.frequencyData[i] / 256 + 1;

                        vec.x = base.x + ( factor * 0.75 ) * base.x * 0.5;
                        vec.y = base.y + ( factor * 0.75 ) * base.y * 0.5;
                        //vec.x = base.x * Math.sin(factor) - base.x;
                        //vec.y = base.y * Math.sin(factor) - base.y;
                        
                        lineVector[i] = vec;

                    }

            }

            const linePoints = new THREE.CatmullRomCurve3(lineVector).getPoints( 150 );
            lineMesh.children[0].geometry.setVertices( linePoints );
            lineMesh.children[1].geometry.setVertices( linePoints );
            lineMesh.children[2].geometry.setVertices( linePoints );
            lineMesh.children[3].geometry.setVertices( linePoints );

        }
    }

    end(lineMesh) {

        if( this.noSound ) return;
        
        let obj3d = lineMesh;
                
        let lineFadeSpeed = 1.0;

        TweenMax.to( lineMesh.children[0].material, lineFadeSpeed, {
            opacity:0.0,
            ease:Sine.easeOut,
        } );

        TweenMax.to( lineMesh.children[1].material, lineFadeSpeed, {
            opacity:0.0,
            ease:Sine.easeOut,
        } );

        TweenMax.to( lineMesh.children[2].material, lineFadeSpeed, {
            opacity:0.0,
            ease:Sine.easeOut,
        } );

        TweenMax.to( lineMesh.children[3].material, lineFadeSpeed, {
            opacity:0.0,
            ease:Sine.easeOut,
            onComplete:function() {

                lineMesh.children[0].visible = false;
                lineMesh.children[1].visible = false;
                lineMesh.children[2].visible = false;
                lineMesh.children[3].visible = false;

                if( obj3d ) obj3d.scale.set( 1.0, 1.0, 1.0 );

            }
        } );                                                
/*
        TweenMax.to( this.currentObj.children[0].material, 1.0, {
            opacity:1.0,
            delay:0.4,
            ease:Sine.easeOut,
        } );
  */      
    }
}
