import React, { memo, useEffect, useState } from 'react';
import styles from '../questionsType.module.scss';
import classnames from 'classnames';
import { Modal } from '../../modal';

interface GreetingProps {
  title: string,
  name: string,
  setQuestionComplete: (value: boolean) => void,
  answer?: string[],
}

export const QuestionModal: React.FC<GreetingProps> = memo(({
  title,
  name,
  setQuestionComplete,
  answer,
}) => {
  const [active, setActive] = useState(false);

  const createAnswerText = () => {
    let answerString = '';
    if (answer) {
      for (let i = 0; i < answer.length; i ++) {
        answerString +=answer[i];
        if (i < answer.length - 1) {
          answerString += ', ';
        }
      }
    }
    return answerString;
  }

  useEffect(() => {
    setActive(true);
  }, []);

  useEffect(() => {
    let timer;
    if (active) {
      timer = setTimeout(() => {
        setQuestionComplete(true);
        setActive(false);
      }, 6500);
    }
    
    return () => {
      clearTimeout(timer);
    }
  }, [active]);

  return (
    <Modal
      className={active ? styles.greeting : classnames(styles.greeting, styles.greeting_close)}
    >
      <div className={styles.radio_greeting}>
        {
          answer && createAnswerText()
        }
      </div>
      <div className={styles.radio_greeting}>
        {title}
      </div>
      <div className={styles.radio_name}>
        {name}
      </div>
    </Modal>
  )
});