import React, { useEffect, useState } from 'react';
import { SelectProps } from '../../constants/types';
import styles from './questionsType.module.scss';
import classnames from 'classnames';
import Button from '../button';
import cloneDeep from 'lodash.clonedeep';
import { ConfirmButton } from './confirmButtons';
import { Comment } from './isComplete/comment';
import { closeQuestion, dispatchImageChange } from '../../helpers/util';
import firebaseService from '../../firebase/firebase';

let timer;

export const SelectOne: React.FC<SelectProps> = ({
  question,
  setAnswer,
  answer,
  setQuestionComplete,
  currentQuestion,
  isMobile,
  mobileClass,
}) => {
  const [isComplete, setIsComplete] = useState(false);
  const [isOpacity, setIsOpacity] = useState(false);
  const { Confirm } = question;

  const handleSetAnswer = (value: string) => {
    if (answer && question) {
      if (!answer.data.answer) {
        dispatchImageChange(currentQuestion ? currentQuestion : 0, 2);
      }
      const newAnswer = cloneDeep(answer);
      let currentValue = value;
      newAnswer.data.answer = currentValue;
      setAnswer(newAnswer);
    }
  }

  const handleConfirm = () => {
    setIsComplete(true);
    closeQuestion(timer, setQuestionComplete);
  };

  const handleSkip = () => {
    firebaseService.handleSkipEvent({"question": currentQuestion});
    setIsComplete(true);
    closeQuestion(timer, setQuestionComplete);
  };

  const handleSetComment = (value: string) => {
    if (answer) {
      const newAnswer = cloneDeep(answer);
      newAnswer.data.comment = value;
      setAnswer(newAnswer);
    }
  };

  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    timer = setTimeout(() => setIsOpacity(true), 100);
  }, []);

  useEffect(() => {
    if(!answer) {
      setAnswer({
        id: question.id,
        data: {
          answer: '',
          comment: '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, answer]);
  return (
    <div className={isComplete ? classnames(styles.typeQuestion, styles.typeConfirm, mobileClass(styles)) : classnames(styles.typeQuestion, isOpacity ? styles.typeAnimation : '', mobileClass(styles))}>
      {
          question && (
            <>
              <div className={styles.question_title}>
                {
                question.data.question.map((q, index) => (
                  <div key={index} className={styles.inner_title}>
                    {q}
                  </div>
                ))
                }
              </div>
              <div className={styles.selectButtons}>
                {
                  question.data.attributes.map((button, index) => (
                    <Button
                      key={index}
                      className={answer && answer.data.answer === button ? classnames(styles.selectButton, styles.selectButton_active) : styles.selectButton}
                      onClick={() => handleSetAnswer(button)}
                      buttonStyles={styles.selectButtonWrapper}
                    >
                      {button}
                    </Button>
                  ))
                }
              </div>
            </>
          )
      }
      {
        answer && answer.data.answer && Confirm
          ? (
            <Comment
              answer={answer ? answer.data.answer : ''}
              setComment={handleSetComment}
              comment={answer ? answer.data.comment : ''}
              label={question ? question.data.label : ''}
            />
          )
          : null
      }
      <ConfirmButton
        isComplete={true}
        isConfirm={!Confirm}
        handleConfirm={handleConfirm}
        handleSkip={handleSkip}
        hideConfirm={answer?.data.answer === null && !!Confirm}
        isMobile={isMobile}
        disabled={isComplete}
        currentQuestion={currentQuestion}
      />
    </div>
  )
}