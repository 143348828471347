import React, { memo, useEffect, useState } from "react";
import styles from "./teaWithRichVideoPage.module.scss";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import ReactPlayer from "react-player";
import { Play } from "../svgIcons";
import Button from "../button";
import { pathName } from "../../constants/constants";
import Lottie from "react-lottie";
import animData from "../../constants/lottie/scrollIcon.json";

interface TeaWithRichVideoPageProps {
  isMobile: boolean;
}

const TeaWithRichVideoPage = ({ isMobile }: TeaWithRichVideoPageProps) => {
  const history = useHistory();
  const videoClickHandler = () => {
    document.dispatchEvent(new CustomEvent("grace_request_stop_audio"));
  };
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: animData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={styles.aboutWrapper}>
      <div className={styles.about}>
          <div className={styles.about_title}>Tea with Rich</div>
          <img className={styles.horizontal_line} src='/images/horizontalLine.png' alt='horizontal line' />
          <div
              className={
                  isMobile
                  ? classnames(styles.thinkerVideo, styles.mobileVideo)
                  : styles.thinkerVideo
              }
              >
              <ReactPlayer
                  url="https://youtu.be/URrikS7ik0Q"
                  light="./images/postcard_rich.jpg"
                  playIcon={<div className={styles.videoPlayButton}><Play /></div>}
                  controls={true}
                  onPlay={videoClickHandler}
              />
              </div>
          <div className={styles.description}>
            <p style={{marginBottom:'2em'}}>Grace's keynote received significant traction, resulting in journalist Rich Whitworth following up with Grace on The Analytical Scientist's well-known interview series 'Tea with Rich'.</p>
          </div>
      </div>
    </div>
  );
};
export default memo(TeaWithRichVideoPage);
