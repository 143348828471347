import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { pathName } from '../../constants/constants';
import Button, { ShareButton } from '../button';
import styles from './successPage.module.scss';
import { SocialButtons } from '../footer/socialButtons';
import Lottie from 'react-lottie';
import successAnim from '../../constants/lottie/success.json';
import successAnimCollapse from '../../constants/lottie/successCollapse.json';
import { Back } from '../svgIcons';

interface SuccessPageProps {
  isMobile: boolean,
}

export const SuccessPage: React.FC<SuccessPageProps> = memo(({
  isMobile,
}) => {
  const [collapse, setCollapse] = useState(false);
  const [isSocial, setIsSocial] = useState(false);
  const history = useHistory();
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: successAnim,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const collapseOptions = {
    loop: false,
    autoplay: true, 
    animationData: successAnimCollapse,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className={styles.successWrapper}>
      <div className={styles.successContainer}>
        <Lottie
          options={collapse ? collapseOptions : defaultOptions}
          width={100}
          height={100}
          eventListeners={[
            {
              eventName: 'complete',
              callback: () => history.push(pathName.explore),
            }
          ]}
        />
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            Thank you for mentoring Grace.
          </div>
        </div>
        <div className={styles.description}>
          Your contribution allows perspectives from all corners 
          of the scientific community to come together, positioning 
          Grace as the pulse of the industry and a leader of inclusive change.
        </div>
        <div className={styles.description}>
          Grace is reviewing your perspective and will let you know when you’ve 
          been published in her initiative. For now, why not explore other industry 
          perspectives and see what they had to say about improving science 
          for future generations.
        </div>
        <div className={styles.voicesButton}>
          <Button
            className={styles.buttonNext}
            buttonStyles={styles.buttonNextWrapper}
            onClick={() => setCollapse(true)}
          >
            Explore other voices
          </Button>
        </div>
        {/* <div className={styles.shareButton}>
          <ShareButton
            icon={<Back />}
            title='Click here to share the survey'
          />
        </div> */}
        <div className={styles.shareContainer}>
          <div className={styles.share}>
            <ShareButton 
              icon={<Back />}
              title='Click here to share the survey'
              onClick={() => setIsSocial(s => !s)}
            />
          </div>
          
          {
            isSocial
            ? (
              <div className={styles.voices_social}>
                <SocialButtons
                  isMobile={isMobile}
                  isExploreUrl={false}
                />
              </div>
            )
            : null
          }
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.skipButton}
            onClick={() => history.push(pathName.startPage)}
          >
            {`go to homepage >`}
          </Button>
        </div>
      </div>
    </div>
  )
});