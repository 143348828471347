import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/analytics';
import flamelink from 'flamelink/app';
import 'flamelink/content'
import 'flamelink/storage'
import { rejects } from 'node:assert';
import srtParser2 from 'srt-parser-2';

class FirebaseService {
  private static instance: FirebaseService;
  isUserAuthenticated:Boolean;
  firebaseApp!:firebase.app.App;
  flamelinkApp!:flamelink.app.App;
  firestore!:firebase.firestore.Firestore;
  
  constructor(){
    this.isUserAuthenticated = false;
    this.initialise();
  }

  public static getInstance(): FirebaseService {
    if (!FirebaseService.instance) {
      FirebaseService.instance = new FirebaseService();
    }
    return FirebaseService.instance;
  }
  
  initialise():void {

    // console.log(`API KEY: ${process.env.REACT_APP_API_KEY}`); <== NOPE!!! Don't log this!

    const config = {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID
    };
    var user!:firebase.User;
    const fApp = firebase.initializeApp(config);
    const flameApp = flamelink({
      firebaseApp: fApp,
      env: 'production', // optional, defaults to `production`
      locale: 'en-US', // optional, defaults to `en-US`
      dbType: 'cf' // optional, defaults to `rtdb` - can be 'rtdb' or 'cf' (Realtime DB vs Cloud Firestore)
    });
    this.firebaseApp = fApp;
    this.flamelinkApp = flameApp;
    this.firestore = firebase.firestore();
  
    // now login anonymously and then listen for changes to this state
    this.firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        var uid = user.uid;
        this.isUserAuthenticated = true;
      } else {
        user = null;
        this.isUserAuthenticated = false;
      }
    });
    
    firebase.auth().signInAnonymously()
    .then(() => {
      // console.log("User is authenticated anonymously");
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      // console.error(`Firebase Auth Error: ${errorCode} / ${errorMessage}`);
    });

    // // TODO: DEBUG - REMOVE BEFORE PRODUCTION

    // this.getSurveyDocuments().then(responseData => {
    //   console.log("Survey documents: ", responseData);
    //   // let jsonObject = {};  
    //   // responseData.forEach((value, key) => {  
    //   //     jsonObject[key] = value;
    //   // }); 
    //   // let jsonString = JSON.stringify(jsonObject);
    //   // console.log(jsonString);
    // })
    // .catch((error) => {
    //   console.log("Error getting Survey documents: ", error);
    // });

    // this.parseSRTTextToObjectFromDocumentId("QraSHBle0KNY31GNhigJ").then(resultObj => {
    //     console.log(`SRT Object: ${resultObj}`);
    // }).catch((error) => {
    //     console.log("Error getting SRT Object: ", error);
    //   });
  }

  createNewSurveyDocument(recordData:Object):Promise<any>{
    return this.flamelinkApp.content.add({
      schemaKey: 'survey',
      data: recordData,
    })
  }

  updateDocumentWithUserData(id:string, userData:Object):Promise<any>{
    return this.flamelinkApp.content.update({
      schemaKey: 'survey',
      entryId: id,
      data: userData,
    })
  }
  
  uploadAudioFileAndSurveyData(blob:Blob | null, recordData:Object):Promise<any>{
    // first upload the data so we have a Document reference we can use to store the blob
    const fthis = this;
    return new Promise(function(resolve, reject) {
      fthis.createNewSurveyDocument(recordData)
      .then(doc => {
        // console.log(`Created new Survey document: ${doc}`);
        // now upload the blob if it exists
        if(blob != null){
          // console.log(`Uploading Blob: ${blob}`);          
          fthis.flamelinkApp.storage.upload(blob, {
            metadata: {
              name: `${doc.id}.wav`,
              contentType: 'audio/wav'
            },
            folderName: 'audio'
          })
          .then(fileObject => {
            // console.log(`Uploaded audio file to Storage: ${fileObject.id}`);
            fthis.flamelinkApp.storage.getURL({fileId:fileObject.id})
            .then(urlString => {
              // console.log(`File URL: ${urlString}`);
              recordData["surveyData"].q13_1 = fileObject.id;
              recordData["surveyData"].voiceMessageUrl = urlString;
              // finally update the record we just created with the file URL
              fthis.flamelinkApp.content.update({
                schemaKey: 'survey',
                entryId: doc.id,
                data: recordData,
              })
              .then(result => {
                // console.log(`Final record: ${result}`);
                resolve(result);
              })
              .catch(error => reject(Error('Error updating Survey entry')))
            })
            .catch(error => reject(Error('Error retrieving image URL')))
          })
          .catch(error => reject(Error('Error uploading Blob file')))
        }else{
          resolve(doc);
        }
      }).catch(error => reject(Error('Error creating Survey entry')))
    })
  }

  parseSRTTextToObjectFromDocumentId(docId:string):Promise<any>{
    const fthis = this;
    return new Promise(function(resolve, reject) {
      fthis.getSRTTextFromDocument(docId).then(srtString => {
        var parser = new srtParser2();
        var result = parser.fromSrt(srtString);
        // console.log(result);
        resolve(result);
      }).catch((error) => {
        // console.log("Error parsing SRT String", error);
        reject(error);
      });
    });
  }

  getSRTTextFromDocument(docId:string):Promise<any>{
    const fthis = this;
    return new Promise(function(resolve, reject) {
      // get the URL first
      fthis.getSRTUrlForDocument(docId).then(url => {
        fetch(url)
        .then(response => response.text())
        .then((data) => {
          // console.log(data);
          resolve(data);
        }).catch((error) => {
          // console.log("Error downloading SRT as Text: ", error);
          reject(error);
        });
      }).catch((error) => {
          // console.log("Error getting SRT File URl: ", error);
          reject(error);
        });
    });
  }

  getSRTUrlForDocument(docId:string):Promise<any>{
    const fthis = this;
    return new Promise(function(resolve, reject) {
      fthis.getSurveyDocumentWithId(docId).then(doc => {
        // this is the raw JSON so let's grab that Storage reference for the SRT file
        let fileDocRefArr = doc.surveyData.subtitleFile;
        if(fileDocRefArr && fileDocRefArr.length > 0){
          let fileDocRef = fthis.firestore.doc(fileDocRefArr[0].path);
          fileDocRef.get().then(fileDoc => {
            let fileDocData = fileDoc.data();
            if(fileDocData != null){
              fthis.flamelinkApp.storage.getURL({ fileId: fileDocData.id }).then(url => {
                resolve(url);
              }).catch((error) => {
                // console.log("Error getting file URL:", error);
                reject(error);
              });
            }else{
              reject(new Error('No File ID from doc data'));
            }
          }).catch((error) => {
            // console.log("Error getting file document ref:", error);
            reject(error);
          });
        }else{
          // console.log("Error getting file document ref froom Array");
            reject(new Error("Error getting file document ref from Array"));
        }
      }).catch((error) => {
          // console.log("Error getting survey document:", error);
          reject(error);
      });
    });
  }

  getSurveyDocumentWithId(docId:string):Promise<any>{
    const fthis = this;
    return new Promise(function(resolve, reject) {
      var docRef = fthis.firestore.collection("fl_content").doc(docId);
      docRef.get().then((doc) => {
        if (doc.exists) {
            resolve(doc.data());
            // console.log("Document data:", doc.data());
        } else {
          // console.log("No such document!");
            reject(Error(`Document not found ${docId}`));
        }
      }).catch((error) => {
          // console.log("Error getting document:", error);
          reject(error);
      });
    });
  }

  getTotalSurveyDocuments():Promise<any>{
    const fthis = this;
    return new Promise(function(resolve, reject) {
      let queryRef = fthis.firestore.collection("fl_content")
      .where('_fl_meta_.env', "==", 'production')
      .where('_fl_meta_.locale', "==", 'en-US')
      .where('_fl_meta_.schema', "==", 'survey')
      .where('_fl_meta_.status', "==", 'publish')
      .where('publishStatus', "==", 'approved')
      .get().then((querySnapshot) => {
        if(querySnapshot.size == 0){
          reject(new Error('No documents returned'));
        }else{
          resolve(querySnapshot.size);
        }
      })
      .catch((error) => {
        // console.log("Error getting Survey documents: ", error);
        reject(error);
      });
    });
  }

  getSurveyDocuments(docId?:string):Promise<any>{

    if(docId){
      // console.log(`DEEPLINK: ${docId}`);
    }
    const fthis = this;
    return new Promise(function(resolve, reject) {
      let queryRef = fthis.firestore.collection("fl_content")
      .where('_fl_meta_.env', "==", 'production')
      .where('_fl_meta_.locale', "==", 'en-US')
      .where('_fl_meta_.schema', "==", 'survey')
      .where('_fl_meta_.status', "==", 'publish') // <-- TODO: this needs to be on when we are live!
      .where('publishStatus', "==", 'approved') // <-- TODO: this needs to be on when we are live!
      .where('priority', "==", 'high')
      .limit(200);
      queryRef.get().then((querySnapshot) => {
        
        var responseData = new Map<string, any>();
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          var data = doc.data();
          var dataObj = fthis.getDataObjFromData(data);
          responseData.set(data.id, dataObj);
          // let jsonString = JSON.stringify(doc.data())+", ";
          // console.log(jsonString);
        });
        if(responseData.keys()){
          // console.log("Survey documents: ", responseData);
          // Need to check to see if we need to add a required document
          if(docId != null){
            // console.log(responseData[docId]);
            if(responseData[docId] != null){
              // exists already, just edit the data
              responseData[docId].deeplink = true;
              resolve(responseData);
            }else{
              // we need to get the document and add it
              fthis.getSurveyDocumentWithId(docId).then( data => {
                var dataObj = fthis.getDataObjFromData(data, true);
                responseData.set(docId, dataObj);
                resolve(responseData);
              })
              .catch((error) => {
                // we don't throw an error here. We just don't add the deeplink document.
                resolve(responseData);
              });
            }
          }else{
            resolve(responseData);
          }
        }else{
          reject(Error("Firestore didn't fail but it returned no documents"));
        }
      })
      .catch((error) => {
        // console.log("Error getting Survey documents: ", error);
        reject(error);
      });
    });
  }

  getFilteredSurveyDocuments(filters: Object[]):Promise<any>{

    // console.log(filters);
    const fthis = this;
    return new Promise(function(resolve, reject) {
      var queryRef = fthis.firestore.collection("fl_content")
      .where('_fl_meta_.env', "==", 'production')
      .where('_fl_meta_.locale', "==", 'en-US')
      .where('_fl_meta_.schema', "==", 'survey')
      .where('publishStatus', "==", 'approved')
      .where('_fl_meta_.status', "==", 'publish');

      // now we add to the query with the contents of the filter array
      filters.forEach((obj) => {
        let path = obj['path'];
        let value = obj['value'];
        if(value != null && value != ""){
          // console.log(`filter : ${path} = ${value}`);
          queryRef = queryRef.where(path, "==", value );
        }
      });

      queryRef.limit(100);
      queryRef.get().then((querySnapshot) => {
        
        var responseData = new Map<string, any>();
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          var data = doc.data();
          var dataObj = fthis.getDataObjFromData(data, querySnapshot.size == 1);
          responseData.set(data.id, dataObj);
          // let jsonString = JSON.stringify(doc.data())+", ";
          // console.log(jsonString);
        });
        if(responseData.size > 0){
          // console.log("Survey documents: ", responseData);
          resolve(responseData);
        }else{
          // console.log("No Data");
          reject(Error("Firestore didn't fail but it returned no documents"));
        }
      })
      .catch((error) => {
        console.log("Error getting Survey documents: ", error);
        reject(error);
      });
    });
  }

  getDataObjFromData(data, isDeepLink:boolean = false) {
    
    var type = data.surveyData.voiceMessageUrl == undefined || data.surveyData.voiceMessageUrl == "" ? 2 : 0;

    var dataObj = {
      id: data.id,
      type: type,
      userData: data.userData,
      audioUrl: data.surveyData.voiceMessageUrl,
      message: data.surveyData.q12_2,
      deeplink: isDeepLink,
      imageURL: this.getImageUrl(data.id)
    }
    return dataObj;
  }

  getImageUrl(docId:string):string | undefined {

    /*
    Isabelle Kohler	Iq60zxzBnKvV8gWEtlhq - public/images/thinkers/textures/Isabelle_Kohler.png✅
    Sisters in Science: Mimi 	gZDHpJzrjBW54RiOiGXd✅
    Sisters in Science: Lotte	WDbCbJrVOW5m7MfOoM1Z✅
    Sisters in Science: Noor	
    Raven Baxter (Raven the Science Maven)	6XPSapLymG61WsgupNwJ - public/images/thinkers/textures/Raven_Baxter.png ✅
    Nathan Basisty	kjgP1bC8GgwaOP3IXRSN - public/images/thinkers/textures/Nathan_Basisty.png✅
    Natalie Panek	ueAL07zSK2253xOxLRl9 - public/images/thinkers/textures/Natalie_Panek.png✅
    Birgit Schilling	G5ZBVH3zg1SoQ1Eg72me - public/images/thinkers/textures/Brigit_Shilling.png✅
    Maarten Daernens	VfRbSlQaqgVXtVkH8yib - public/images/thinkers/textures/Maarten_Dhaenens.png✅
    Grace Van Der Gugten	NuAh4QUZSlvuEKVQPVIv - public/images/thinkers/textures/Grace_Van_der_Gugten.png✅
    Christina Jones	
    Joanna Bons	vBpAXOAQWJJkGi8yUQiu - public/images/thinkers/textures/Joanna_Bons.png✅
    Jessica Prenni	a1BZxFp6fHZTAlW6sqP0 - public/images/thinkers/textures/Jessica_Prenni.png✅
    Karen Possimato	0bmZpi0QxWOFTkWXuUIK
    Daniel Hornburg	EKUQpZNRPuzM3ICNeWHz - public/images/thinkers/textures/Daniel_Hornburg.png✅
    */

    // look-up table for influencers
    var textureUrlData = new Map<string, string>();

    textureUrlData.set("Iq60zxzBnKvV8gWEtlhq", "./images/thinkers/textures/Isabelle_Kohler.png");
    textureUrlData.set("gZDHpJzrjBW54RiOiGXd", "./images/thinkers/textures/Mimi_den_uyl.png");
    textureUrlData.set("WDbCbJrVOW5m7MfOoM1Z", "./images/thinkers/textures/Lotte_Schreuders.png");
    textureUrlData.set("6XPSapLymG61WsgupNwJ", "./images/thinkers/textures/Raven_Baxter.png");
    textureUrlData.set("kjgP1bC8GgwaOP3IXRSN", "./images/thinkers/textures/Nathan_Basisty.png");
    textureUrlData.set("ueAL07zSK2253xOxLRl9", "./images/thinkers/textures/Natalie_Panek.png");
    textureUrlData.set("G5ZBVH3zg1SoQ1Eg72me", "./images/thinkers/textures/Brigit_Shilling.png");
    textureUrlData.set("VfRbSlQaqgVXtVkH8yib", "./images/thinkers/textures/Maarten_Dhaenens.png");
    textureUrlData.set("NuAh4QUZSlvuEKVQPVIv", "./images/thinkers/textures/Grace_Van_der_Gugten.png");
    textureUrlData.set("vBpAXOAQWJJkGi8yUQiu", "./images/thinkers/textures/Joanna_Bons.png");
    textureUrlData.set("a1BZxFp6fHZTAlW6sqP0", "./images/thinkers/textures/Jessica_Prenni.png");
    textureUrlData.set("EKUQpZNRPuzM3ICNeWHz", "./images/thinkers/textures/Daniel_Hornburg.png");

    // console.log(`LOOKING FOR: ${docId}`);
    // if(textureUrlData.get(docId)){
    //   console.log(`FOUND THINKER: ${textureUrlData.get(docId)}`);
    // }
    return textureUrlData.get(docId);
  }

  // ANALYTICS --------------------------------------
  logCustomEvent(name:string, data:Object){
    this.firebaseApp.analytics().logEvent(name, data);
  }

  handleSkipEvent(data:Object){
    this.logCustomEvent("skip_button_click_event", data);
  }
}

const firebaseService = FirebaseService.getInstance();
export default firebaseService;