import React, { memo } from 'react';
import Button from '..';
import { LeftArrow, RightArrow } from '../../svgIcons';
import { ShuffleIcon } from '../../svgIcons/icons';
import styles from '../button.module.scss';

interface NavigatorProps {
  isMobile: boolean,
  leftClick: () => void,
  rightClick: () => void,
  shuffleClick: () => void,
};

export const Navigator: React.FC<NavigatorProps> = memo(({
  isMobile,
  leftClick,
  rightClick,
  shuffleClick,
}) => {
  return (
    <div className={styles.navigatorContainer}>
      <Button
        className={styles.buttonNext}
        buttonStyles={styles.buttonNextWrapper}
        onClick={leftClick}
      >
        <LeftArrow />
      </Button>
      <Button
        className={styles.buttonNext}
        buttonStyles={styles.buttonNextWrapper}
        onClick={shuffleClick}
      >
        <ShuffleIcon />
      </Button>
      <Button
        className={styles.buttonNext}
        buttonStyles={styles.buttonNextWrapper}
        onClick={rightClick}
      >
        <RightArrow />
      </Button>
    </div>
  )
});