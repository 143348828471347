import React, { memo } from 'react';
import Button from '..';
import styles from '../button.module.scss';

interface ShareButtonProps {
  onClick?: () => void,
  icon: JSX.Element,
  title: string,
}

export const ShareButton: React.FC<ShareButtonProps> = memo(({
  onClick,
  icon,
  title,
}) => {
  return(
    <Button
      onClick={onClick}
      className={styles.shareButton}
    >
      <>
        {icon}
        <p>{title}</p>
      </>
    </Button>
  )
});