import React, { memo, useEffect, useState } from 'react';
import styles from './modalMenu.module.scss';
import classnames from 'classnames';

interface ModalProps {
  children?: JSX.Element | string,
  active: boolean,
}
let timer;
export const ModalMenu: React.FC<ModalProps> = memo(({
  children,
  active,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (active) {
      timer = setTimeout(() => setOpen(true), 200);
    } else {
      clearTimeout(timer);
      setOpen(false);
    }
  }, [active]);

  return (
    <div className={active ? classnames(styles.modal, styles.modal__active) : styles.modal}>
      <div className={styles.modal_panel_1} />
      <div className={styles.modal_panel_2} />
      <div className={styles.modal_panel_3} />
      {
        open ? children : null
      }
    </div>
  )
})