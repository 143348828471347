import WebGLView from './webgl/WebGLView';
import { getGPUTier } from 'detect-gpu';

class ExplorerApp {

	constructor() {
		this.isInitialised = false;
		this.isSuspended = false;
		this.webgl = null;
		this.delayTimer = null;
	}

	init() {
		let _self = this;
		(async () => {
		 	const tiers = await getGPUTier();
			_self.initWebGL( tiers );
			_self.initCanvas();
			_self.addListeners();
			_self.animate();
			_self.resize();
			_self.isInitialised = true;
			// console.log("EXPLORER INIT");
		})();
	}

	suspend(){
		// console.log("EXPLORER SUSPENDING");
		// TODO: stop rendering, processing and clear the data
		this.isSuspended = true;
		this.webgl.isSuspended = true;
	}

	resume(){
		// console.log("EXPLORER RESUMING");
		// TODO: resume rendering, processing and also get the data again
		this.initCanvas();
		this.isSuspended = false;
		this.webgl.isSuspended = false;
		try {
			this.delayTimer = setTimeout(() => {
				document.dispatchEvent( new CustomEvent("grace_request_explorer_ready" ));
			}, 300);
		  } catch (error) {
			console.log(error.message);
		  }
	}

	initWebGL( tiers ) {
		this.webgl = new WebGLView(this, tiers);
	}
	
	initCanvas(){
		document.querySelector('.explorer').appendChild(this.webgl.renderer.domElement);
	}

	addListeners() {
		this.handlerAnimate = this.animate.bind(this);
		window.addEventListener('resize', this.resize.bind(this));
		// const el = this.webgl.renderer.domElement; // <-- this isn't doing anything?
	}

	animate() {
		this.update();
		this.draw();
		this.raf = requestAnimationFrame(this.handlerAnimate);
	}

	// ---------------------------------------------------------------------------------------------
	// PUBLIC
	// ---------------------------------------------------------------------------------------------

	update() {
		if (this.webgl && !this.isSuspended) this.webgl.update();
	}

	draw() {
		if (this.webgl && !this.isSuspended) this.webgl.draw();
	}

	// ---------------------------------------------------------------------------------------------
	// EVENT HANDLERS
	// ---------------------------------------------------------------------------------------------

	resize() {
		if (this.webgl) this.webgl.resize();
	}

	requestImageRemoveHandler(e) {

	}
}

const explorerApp = new ExplorerApp();
export default explorerApp;