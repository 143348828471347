import React, { useEffect, useState } from 'react';
import { SelectProps } from '../../constants/types';
import styles from './questionsType.module.scss';
import classnames from 'classnames';
import Button from '../button';
import cloneDeep from 'lodash.clonedeep';
import { ConfirmButton } from './confirmButtons';
import { closeQuestion, dispatchImageChange } from '../../helpers/util';
import firebaseService from '../../firebase/firebase';

let timer;

export const SeveralQuestion: React.FC<SelectProps> = ({
  question,
  setAnswer,
  answer,
  setQuestionComplete,
  currentQuestion,
  isMobile,
  setModal,
  mobileClass,
}) => {
  const [isComplete, setIsComplete] = useState(false);
  const [isOpacity, setIsOpacity] = useState(false);
  const { Confirm } = question;

  const handleSetAnswer = (value: string, index: number) => {
    if (answer) {
      const length = answer.data.answer.length;
      const newAnswer = cloneDeep(answer);
      let currentValue = value;
      newAnswer.data.answer[index] = currentValue;

      setAnswer(newAnswer);
    }
  }

  const handleConfirm = () => {
    if (Confirm && setModal) {
      setIsComplete(true);
      if (question) {
        setModal(
          <Confirm
            answer={question.data.correct}
            title={`Now guess what... we won the grant! Can you believe it!? Change is coming!`}
            name={``}
            setQuestionComplete={setQuestionComplete}
          />
        )
        dispatchImageChange(currentQuestion || 1, 2);
      } else {
        setIsComplete(true);
        dispatchImageChange(currentQuestion || 1, 2);
        closeQuestion(timer, setQuestionComplete);
      }
    } else {
      setIsComplete(true);
      dispatchImageChange(question.id, 2);
      closeQuestion(timer, setQuestionComplete);
    }
  };

  const handleSkip = () => {
    firebaseService.handleSkipEvent({"question": currentQuestion});
    setIsComplete(true);
    dispatchImageChange(question.id, 2);
    closeQuestion(timer, setQuestionComplete);
  };

  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    timer = setTimeout(() => setIsOpacity(true), 100);
  }, []);


  useEffect(() => {
    if(!answer) {
      setAnswer({
        id: question.id,
        data: {
          answer: ['undefined', 'undefined', 'undefined'],
          comment: '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, answer]);
  
  const isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;

  return (
    <>
      <div className={isComplete ? classnames(styles.typeQuestion, styles.typeConfirm, mobileClass(styles)) : classnames(styles.typeQuestion, isOpacity ? styles.typeAnimation : '', mobileClass(styles))}>
        
        {
            question && (
              <>
                <div className={styles.question_title}>
                  {question.data.label}
                </div>
                  {
                    question.data.attributes.map((button, index) => {
                      return (
                      <React.Fragment key={index}>
                      {
                        answer && answer.data.answer.length >= index && (
                          <>
                          <div className={styles.several_title}>
                            {question.data.question[index][0]}
                          </div>
                          <div className={classnames(styles.selectButtons, isComplete && isSafari ? styles.selectButtonsHidden : '')}>
                            {
                              button.map((b, i) => (
                                <Button
                                  key={i}
                                  className={answer && answer.data.answer[index] === b ? classnames(styles.selectButton, styles.selectButton_active) : styles.selectButton}
                                  onClick={() => handleSetAnswer(b, index)}
                                  buttonStyles={styles.selectButtonWrapper}
                                >
                                  {b}
                                </Button>
                              ))
                            }
                          </div>
                          </>
                        )
                      }
                      </React.Fragment>)
                    })
                  }
              </>
            )
        }
        <ConfirmButton
          className={isComplete ? styles.radio_confirm : ''}
          isComplete={true}
          isConfirm={!Confirm}
          handleConfirm={handleConfirm}
          handleSkip={handleSkip}
          hideConfirm={(!answer?.data.answer || answer.data.answer.length !== 3) && !!Confirm}
          isMobile={isMobile}
          disabled={isComplete}
          currentQuestion={currentQuestion}
        />
      </div>
    </>
  )
}