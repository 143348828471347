import React, { memo, useEffect } from 'react';
// import particleApp from './KeynoteParticleCanvasApp';
import styles from './KeynoteParticleCanvas.module.scss';
import classnames from 'classnames';
import KeynoteParticleCanvasApp from './KeynoteParticleCanvasApp';

interface KeynoteParticleCanvasProps { 
  isMobile: boolean,
  canvasClassName: string,
  imageReference: string,
  scrollSpeed: number,
  scrollPos: number,
  particleScroll: number,
  particleApp: KeynoteParticleCanvasApp,
  particleRandomnessMax?: number,
  particleRandomnessMin?: number,
}

export const KeynoteParticleCanvas: React.FC<KeynoteParticleCanvasProps> = memo(({
    isMobile,
    canvasClassName,
    imageReference,
    scrollSpeed,
    scrollPos,
    particleScroll,
    particleApp,
    particleRandomnessMax = 300,
    particleRandomnessMin = 0,
  }) => {

    useEffect(() => {
      // console.log(scrollSpeed);
      if(particleApp != null && particleApp.isInitialised){
        // console.log(`${imageReference} / ${particleScroll}`);
        // particleScroll is an inverse Lerp between the scroll min and max that we want to affect
        // use the lerp to create a randomness value for the particle
        const randomness = (particleRandomnessMax-(particleRandomnessMax*particleScroll))+particleRandomnessMin;
        particleApp.particleRandomness = randomness;
        particleApp.scrollLerp = particleScroll;
        if(particleApp.webgl?.isSuspended){
          particleApp.resume();
        }
        // console.log(particleApp);
      }else{
        // app instance doesn't exist so initialise it
        // particleApp = new KeynoteParticleCanvasApp();
        particleApp.init(canvasClassName, imageReference, scrollPos, particleScroll);
      }

      // cleanup - this is where we need to suspend the ParticleApp
      // return () => {
      //   particleApp.suspend();
      // }
    }, [scrollPos]);

    useEffect(() => {
      return () => {
        particleApp.suspend();
      }
    }, [])

    /**
     * TODO: create state hook for updating the particleApp particleRandomness property with the new prop from the scroll speed
     */

    return (
      <div className={canvasClassName}></div>
      )
    })