import React, { memo, useEffect, useState } from 'react';
import styles from './KeynoteThankYou.module.scss';
import classnames from 'classnames';
import Lottie from 'react-lottie';
import animData from '../../constants/lottie/bookedSeat.json';
import { useHistory } from 'react-router-dom';
import { pathName } from '../../constants/constants';
import Button from '../button';

interface KeynoteThankYouProps {
    isMobile: boolean,
}

const KeynoteThankYou = ({
    isMobile,
  }: KeynoteThankYouProps) => {

    const animationOptions = {
        loop: true,
        autoplay: true, 
        animationData: animData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const history = useHistory();

    return (
    <div className={styles.mainContainer} >
      <Lottie
          options={animationOptions}
          width={112}
          height={112}
        />
      <div className={styles.innerContainer} >
        <h2>Your seat is booked</h2>
        <p>Success! You have booked your place at the global launch of Grace's keynote, July 21, 2022 (5pm CET/ 11am EST/ 8am PDT). The premiere will be followed by a panel discussion about “Cultivating​ an inclusive and equitable science ecosystem for all”. You can get your questions on all things DEI (Diversity, Equity, Inclusion) in STEM answered in a live Q&amp;A. Keep an eye out for an email confirming your place and for further information about the event.</p>
        <p>In the meantime, if you haven't already, answer the questions that will help Grace navigate her way through her scientific career.</p>
        <p>Simply click the link below to add your voice to this global conversation.</p>
        <Button
            onClick={() => history.push(pathName.questionPage)}
            className={styles.buttonNext}
            style={{marginTop:'2em'}}
            buttonStyles={styles.buttonNextWrapper}
            >
            MEET GRACE
        </Button>
        <p className={styles.plink}>
        <span className={styles.check_terms} onClick={() => history.push(pathName.startPage)}>
        {`back to homepage`}
        </span>
        </p>
      </div>
    </div>
    )
  }
export default memo(KeynoteThankYou);

