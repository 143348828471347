import { Form } from 'formik';
import React, { memo, useEffect, useState } from 'react';
import { formData } from '../../constants/constants';
import { SubmitInput } from '../submitPage/form/submitInput';
import { SubmitSelect } from '../submitPage/form/submitSelect';
import styles from './filterPage.module.scss';
import classnames from 'classnames';
import Button from '../button';
import { CloseVoice } from '../svgIcons';
import cloneDeep from 'lodash.clonedeep';

interface FilterFormProps {
  isMobile: boolean,
  values: {
    [type: string]: string | number,
  },
  setFieldValue: (type: string, value: string | number) => void,
  handleClear: (value: (value: any) => void) => void,
  resetForm: (value: any) => void,
  handleClose: () => void,
  filterVoices: (value: any) => Promise<Map<string, string>>,
}

export const FilterForm: React.FC<FilterFormProps> = memo(({
  isMobile,
  values,
  setFieldValue,
  handleClear,
  resetForm,
  handleClose,
  filterVoices,
}) => {
  const [total, setTotal] = useState<string | null>(null);

  const customBlur = () => {
    // console.log(values);
    filterVoices(values)
    .then((data) => {
      if (data) {
        setTotal(`${data.size}`);
      }else {
        setTotal(`${0}`);
      }
    }).catch(error => {
      console.log(error);
      setTotal(`${0}`);
    })
  }

  const disciplineBlur = (value?: string | number) => {
    const newValues = cloneDeep(values);
    if(value){
      newValues.discipline = value;
    }
    // console.log(newValues);
    filterVoices(newValues)
    .then((data) => {
      if (data) {
        setTotal(`${data.size}`);
      }else {
        setTotal(`${0}`);
      }
    }).catch(error => {
      console.log(error);
      setTotal(`${0}`);
    })
  }

  return (
    <Form>
      <SubmitInput
        title='Name'
        name='name'
        type='text'
        error=''
        touched=''
        className={styles.input}
        isMobile={isMobile}
        placeholder='Type here...'
        customBlur={customBlur}
      />
      {/* <SubmitInput
        title='Location'
        name='location'
        type='text'
        error=''
        touched=''
        className={styles.input}
        isMobile={isMobile}
        placeholder='Type here...'
        customBlur={customBlur}
      /> */}
      <SubmitSelect
        isMobile={isMobile} 
        current={String(values.discipline)}
        data={formData.discipline.values}
        title='Scientific discipline'
        placeholder={formData.discipline.placeholder}
        error=''
        touched=''
        setValue={(value: string | number) => {
          // console.log(`set field value: ${value}`);
          setFieldValue('discipline', value);
          disciplineBlur(value);
        }}
        upper={!isMobile}
      />
      {/* <div className={classnames(styles.totalCount, parseInt(total) === 0 ? styles.totalError : '')}> */}
      <div className={styles.totalCount}>
        {
          total ? `${total} results` : null
        }
      </div>
      <div className={classnames(styles.buttonWrapper, isMobile ? styles.buttonMobile : '')}>
        <div>
          <Button
            type='submit'
            className={styles.buttonNext}
            disabled={total === null || total === '0'}
          >
            Apply
          </Button>
          <Button
            className={styles.skipButton}
            onClick={() => handleClear(resetForm)}
          >
            Clear All
          </Button>
        </div>
        {
          isMobile? (
            <Button
              onClick={handleClose}
              className={classnames(styles.buttonNext, styles.filterCloseButton)}
            >
              <CloseVoice />
            </Button>
          )
          : null
        }
      </div>
    </Form>
  )
});