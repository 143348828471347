import React, { memo } from 'react';
import Button from '../button';
import styles from './modalDescription.module.scss';
import classnames from 'classnames';
import { Modal } from '../modal';
import { Close } from "../svgIcons";

interface ModalDescriptionProps {
  active: boolean,
  setActive: () => void,
  children?: React.ReactNode | null,
  className: string,
  isMobile: boolean,
}

export const ModalDescription: React.FC<ModalDescriptionProps> = memo(({
  active,
  setActive,
  children,
  className,
  isMobile,
}) => {

  return (
    <Modal className={active ? classnames(styles.wrapper, styles.wrapper__active, className) : classnames(styles.wrapper, className)}>
      <div className={
        isMobile
        ? classnames(styles.body, styles.mobileBody)
        : styles.body
        }>
        <div className={styles.closeButtonContainer}>
          <Button
            className={styles.closeButton}
            onClick={setActive}
          >
          <Close />
          </Button>
        </div>
        {children}
      </div>
    </Modal>
  )
});