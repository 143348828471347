import React, { useEffect, useState } from 'react';
import Button from '../button';
import styles from './questionsType.module.scss';
import cloneDeep from 'lodash.clonedeep';
import classnames from 'classnames';
import { SelectProps } from '../../constants/types';
import { ConfirmButton } from './confirmButtons';
import { between, closeQuestion, dispatchImageChange } from '../../helpers/util';
import firebaseService from '../../firebase/firebase';

let timer;

export const SelectAll: React.FC<SelectProps> = ({
  question,
  setAnswer,
  answer,
  setQuestionComplete,
  currentQuestion,
  isMobile,
  mobileClass,
}) => {
  const [currentButtons, setCurrentButtons] = useState({});
  const [isComplete, setIsComplete] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isOpacity, setIsOpacity] = useState(false);
  const { Confirm } = question;

  const setAttribute = (value: string) => {
    if (answer) {
      const newAttribute = {...answer.data.attributes};
      newAttribute[value] = !newAttribute[value];
      const newAnswer = cloneDeep(answer);
      newAnswer.data.attributes = newAttribute;
      setAnswer(newAnswer);
    }
    
  };
  const handleConfirm = () => {
    if (!isConfirm && Confirm) {
      // console.log("CONFIRMED");
      setIsConfirm(true);
      dispatchImageChange(currentQuestion ? currentQuestion : 0, 2);

    } else {
      setIsComplete(true);
      closeQuestion(timer, setQuestionComplete);
    }
  };

  const handleSkip = () => {
    firebaseService.handleSkipEvent({"question": currentQuestion});
    setIsComplete(true);
    closeQuestion(timer, setQuestionComplete);
  };

  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    timer = setTimeout(() => setIsOpacity(true), 100);
  }, []);

  useEffect(() => {
    if(!answer) {
      const newButtons = {};
      question.data.attributes.sort(() => between(-1, 1)).map((atr) => newButtons[atr] = false);
      setCurrentButtons(newButtons);
      setAnswer({
        id: question.id,
        data: {
          attributes: newButtons,
          comment: '',
        },
      });
    } else if (answer) {
      setCurrentButtons(answer.data.attributes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, answer]);

  return (
    <div className={isComplete ? classnames(styles.typeQuestion, styles.typeConfirm, mobileClass(styles)) : classnames(styles.typeQuestion, isOpacity ? styles.typeAnimation : '', mobileClass(styles))}>
      {
        !isConfirm
        ? (
          question && (
            <>
            <div className={styles.question_title}>
              {
              question.data.question.map((q, index) => (
                <div key={index} className={styles.inner_title}>
                  {q}
                </div>
              ))
              }
            </div>
            <div className={styles.selectButtons}>
              {
                Object.entries(currentButtons).map((button, index) => (
                  <Button
                    key={index}
                    className={button[1] ? classnames(styles.selectButton, styles.selectButton_active) : styles.selectButton}
                    onClick={() => setAttribute(button[0])}
                    buttonStyles={styles.selectButtonWrapper}
                  >
                    {button[0]}
                  </Button>
                ))
              }
            </div>
            </>
          )
        )
        : (
          question && Confirm && (
            <Confirm
              question={question}
              answer={answer}
              setIsComplete={setIsConfirm}
              setAnswer={setAnswer}
              isMobile={isMobile}
            >
              <>
              {
                Object.entries(answer?.data.attributes).map((button, index) => {
                  if(button[1]) {
                    return (
                      <Button
                        key={index}
                        className={classnames(styles.selectButton, styles.selectButton_active)}
                        buttonStyles={styles.selectButtonWrapper}
                        onClick={() => {}}
                      >
                        {button[0]}
                      </Button>
                    )
                  }
                })
              }
              </>
            </Confirm>
          )
        )
      }
      <ConfirmButton 
        isComplete={isComplete}
        isConfirm={isConfirm}
        handleConfirm={handleConfirm}
        handleSkip={handleSkip}
        isMobile={isMobile}
        disabled={isComplete}
        nextStep={!isConfirm}
        currentQuestion={currentQuestion}
      />
    </div>
  )
};