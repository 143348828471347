import React, { memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {  pathName } from '../../constants/constants';
import { Pages } from '../../constants/types';
import { isCorrectPage } from '../../helpers/util';
import {  Footer } from '../footer';
import { Header } from '../header';
import { BackgroundParticleAnimation } from '../backgroundParticleAnimation';
import { Explorer } from '../explorer';
import Menu from '../menu';
import { ModalMenu } from '../modalMenu';
import styles from './pageLayout.module.scss';
import classnames from 'classnames';

interface PageLayoutProps {
  children: JSX.Element,
  isMobile: boolean,
  isKeynote: boolean,
  setOpenModal: () => void,
  setCurrentModalData: (value: JSX.Element) => void,
  openModal: boolean,
  setModalClassName: (value: string) => void,
  checkAudio: () => void,
  playAudio: boolean,
  breakHeight: boolean,
}

const PageLayout = ({
  children,
  isMobile,
  isKeynote,
  setOpenModal,
  setCurrentModalData,
  openModal,
  setModalClassName,
  checkAudio,
  playAudio,
  breakHeight,
}: PageLayoutProps) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(Pages.GLOBAL);
  const location = useLocation();

  const checkExplorePage = () => {
    const path = location.pathname;
    return isCorrectPage(path, pathName.explore) || isCorrectPage(path, pathName.voices);
  }
  

  useEffect(() => {
    if (location.pathname !== pathName.questionPage) {
      document.dispatchEvent(new CustomEvent('grace_request_image_removal', {
        detail: {
          remove: true,
          location: location.pathname,
        }
      }))
    }
  }, [location])

  return (
    <div className={classnames(styles.pageWrapper, isKeynote? styles.scrollable : '', isMobile ? styles.mobileWrapper : '')}>
      {
        location.pathname.includes(pathName.explore)
        ? <Explorer />
        : <BackgroundParticleAnimation isMobile={isMobile}/>
      }
      <div className={styles.page}>
        <Header
          isMobile={isMobile}
          isKeynote={isKeynote}
          onClickModalWindow={setOpenMenu}
          modalOpen={openMenu}
          playAudio={playAudio}
          setPlayAudio={checkAudio}
          page={currentMenu}
          setPage={setCurrentMenu}
          breakHeight={breakHeight}
        />
        {children}
        {
          isMobile || checkExplorePage() ? null
          : (
            <Footer 
              modalOpen={openMenu}
              playAudio={playAudio}
              setPlayAudio={checkAudio}
              isMobile={isMobile}
              breakHeight={breakHeight}
            />
          )
        }
        <ModalMenu
          active={openMenu}
        >
          <Menu
            page={currentMenu}
            setPage={setCurrentMenu}
            setOpen={setOpenMenu}
            isMobile={isMobile}
            active={openMenu}
            setCurrentModalData={setCurrentModalData}
            setOpenModal={setOpenModal}
            openModal={openModal}
            setModalClassName={setModalClassName}
          />
        </ModalMenu>
      </div>
    </div>
  )
}

export default memo(PageLayout);