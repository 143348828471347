import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { Select } from '../../input/select';
import styles from '../submitPage.module.scss';
import classnames from 'classnames';
import { useWindowSize } from '../../../hooks';
import { HIDDEN_ERROR_BREAKPOINT } from '../../../constants/constants';

interface SubmitSelectProps {
  data: string[],
  title: string,
  current: string,
  setValue: (value: string | number) => void,
  isMobile: boolean,
  placeholder: string,
  error?: string,
  touched?: true | '',
  required?: string,
  other?: string,
  comment?: string | number,
  setComment?: (value: string) => void,
  upper?: boolean,
}

export const SubmitSelect: React.FC<SubmitSelectProps> = memo(({
  data,
  title,
  current,
  setValue,
  isMobile,
  placeholder,
  error,
  touched,
  required,
  other,
  comment,
  setComment,
  upper,
}) => {
  const [errors, setErrors] = useState('');
  const {width} = useWindowSize();
  const hiddenError = width < HIDDEN_ERROR_BREAKPOINT;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (setComment) {
      const value = event.target.value;
      setComment(value);
    }
  }

  useEffect(() => {
    if (other && setComment && current !== other) {
      setComment('');
    }
  }, [current, other]);

  useEffect(() => {
    if (error && touched) {
      setErrors(error);
    } else {
      setErrors('');
    }
  }, [error, touched]);
  return (
    <div className={classnames(styles.customSelect, errors ? styles.selectError : current ? styles.selectTouched : '')}>
       <div className={styles.title}>
        <div className={required}>
        {title}
        </div>
        <div className={styles.errors}>
          {
            !hiddenError || isMobile ? errors : null
          }
        </div>
      </div>
      <Select 
        values={data}
        current={current}
        placeholder={placeholder}
        id={`select${Math.round(Math.random()* 100)}`}
        setCurrentValue={setValue}
        className={styles.select}
        isMobile={isMobile}
        upper={upper}
      />
      {
        current === other
        ? (
          <>
            <div className={styles.submitInput}>
              <div className={classnames(styles.title, styles.title_comment)}>
                <div>
                  Enter more details bellow.
                </div>
              </div>
              <div className={styles.inputWrapper}>
                <div className={styles.inputAnimation}/>
                <input
                  onChange={handleChange}
                  value={comment}
                  placeholder={placeholder}
                  className={styles.input}
                />
              </div>
            </div>
          </>
        )
        : null
      }
    </div>
  )
});