import React, { memo, useEffect, useState } from 'react';
import { DesktopQuestion } from '../desktopQuestion';
import styles from './questionWrapper.module.scss';
import { useSelector, useDispatch } from 'react-redux'; 
import { Answer } from '../../constants/types';
import { QuestionState, setAnswer, setCurrentQuestionAction, setQuestionTime } from '../../redux/reducers/questionReducer/questionReducer';
import { MobileQuestion } from '../mobileQuestion';
import classnames from 'classnames';
import { dispatchImageChange } from '../../helpers/util';
import firebaseService from '../../firebase/firebase';
import { GreetingPage } from '../greetingPage';

interface QuestionWrapperProps {
  isMobile: boolean,
  breakHeight: boolean,
}

let startTime = new Date();
let surveyTime = new Date();

export const QuestionWrapper: React.FC<QuestionWrapperProps> = memo(({
  isMobile,
  breakHeight,
}) => {
  const [isGreeting, setIsGreeting] = useState(false);
  const [questionComplete, setQuestionComplete] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState<number | null>(null);
  const answers = useSelector<QuestionState, QuestionState['answers']>(state => state.answers);
  const questions = useSelector<QuestionState, QuestionState['questions']>(state => state.questions);
  const dispatch = useDispatch();

  const setTime = () => {
    try {
      const time = (Number(new Date()) - Number(startTime)) / 1000;
      const id = currentQuestion ? `${currentQuestion - 1}_1` : `12_1`;
      startTime = new Date();
      const data = {
        id: id,
        duration: time,
      }
      firebaseService.logCustomEvent(`question_${id}_dwell_time`, data);
      dispatch(setQuestionTime(time, `q${id}`));
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    if(questionComplete) {
      setQuestionComplete(false);
      setCurrentQuestion(number => number ? number + 1 : 1);
      dispatch(setCurrentQuestionAction(currentQuestion ? `${currentQuestion}_1` : '1_1'));
    }
  }, [questionComplete]);

  useEffect(() => {
    const currentAnswers = Object.values(answers);
    if (Object.keys(questions).length > 0 && !currentQuestion && currentAnswers.length === 0) {
      setIsGreeting(true);
      setCurrentQuestion(questions[1].id);
    } else if (questions && !currentQuestion && currentAnswers.length > 0) {
      setIsGreeting(false);
      setCurrentQuestion(currentAnswers[currentAnswers.length - 1].id);
    }

  }, [answers, questions, currentQuestion]);

  useEffect(() => {
    if (currentQuestion && currentQuestion !== 1) {
      setTime();
    }
  },[currentQuestion])

  useEffect(() => {
    startTime = new Date();
    return () => {
      setTime();
      const time = (Number(new Date()) - Number(surveyTime)) / 1000;
      const data = {
        duration: time,
      }
      firebaseService.logCustomEvent(`survey_time`, data);
      dispatch(setQuestionTime(time, `survey_time`));
    };
  }, []);

  useEffect(() => {
    if (currentQuestion && !isGreeting) {
      dispatchImageChange(currentQuestion ? currentQuestion : 0, 1);
    }
  }, [currentQuestion, isGreeting])

  const changeCurrentQuestion = (value: number) => {
    if (Object.keys(answers).length + 1 >= value) {
      setCurrentQuestion(value);
      dispatch(setCurrentQuestionAction(`${value}_1`));
    }
  };
  const handleAnswer = (answer: Answer) => {
    dispatch(setAnswer(String(answer.id), answer));
  }
  return (
    <>
    {
      isGreeting
      ? (
        <GreetingPage
          isMobile={isMobile}
          active={isGreeting}
          setActive={setIsGreeting}
        />
      )
      : null
    }
    <div className={classnames(styles.questionWrapper, isMobile ? styles.mobileWrapper : '', breakHeight ? styles.smallWrapper : '')}>
      {
        isGreeting
        ? null
        : (
          <div className={isGreeting ? styles.disabledWrapper : classnames(styles.disabledWrapper, styles.visibleWrapper)}>
            {
              !isMobile
              ? (
                <DesktopQuestion
                  answers={answers}
                  questions={questions}
                  setCurrentQuestion={changeCurrentQuestion}
                  currentQuestion={currentQuestion}
                  handleAnswer={handleAnswer}
                  setQuestionComplete={setQuestionComplete}
                  isMobile={isMobile}
                  breakHeight={breakHeight}
                />
              )
              : (
                <MobileQuestion
                  answers={answers}
                  questions={questions}
                  setCurrentQuestion={changeCurrentQuestion}
                  currentQuestion={currentQuestion}
                  handleAnswer={handleAnswer}
                  setQuestionComplete={setQuestionComplete}
                  isMobile={isMobile}
                />
              )
            }
          </div>
        )
      }
    </div>
    </>
  )
});