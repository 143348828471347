import React, { memo } from 'react';
import { ThinkerData } from '../../constants/types';
import styles from './thinker.module.scss';
import classnames from 'classnames';
import { Play } from '../svgIcons';
import ReactPlayer from "react-player";

interface ThinkerDescriptionProps {
 isMobile: boolean,
 currentThinker: ThinkerData,
}

export const ThinkerDescription: React.FC<ThinkerDescriptionProps> = memo(({
  isMobile,
  currentThinker,
}) => {

  const videoClickHandler = () => {
    document.dispatchEvent(new CustomEvent("grace_request_stop_audio"));
  }
  return (
    <>
      {
        currentThinker.video == null ? null :
      <div 
        className={
          isMobile
          ? classnames(styles.thinkerVideo, styles.mobileVideo)
          : styles.thinkerVideo
        }
        >
          <ReactPlayer
            url={currentThinker.video}
            light={currentThinker.avatar}
            playIcon={<div className={styles.videoPlayButton}><Play /></div>}
            controls={true}
            onPlay={videoClickHandler}
          />
      </div>
      }
      <div className={styles.name}>
        {`${currentThinker.firstName} ${currentThinker.lastName}`}
      </div>
      <div className={styles.thinkerDescription} dangerouslySetInnerHTML={{__html: currentThinker.story}}>
      </div>
    </>
  )
});