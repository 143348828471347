import * as React from 'react';
import { MapOfAnswer } from '../redux/reducers/questionReducer/questionReducer';

type MouseEvent = React.MouseEvent<EventTarget>;
type KeyboardEvent = React.KeyboardEvent<EventTarget>;

function isMouseEvent(event: MouseEvent | KeyboardEvent): event is MouseEvent {
  return event.nativeEvent instanceof MouseEvent;
}

export const isPressEnter = (event: KeyboardEvent): boolean => event.key === 'Enter';

export const isClickOrEnter = (event: MouseEvent | KeyboardEvent): boolean => {
  if (isMouseEvent(event)) {
    return true;
  }
  return isPressEnter(event);
};

export const stopAllAudio = () => {
  const sounds = document.querySelectorAll('audio');
  for (let i = 0; i < sounds.length; i++) {
    sounds[i].pause();
  };
}

export const horizontalScroll = (current: HTMLDivElement) => {
  const scrollHorizontally = (event: WheelEvent) => {
    if(current) {
      current.scrollLeft += event.deltaY;
      event.preventDefault();
      event.stopPropagation();
    }
  }
  current.addEventListener('wheel', scrollHorizontally, false);
}

export const dispatchImageChange = (currentQuestion: number, number: number) => {
  document.dispatchEvent(new CustomEvent('grace_request_image_change', {
    detail: {
      question: `${currentQuestion}_${number}`,
    }
  }))
}

export const dispatchKeynoteParticleCanvasImageChange = (imageReference: string) => {
  document.dispatchEvent(new CustomEvent('grace_request_keynote_image_change', {
    detail: {
      imageReference: imageReference,
    }
  }))
}

export const mapToString = (map: any[]): string => {
    let selected: string = '';

    for (let i = 0; i < map.length; i++) {
        if (!selected) {
          selected += map[i];
        } else {
          selected += `, ${map[i]}`;
        }
    }
    return selected;
}

export const createFirebaseData = (answers: MapOfAnswer) => {
  const submitData: any = {
    surveyData: {
    },
    userData: {
    },
    analytics: {
      dwellTime: {
      },
      skipped: {
      },
    },
    submissionDate: '',
  }
  try {
    const surveyData = {
    }
    const correctSelect: any[] = Object.entries(answers['1'].data.attributes);
    const trueSelect: string[] = [];

    for (let i = 0; i < correctSelect.length; i++) {
      if (correctSelect[i][1]) {
        trueSelect.push(correctSelect[i][0]);
      }
    }

    surveyData['q1_1'] = mapToString(trueSelect);
    surveyData['q1_2'] = answers['1'].data.comment;
    surveyData['q2_1'] = answers['2'].data.answer;
    surveyData['q2_2'] = answers['2'].data.comment;
    surveyData['q3_1'] = answers['3'].data.answer;
    surveyData['q3_2'] = answers['3'].data.comment;
    surveyData['q4_1'] = answers['4'].data.comment ? answers['4'].data.comment : answers['4'].data.answer;
    surveyData['q5_1'] = answers['5'].data.answer;
    surveyData['q6_1'] = answers['6'].data.answer;

    surveyData['q7_1'] = mapToString(Object.values(answers['7'].data.answer));
    surveyData['q8_1'] = answers['8'].data.answer;
    surveyData['q9_1'] = answers['9'].data.answer;
    surveyData['q10_1'] = answers['10'].data.answer;
    surveyData['q11_1'] = answers['11'].data.answer[0];
    surveyData['q11_2'] = answers['11'].data.answer[1];
    surveyData['q11_3'] = answers['11'].data.answer[2];
    surveyData['q12_1'] = answers['12'].data.answer;
    surveyData['q12_2'] = answers['12'].data.comment;

    submitData.surveyData = surveyData;
  } catch (error) {
    console.log(error.message);
  }

  return submitData;
}

export const minusCheck = (event: React.ChangeEvent<HTMLInputElement>, set: (value: number | string) => void) => {
  let value: number | string = Number(event.target.value);
  if (value === 0) {
    value = '';
  } else if (String(value).includes('-') || String(value).includes('.')) {
    value = String(value).replace('-', '').replace('.', '');
  }
  set(value);
}

export const handleInputChange = (event: React.FocusEvent<HTMLInputElement>, set: (value: number) => void) => {
  const value: number = Number(event.target.value);
  if (value >= 16 && value <= 100) {
    set(value);
  } else if (value < 16) {
    set(16);
  } else if (value > 100) {
    set(100);
  }
}

export const trueOrFalse = (value: boolean, data: string[]) => {
  if(value === true) {
    return data[0];
  }  else if (value === false) {
    return data[1];
  } else {
    return null;
  }
}

export const between = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

export const closeQuestion = (timer, setQuestionComplete: (value: boolean) => void) => {
  timer = setTimeout(() => setQuestionComplete(true), 800);
}

export const isCorrectPage = (location: string, page: string) => {
  return location.includes(page);
}

export const audioTrackConstraints = {
  echoCancellation: {exact: false},
  autoGainControl: {exact: false},
  noiseSuppression: {exact: false},
  sampleRate: 16000,
  }

export const controlExploreAudio = (src?: string):Promise<any> => {
  const fthis = this;
  return new Promise(function(resolve, reject) {
    // const audioContext = new AudioContext();
    const audioElement: HTMLAudioElement | null = document.querySelector('.explore-audio');
    // audioContext.createMediaElementSource(audioElement!);

    if (audioElement) {
      if (src && audioElement.paused) {
        if (audioElement.src !== src) {
          audioElement.src = src;
        }
        // console.log('AUDIO PLAY IS REQUESTED');
        audioElement.play()
          .then(() => {
            // console.log('work');
            resolve(audioElement);
          })
          .catch(error => {
            // throw new Error(error.message);
            reject(error);
          });
      } else if (!audioElement.paused) {
        audioElement.pause();
        resolve(audioElement);
      }
    }else{
      reject(new Error('Could not create Audio context'));
    }
  });
}

export const checkMicrophone = (setIsBlocked: (value: boolean) => void) => {
  try {
    if (navigator && navigator.mediaDevices) {
      return navigator.mediaDevices.getUserMedia({ audio: audioTrackConstraints
      })
      .then(() => {
        // console.log('Permission Granted');
        setIsBlocked(false);
        return true;
      })
      .catch((error) => {
        console.log(error.message);
        setIsBlocked(true);
        return false;
      })
    } else {
      // TODO: handle this!
      setIsBlocked(true);
      // console.log('media device stuff not available in this browser');
      return false;
    }
  } catch (error) {
    console.log(error.message);
  }
}

export const goToPage = (url: string) => {
	window.open(url);
}

export const goToFullScreen = () => {
  if (
    document.fullscreenElement ||
    document[`webkitFullscreenElement`] ||
    document[`mozFullScreenElement`] ||
    document[`msFullscreenElement`]
  ) {
    if (document.exitFullscreen) {
      document.exitFullscreen()
      .catch(error => console.log(error.message));
    } else if (document['mozCancelFullScreen']) {
      document['mozCancelFullScreen']()
      .catch(error => console.log(error.message));
    } else if (document['webkitExitFullscreen']) {
      document['webkitExitFullscreen']()
      .catch(error => console.log(error.message));
    } else if (document['msExitFullscreen']) {
      document['msExitFullscreen']()
      .catch(error => console.log(error.message));
    }
  } else {
    const element = document.querySelector('#root');
    if (element) {
     if (element.requestFullscreen) {
        element.requestFullscreen()
        .catch(error => console.log(error.message));
      } else if (element['mozRequestFullScreen']) {
        element['mozRequestFullScreen']()
        .catch(error => console.log(error.message));
      } else if (element['webkitRequestFullscreen']) {
        element['webkitRequestFullscreen']()
        .catch(error => console.log(error.message));
      } else if (element['msRequestFullscreen']) {
        element['msRequestFullscreen']()
        .catch(error => console.log(error.message));
      } 
    }
  }
}