// import THREE, { Object3D } from 'three';
import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
// import Quad from 'gsap/TweenMax';
// import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
//import { BloomPass } from 'three/examples/jsm/postprocessing/BloomPass';
// import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass';
// import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass';
// import {HalftonePass } from 'three/examples/jsm/postprocessing/HalftonePass';
// import {AfterimagePass } from 'three/examples/jsm/postprocessing/AfterimagePass';
// import {DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass';
import { TweenLite } from 'gsap/TweenMax';
import InteractiveControls from './controls/InteractiveControls';
import Particles from './particles/Particles';
import BackgroundAnimation from './BackgroundAnimation';
import ParticleBackground from './particles/ParticleBackground';

export default class WebGLView {

	constructor(app, tiers) {
		this.app = app;
		this.tiers = tiers;
		this.isSuspended = false;
		this.imageData = {
			'landscape': {
				'1_1':'./images/landscape_1.jpg',
				'2_1':'./images/landscape_2.jpg',
				'3_1':'./images/landscape_3.jpg',
				'4_1':'./images/landscape_4.jpg',
				'4_2':'./images/landscape_4_2.jpg',
				'5_1':'./images/landscape_5.jpg',
				'5_2':'./images/landscape_5_2.jpg',
				'6_1':'./images/landscape_6.jpg',
				'6_2':'./images/landscape_6.jpg',
				'7_1':'./images/landscape_7_1.jpg',
				'8_1':'./images/landscape_8.jpg',
				'9_1':'./images/landscape_9.jpg',
				'10_1':'./images/landscape_10.jpg',
				'11_1':'./images/landscape_11.jpg',
				'11_2':'./images/landscape_11_2.jpg',
				'12_1':'./images/landscape_12_1.jpg',
			},
			'portrait': {
				'1_1':'./images/portrait_1.jpg',
				'2_1':'./images/portrait_2.jpg',
				'3_1':'./images/portrait_3.jpg',
				'4_1':'./images/portrait_4.jpg',
				'4_2':'./images/portrait_4_2.jpg',
				'5_1':'./images/portrait_5.jpg',
				'5_2':'./images/portrait_5_2.jpg',
				'6_1':'./images/portrait_6.jpg',
				'6_2':'./images/portrait_6.jpg',
				'7_1':'./images/portrait_7.jpg',
				'8_1':'./images/portrait_8.jpg',
				'9_1':'./images/portrait_9.jpg',
				'10_1':'./images/portrait_10.jpg',
				'11_1':'./images/portrait_11.jpg',
				'11_2':'./images/portrait_11_2.jpg',
				'12_1':'./images/portrait_12.jpg',
			}
		};
		this.detailImageData = {
			'landscape': {
				'1_1':'./images/landscape_1_D.jpg',
				'2_1':'./images/landscape_2_D.jpg',
				'3_1':'./images/landscape_3_D.jpg',
				'4_1':'./images/landscape_4_D.jpg',
				'4_2':'./images/landscape_4_2_D.jpg',
				'5_1':'./images/landscape_5_D.jpg',
				'5_2':'./images/landscape_5_2_D.jpg',
				'6_1':'./images/landscape_6_1_D.jpg',
				'6_2':'./images/landscape_6_2_D.jpg',
				'7_1':'./images/landscape_7_1_D.jpg',
				'8_1':'./images/landscape_8_D.jpg',
				'9_1':'./images/landscape_9_D.jpg',
				'10_1':'./images/landscape_10_D.jpg',
				'11_1':'./images/landscape_11_D.jpg',
				'11_2':'./images/landscape_11_2_D.jpg',
				'12_1':'./images/landscape_12_1_D.jpg',
			},
			'portrait': {
				'1_1':'./images/portrait_1_D.jpg',
				'2_1':'./images/portrait_2_D.jpg',
				'3_1':'./images/portrait_3_D.jpg',
				'4_1':'./images/portrait_4_D.jpg',
				'4_2':'./images/portrait_4_2_D.jpg',
				'5_1':'./images/portrait_5_D.jpg',
				'5_2':'./images/portrait_5_2_D.jpg',
				'6_1':'./images/portrait_6_D.jpg',
				'6_2':'./images/portrait_6_2_D.jpg',
				'7_1':'./images/portrait_7_D.jpg',
				'8_1':'./images/portrait_8_D.jpg',
				'9_1':'./images/portrait_9_D.jpg',
				'10_1':'./images/portrait_10_D.jpg',
				'11_1':'./images/portrait_11_D.jpg',
				'11_2':'./images/portrait_11_2_D.jpg',
				'12_1':'./images/portrait_12_D.jpg',
			}
		};
		this.initThree();
		this.initParticles();
		this.initControls();
	}

	initThree() {
		// scene
		this.scene                = new THREE.Scene();
		// this.scene.background = new THREE.Color('rgb(36, 42, 59)');
		// this.backScene            = new THREE.Scene();
		// this.backScene.background = new THREE.Color(0x242A3B);
		// this.efxScene = new THREE.Scene();

		// camera
		this.camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 1, 10000);
		this.camera.position.z = 300;
		this.camera.position.x = 0;

		// renderer
        let ratio = window.devicePixelRatio
        if ( ratio > 1 ) ratio = 1;

        ratio *= 0.7;

        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha:false, preserveDrawingBuffer: false, powerPreference:'high-performance' });
        this.renderer.outputEncoding = THREE.sRGBEncoding;
		this.renderer.setClearColor( 0x141221, 1 );
        this.renderer.setPixelRatio( ratio );
        this.renderer.autoClear = false; 



        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // compose: background                          ///////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    	// let bgRenderTargetParams = {
      	// 	minFilter: THREE.LinearFilter,
      	// 	magFilter: THREE.LinearFilter,
      	// 	format: THREE.RGBAFormat,
    	// };

      	// let bgRenderTarget = new THREE.WebGLRenderTarget(
        // 	window.innerWidth,
        // 	window.innerHeight,
        // 	bgRenderTargetParams
      	// );

		// this.backgroundComposer = new EffectComposer(this.renderer,bgRenderTarget);
        // this.backgroundComposer.renderTarget2.texture.format = this.backgroundComposer.renderTarget1.texture.format = THREE.RGBAFormat;		

    	// const bgRenderPass = new RenderPass(this.backScene, this.camera);
    	// this.backgroundComposer.addPass(bgRenderPass);

	    // this.simpleCopyBgShader = {

	    //   uniforms: {
	    //     tDiffuse: { value: null },
	    //   },

	    //   vertexShader: [
	    //     'varying vec2 vUv;',

	    //     'void main() {',
	    //     'vUv = uv;',
	    //     'gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);',
	    //     '}',
	    //   ].join('\n'),

	    //   fragmentShader: [
	    //     'uniform sampler2D tDiffuse;',
	    //     'varying vec2 vUv;',

	    //     'void main() {',

	    //     'vec4 color = texture2D( tDiffuse, vUv );',

	    //     'gl_FragColor = color;',

	    //     '}',
	    //   ].join('\n'),
	    // };

	    // this.simpleCopyBgPass = new ShaderPass(this.simpleCopyBgShader);
	    // this.backgroundComposer.addPass(this.simpleCopyBgPass);




        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // compose: background.particles                ///////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

		// this.composer = new EffectComposer(this.renderer);

    	// let particleRenderTargetParams = {
      	// 	minFilter: THREE.LinearFilter,
      	// 	magFilter: THREE.LinearFilter,
      	// 	format: THREE.RGBAFormat,
    	// };

      	// let particleRenderTarget = new THREE.WebGLRenderTarget(
        // 	window.innerWidth * 0.9,
        // 	window.innerHeight * 0.9,
        // 	particleRenderTargetParams
      	// );

		// this.composer = new EffectComposer(this.renderer,particleRenderTarget);
        // this.composer.renderTarget2.texture.format = this.composer.renderTarget1.texture.format = THREE.RGBAFormat;		

    	// const renderPass = new RenderPass(this.efxScene, this.camera);
    	// this.composer.addPass(renderPass);

	    // this.tiltShiftShader = {

	    //     uniforms: {
	    //     tDiffuse: { type: 't', value: null },
	    //     tDiffuse1: { type: 't', value: null },
	    //     resolution: { type: 'v2', value: new THREE.Vector2(window.innerWidth * ratio, window.innerHeight * ratio) },
	    //     },
	    //     vertexShader: [

	    //       'varying vec2 vUv;',

	    //       'void main() {',
	    //           'vUv = uv;',
	    //           'gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);',
	    //       '}'

	    //     ].join( '\n' ),

	    //     fragmentShader: [

	    //         'uniform sampler2D tDiffuse;',
	    //         'uniform sampler2D tDiffuse1;',
	    //         'uniform vec2 resolution;',
	    //         'varying vec2 vUv;',

	    //         'float normpdf(in float x, in float sigma)',
	    //         '{',
	    //             'return 0.39894*exp(-0.5*x*x/(sigma*sigma))/sigma;',
	    //         '}',


		//         'mat4 brightnessMatrix( float brightness ) {',
		//         'return mat4( 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, brightness, brightness, brightness, 1 );',
		//         '}',

		//         'mat4 contrastMatrix( float contrast ) {',
		//         'float t = ( 1.0 - contrast ) / 2.0;',

		//         'return mat4( contrast, 0, 0, 0, 0, contrast, 0, 0, 0, 0, contrast, 0, t, t, t, 1 );',

		//         '}',

		//         'mat4 saturationMatrix( float saturation ) {',
		//         'vec3 luminance = vec3( 0.3086, 0.6094, 0.0820 );',

		//         'float oneMinusSat = 1.0 - saturation;',

		//         'vec3 red = vec3( luminance.x * oneMinusSat );',
		//         'red+= vec3( saturation, 0, 0 );',

		//         'vec3 green = vec3( luminance.y * oneMinusSat );',
		//         'green += vec3( 0, saturation, 0 );',

		//         'vec3 blue = vec3( luminance.z * oneMinusSat );',
		//         'blue += vec3( 0, 0, saturation );',

		//         'return mat4( red,     0, green,   0, blue,    0, 0, 0, 0, 1 );',
		//         '}',


		//         'vec3 uncharted2Tonemap(vec3 x) {',
		//         'float A = 0.15;',
		//         'float B = 0.50;',
		//         'float C = 0.10;',
		//         'float D = 0.20;',
		//         'float E = 0.02;',
		//         'float F = 0.30;',
		//         'float W = 11.2;',
		//         'return ((x * (A * x + C * B) + D * E) / (x * (A * x + B) + D * F)) - E / F;',
		//         '}',

		//         'vec3 uncharted2(vec3 color) {',
		//         'const float W = 11.2;',
		//         'float exposureBias = 9.5;',
		//         'vec3 curr = uncharted2Tonemap(exposureBias * color);',
		//         'vec3 whiteScale = 1.0 / uncharted2Tonemap(vec3(W));',
		//         'return curr * whiteScale;',
		//         '}',

		//         'float uncharted2Tonemap(float x) {',
		//         'float A = 0.15;',
		//         'float B = 0.50;',
		//         'float C = 0.10;',
		//         'float D = 0.20;',
		//         'float E = 0.02;',
		//         'float F = 0.30;',
		//         'float W = 11.2;',
		//         'return ((x * (A * x + C * B) + D * E) / (x * (A * x + B) + D * F)) - E / F;',
		//         '}',

		//         'float uncharted2(float color) {',
		//         'const float W = 11.2;',
		//         'const float exposureBias = 2.0;',
		//         'float curr = uncharted2Tonemap(exposureBias * color);',
		//         'float whiteScale = 1.0 / uncharted2Tonemap(W);',
		//         'return curr * whiteScale;',
		//         '}',


	    //         'void main() {',

	    //             'vec4 sum = vec4(0);',
	    //             // How much the shift is visible.
	    //             'const float shiftPower = 1.3;',
	                 
	    //             'vec4 color = vec4(texture2D(tDiffuse, vUv).rgb, 1.0);',
	    //             'vec4 bg = texture2D(tDiffuse1, vUv);',    
	    //             //declare stuff
	    //             'const int mSize = 20;',
	    //             'const int kSize = (mSize-1)/2;',
	    //             'float kernel[mSize];',
	    //             'vec3 final_colour = vec3(0.0);',
	    //             //create the 1-D kernel
	    //             'float sigma = 7.0;',
	    //             'float Z = 0.0;',

	    //             'for (int j = 0; j <= kSize; ++j)',
	    //                 'kernel[kSize+j] = kernel[kSize-j] = normpdf(float(j), sigma);',

	    //             //get the normalization factor (as the gaussian has been clamped)
	    //             'for (int j = 0; j < mSize; ++j)',
	    //                 'Z += kernel[j];',
	                
	    //             //read out the texels
	    //             'for (int i=-kSize; i <= kSize; ++i)',
	    //             '{',
	    //                 'for (int j=-kSize; j <= kSize; ++j)',
	    //                     'final_colour += kernel[kSize+j]*kernel[kSize+i]*texture2D(tDiffuse, (gl_FragCoord.xy+vec2(float(i),float(j))) / resolution.xy).rgb;',
	    //             '}',

	    //             'float val = clamp(shiftPower * abs(resolution.y/2.0 - gl_FragCoord.y) / (resolution.y/2.0), 0.0, 1.0);',
	    //             'vec4 preColour = vec4(final_colour/(Z*Z), 1.0) * val + color * (1.0 - val);',
	    //             //'preColour *= contrastMatrix( 10. );',

	    //             'gl_FragColor = vec4(bg.rgb + preColour.rgb, 1.0);',


	    //       '}'

	    //     ].join( '\n' ) 
	    // };

	   	// this.tiltShiftShader.uniforms['tDiffuse1'].value = this.backgroundComposer.renderTarget2;
	    // this.tiltShiftPass = new ShaderPass( this.tiltShiftShader );

	    // this.composer.addPass(this.tiltShiftPass);






        // clock
		this.clock = new THREE.Clock(true);

		// background animation
		// console.log(this.tiers);
		if(this.tiers.tier >= 2 || this.tiers.isMobile == false){
			this.backgroundAnimation = new BackgroundAnimation(this.scene);
			this.particleBackground = new ParticleBackground(this.scene);
		}
	}

	initControls() {
		this.interactive = new InteractiveControls(this.camera, this.renderer.domElement);
	}

	initParticles() {
		if(this.tiers.isMobile == false){
			this.particles = new Particles(this, 16, 30, 0.4);
			this.scene.add(this.particles.container);
		}
		this.midParticles = new Particles(this, 8, 10, 0.6);
		this.scene.add(this.midParticles.container);
		this.detailParticles = new Particles(this, 1, 2, 1.0);
		this.scene.add(this.detailParticles.container);
	}

	updateBackground(){

		// Update the Background Animation
		this.backgroundAnimation.update();
		this.particleBackground.update();
    };
	// ---------------------------------------------------------------------------------------------
	// PUBLIC
	// ---------------------------------------------------------------------------------------------

	update() {
		if (!this.renderer || this.isSuspended) return;
		const delta = this.clock.getDelta();
		if (this.particles) this.particles.update(delta);
		if (this.midParticles) this.midParticles.update(delta);
		if (this.detailParticles) this.detailParticles.update(delta);

		if(this.tiers.tier >= 2 || this.tiers.isMobile == false){
			this.updateBackground();
		}
	}

	draw() {
		if (!this.renderer || this.isSuspended) return;
		this.renderer.clear();
		// this.backgroundComposer.render();
		// this.composer.render();
		this.renderer.clearDepth();
		this.renderer.render(this.scene, this.camera);
	}

	goto(questionId) {
		if (!this.renderer || this.isSuspended) return;
		// grab the image URI using the device orientation and Question id as Keys
		let isLandscape = window.innerWidth > window.innerHeight;
		let imagePath = this.imageData[isLandscape ? 'landscape' : 'portrait'][questionId];
		let detailImagePath = this.detailImageData[isLandscape ? 'landscape' : 'portrait'][questionId];
		if(imagePath == null || detailImagePath == null) return;
		// init next
		if (this.currImage == null || this.currDetailImage == null) {
			if(this.particles) this.particles.init(imagePath);
			if(this.midParticles) this.midParticles.init(imagePath);
			if(this.detailParticles) this.detailParticles.init(detailImagePath);
		}
		// hide curr then init next
		else {
			if(this.particles) {
				this.particles.hide(true).then(() => {
					this.particles.init(imagePath);
				});
			}
			if(this.midParticles) {
				this.midParticles.hide(true).then(() => {
					this.midParticles.init(imagePath);
				});
			}
			if(this.detailParticles) {
				this.detailParticles.hide(true).then(() => {
					this.detailParticles.init(detailImagePath);
				});
			}
		}
		this.currImage = imagePath;
		this.currDetailImage = detailImagePath;
	}

	hideCurrentImage(){
		if (!this.renderer || this.isSuspended) return;
		if(this.currImage != null || this.currDetailImage != null){
			if(this.particles) {
				this.particles.hide(true).then(() => {
					this.currImage = null;
				});
			}
			if(this.midParticles) {
				this.midParticles.hide(true).then(() => {
					this.currImage = null;
				});
			}
			if(this.detailParticles) {
				this.detailParticles.hide(true).then(() => {
					this.currDetailImage = null;
				});
			}
		}
	}

	// ---------------------------------------------------------------------------------------------
	// EVENT HANDLERS
	// ---------------------------------------------------------------------------------------------

	resize() {
		if (!this.renderer || this.isSuspended) return;
		this.camera.aspect = window.innerWidth / window.innerHeight;
		this.camera.updateProjectionMatrix();
		this.fovHeight = 2 * Math.tan((this.camera.fov * Math.PI) / 180 / 2) * this.camera.position.z;
		this.renderer.setSize(window.innerWidth, window.innerHeight);

		if (this.interactive) this.interactive.resize();
		if (this.particles) this.particles.resize();
		if (this.midParticles) this.midParticles.resize();
		if (this.detailParticles) this.detailParticles.resize();
	}
}
