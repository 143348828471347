import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styles from './input.module.scss';
import classnames from 'classnames';
import { Vector } from '../svgIcons';

interface SelectProps {
  current: string,
  values: string[],
  setCurrentValue: (value: string | number) => void,
  id: string,
  className?: string,
  isMobile: boolean,
  placeholder: string,
  upper?: boolean,
}

export const Select: React.FC<SelectProps> = ({
  current,
  values,
  setCurrentValue,
  id,
  className,
  isMobile,
  placeholder,
  upper,
}) => {
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const selectRef = useRef<HTMLSelectElement | null>(null);

  const setNewValue = (value) => {
    setCurrentValue(value);
    setShow(false);
  }

  const clickListener = (element) => {
    if (
      element.target.closest(`#${id}`) !== ref.current
    ) {
      setShow(false);
    }
  }

  const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setCurrentValue(event.target.value);
  }

  const checkHeight = () => {
    if (ref.current) {
      return window.innerHeight - ref.current.getBoundingClientRect().top > 300 && !upper;
    } else {
      return true;
    }
  }

  useEffect(() => {
    document.addEventListener('click', clickListener);

    return () => {
      document.removeEventListener('click', clickListener);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, current]);

  return (
  <div id={id} ref={ref} className={classnames(styles.selectContainer, show ? styles.selectActiveWrapper : '')}>
        <div className={styles.selectAnimation}/>
        <div className={
          show
          ? classnames(styles.select, className, isMobile ? styles.custom_disabled : '')
          : classnames(styles.select, styles.select_up, className, isMobile ? styles.custom_disabled : '')
          } 
          onClick={() => setShow(s => !s)}
        >
          <div className={classnames(styles.currentValue, current === '' ? styles.selectPlaceholder : '')}>
            {current || placeholder}
          </div>
          <Vector />
        </div>
        <div className={show ? checkHeight() ? styles.dropdown : classnames(styles.dropdown, styles.dropdown_up) : classnames(styles.dropdown, styles.dropdown_visible)}>
          <div className={styles.dropdown_entry_wrapper}>
            <div className={styles.dropdown_hr}/>
            <div className={styles.optionsWrapper}>
              {
                values.map((v, index) => (
                  <div key={v} onClick={() => setNewValue(v)} className={styles.option}>
                    {v}
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <select
          ref={selectRef}
          onChange={handleOnChange}
          className={
            show
            ? classnames(styles.select, className, !isMobile ? styles.select_disabled : '')
            : classnames(styles.select, styles.select_up, className, !isMobile ? styles.select_disabled : '')}
          value={current || placeholder}
          autoComplete="on"
          >
            {
              isMobile
              ? (
                <>
                <option value={placeholder} disabled>{placeholder}</option>
                {
                  values.map((v, index) => (
                    <option key={v} value={v}>{v}</option>
                  ))
                }
                </>
              )
              : null
            }
        </select>
  </div>
  )
}