import React, { memo, ReactNode, useEffect, useState } from 'react';

interface KeynoteTransformAnimationProps {
    isMobile: boolean,
    transformFactor: number,
    targetAngle: number,
    width: number | string,
    height: number | string,
    srcImage: string,
    opacity?: number,
}

const KeynoteTransformAnimation = ({
    isMobile,
    transformFactor,
    targetAngle,
    width,
    height,
    srcImage,
    opacity = 1,
}: KeynoteTransformAnimationProps) => {

    useEffect(() => {
        // console.log(transformFactor);
    }, [transformFactor, isMobile]);

    return (
        <div style={ isMobile ? {} : {
            perspective: `50vw`
        }}>
            <div style={{
            transform: isMobile ? '' : `rotate3d(0,1,0,${targetAngle*transformFactor}deg)`,
            width: width,
            height: height,
            opacity: opacity,
            }}>
                <img 
                    src={srcImage}
                    alt="Grace's Call-to-action"
                    width={'100%'} 
                    height={'100%'}
                    style={{
                        objectFit: 'cover',
                    }}
                    />
            </div>
        </div>
    );
}
export default memo(KeynoteTransformAnimation);