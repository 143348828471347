import React, { memo } from 'react';
import styles from './modal.module.scss';
import classnames from 'classnames';

interface ModalProps {
  className?: string,
  children:  React.ReactNode,
}

export const Modal: React.FC<ModalProps> = memo(({
  className,
  children,
}) => {
  return (
    <div className={classnames(styles.modal, className)}>
      {children}
    </div>
  )
});