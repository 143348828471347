import React, { memo } from 'react';
import styles from './webGL.module.scss';
import Lottie from 'react-lottie';
import loadingAnim from '../../constants/lottie/clickAndDrag.json';
import classnames from 'classnames';
import Button from '../button';

interface LoadedPageProps {
  isMobile: boolean,
  isLoaded: boolean,
  isUseLaunchButton: boolean,
  isReadyForLaunch: boolean,
  launchButtonHandler?: () => void,
  isShowLoadScreen: boolean,
}

export const LoadedPage: React.FC<LoadedPageProps> = memo(({
  isMobile,
  isLoaded,
  isReadyForLaunch,
  isUseLaunchButton,
  launchButtonHandler,
  isShowLoadScreen,
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: loadingAnim,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className={classnames(styles.loaded, isReadyForLaunch ? styles.disabled : '')}>
      <img 
        className={styles.loaded_background}
       src='/images/loaded.png'
       alt='loaded'
      />
      <div className={styles.loaded_circle}>
        <Lottie
          options={defaultOptions}
          width={300}
          height={isUseLaunchButton ? 200 : 300}
        />
        {
          isUseLaunchButton && isLoaded ? (
            <Button
              className={styles.buttonLoadingExplore}
              buttonStyles={styles.buttonNextWrapper}
              onClick={launchButtonHandler}
              disabled={isReadyForLaunch}
            >
              {`EXPLORE`}
            </Button>
          )
          : null
        }
      </div>
    </div>
  )
});