import React, { memo, useEffect, useState } from 'react';
import { UserData } from '../../../constants/types';
import { Navigator, ShareButton } from '../../button';
import { Back } from '../../svgIcons';
import styles from '../voicesPage.module.scss';
import { SocialButtons } from '../../footer/socialButtons';

interface MessageTypeProps {
  isMobile: boolean,
  isSocial: boolean,
  setIsSocial: () => void,
  navigatorClick: (value: string) => void,
  user: UserData | null,
  docId: string | null,
  message: string | null,
}

export const MessageType: React.FC<MessageTypeProps> = memo(({
  isMobile,
  isSocial,
  setIsSocial,
  navigatorClick,
  user,
  docId,
  message,
}) => {

  return (
    <>
    <div className={styles.voices_messageContainer}>
      <div className={styles.voices_userInfo}>
        <div className={styles.voices_name}>
          {`${user?.firstName} ${user?.lastName}`}
        </div>
        <div className={styles.voices_country}>
          {user?.location}
        </div>
      </div>
      <div className={styles.shareMessageContainer}>
      {isMobile
         ? null : (
        <div className={styles.share}>
          <ShareButton 
            icon={<Back />}
            title='Share this voice'
            onClick={setIsSocial}
          />
        </div>
         )}
        {
          isSocial
          ? (
            <div className={styles.voices_social}>
              <SocialButtons
                isMobile={isMobile}
                isExploreUrl={false}
                docId={docId}
              />
            </div>
          )
          : null
        }
      </div>
      <div className={styles.voices_message}>
        {message}
      </div>
    </div>
      <div className={styles.voices_navigatorWrapper}>
        <Navigator
          isMobile={isMobile}
          leftClick={() => navigatorClick('grace_request_show_previous_explorer_detail')}
          rightClick={() => navigatorClick('grace_request_show_next_explorer_detail')}
          shuffleClick={() => navigatorClick('grace_request_show_random_explorer_detail')}
        />
      </div>
    </>
  )
});