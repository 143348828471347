import React, { memo, useEffect, useRef } from 'react';
import styles from './desktopQuestion.module.scss';
import { Answer } from '../../constants/types';
import { Crumbs } from '../crumbs';
import { MapOfAnswer, MapOfQuestion } from '../../redux/reducers/questionReducer/questionReducer';
import { QuestionComponent } from '../question';
import classnames from 'classnames';

interface DesktopQuestionProps {
  questions: MapOfQuestion,
  answers: MapOfAnswer,
  setCurrentQuestion: (value: number) => void,
  currentQuestion: number | null,
  handleAnswer: (value: Answer) => void,
  setQuestionComplete: (value: boolean) => void,
  isMobile: boolean,
  breakHeight: boolean
};

export const DesktopQuestion: React.FC<DesktopQuestionProps> = memo(({
  questions,
  answers,
  setCurrentQuestion,
  currentQuestion,
  handleAnswer,
  setQuestionComplete,
  isMobile,
  breakHeight,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const eventScroll = () => {
      if (ref.current) {
        ref.current.scrollTop = 0;
      }
    }
      document.addEventListener('scroll_to_zero', eventScroll);

      return () => document.removeEventListener('scroll_to_zero', eventScroll);
  }, []);

  useEffect(() => {
    document.dispatchEvent(new CustomEvent('scroll_to_zero', {
      detail: {
        scroll: true,
      }
    }))
  }, [currentQuestion]);
  return (
    <div className={classnames(styles.desktopQuestion, breakHeight ? styles.desktopMobile : '')}>
      <div className={styles.question}>
        <Crumbs
          questions={questions}
          current={currentQuestion}
          answers={answers}
          setCurrent={setCurrentQuestion}
          isMobile={isMobile}
        />
        <div ref={ref} className={styles.overflowWrapper}>
          {
          questions && currentQuestion &&
          <QuestionComponent
            question={questions[currentQuestion]}
            length={Object.keys(questions).length}
            current={currentQuestion}
            answer={answers && currentQuestion ? answers[currentQuestion] : null}
            setAnswer={handleAnswer}
            setQuestionComplete={setQuestionComplete}
            currentQuestion={currentQuestion}
            isMobile={isMobile}
          />
          }
        </div>
        
      </div>
      <div className={styles.animationImage}>

      </div>
    </div>
  )
});