import React from 'react';

export interface ThinkerData {
  id: number | string,
  firstName: string,
  lastName: string,
  cta: string,
  deeplink: string | null,
  avatar: string,
  voice: string | null,
  video?: string | null,
  story: string,
};

export interface Question {
  id: number,
  Component?: React.FC<any>,
  Confirm: React.FC<any> | null,
  data?: any,
}
export interface Answer {
  id: number,
  data?: any,
}

export interface SelectProps {
  question: Question,
  setAnswer: (value: Answer) => void,
  answer: Answer | undefined,
  setQuestionComplete: (value: boolean) => void,
  currentQuestion: number | null,
  isMobile: boolean,
  setModal?: (value: JSX.Element | null) => void,
  mobileClass: (value: any) => string,
}

export enum Pages {
  GLOBAL,
  PROJECT,
  FEATURED,
  EXPLORE,
}

export interface UserData {
  firstName?: string,
  lastName?: string,
  fullName?: string,
  email?: string,
  linkedIn?: string,
  contactConsent?: boolean,
  age?: string,
  pronouns?: string,
  location?: string,
  ethnic?: string,
  gender?: string,
  genderComment?: string,
  company?: string,
  discipline?: string,
  emailConsent?: boolean,
  diversity?: string,
}

export interface KeynoteData {
  name?: string,
  email?: string,
  consent?: boolean,
}

export interface ExploreDataType {
  id: string,
  type: number,
  userData: UserData | null,
  audioUrl: string | null,
  message: string | null,
  deeplink: boolean,
  imageURL: string | null,
}

export interface SubType {
  id: string | number,
  startTime: string,
  endTime: string,
  text: string,
}