import React, { memo } from "react";
import styles from "./about.module.scss";
import classnames from "classnames";
import ReactPlayer from "react-player";
import { Play } from "../svgIcons";

interface AboutProps {
  isMobile: boolean,
}

export const About: React.FC<AboutProps> = memo(({
  isMobile,
}) => {
  const videoClickHandler = () => {
    document.dispatchEvent(new CustomEvent("grace_request_stop_audio"));
  }
  return (
    <div className={styles.aboutWrapper}>
      <div className={styles.about}>
        <div className={styles.about_title}>About Extraordinary Grace</div>
        <img className={styles.horizontal_line} src='./images/horizontalLine.png' alt='horizontal line' />
        <div
          className={
            isMobile
              ? classnames(styles.thinkerVideo, styles.mobileVideo)
              : styles.thinkerVideo
          }
        >
          <ReactPlayer
            url="https://www.youtube.com/watch?v=kjRhYSDT8VA"
            light="./images/about_video_placeholder.png"
            playIcon={<div className={styles.videoPlayButton}><Play /></div>}
            controls={true}
            onPlay={videoClickHandler}
          />
        </div>
        <div className={styles.description}>
          <p>As scientists, we are the very experts in discovering answers, but by missing out on invaluable, diverse perspectives, we’re missing out on crucial thinking that has the potential to unlock a new era of scientific greatness.</p>
          <p>Introducing Grace, a young girl with an extraordinary future. Grace represents the next generation of scientists, but also, the underrepresented. She has the potential to change the face of analytical science; hers is an incredible, but fragile journey that calls upon your unique expertise for mentorship.</p>
          <p>Grace will face many barriers as she grows up, but is unprepared for the challenges along the way, finding herself frequently asking a life-defining question:</p>
          <p>“Should I continue with a career in science?”</p>
          <p>By sharing your unique perspective and experiences with Grace, you’ll be joining an unprecedented conversation in science, where together, we can form an extensive, truly accurate picture of our industry.</p>
          <p>Now, wouldn’t that <strong>be extraordinary</strong>.</p>
          <br/>
          <p>Extraordinary Grace is brought to you by <img src='./images/SCIEX LOGO.png' alt='Sciex Logo' /></p>
          <p><small>All data collected in the survey will be processed and stored securely and anonymously, and used only for the purpose of this survey and the presentation of survey results. Gender and age information will only be used in aggregate within the survey results to explore the variety of advice given to Grace between age groups and genders. Please refer to our privacy policy.</small></p>
          <p><small>Voice/ text responses to question 12 will be published to the “Explore” section of the site along with name, location and scientific discipline.</small></p>
          <p><small>GEN-MKT-18-13291 Trademarks and/or registered trademarks mentioned herein are the property of AB Sciex Pte. Ltd. or their respective owners in the United States and/or certain other countries. &#169; 2021 DH Tech. Dev. Pte. Ltd.</small></p>
        </div>
      </div>
    </div>
    
  );
});
