import React, { memo, useState, useEffect } from 'react';
import styles from './header.module.scss';
import Button from '../button';
import classnames from 'classnames';
import { Audio } from '../audio';
import { Pages } from '../../constants/types';
import { useHistory, useLocation } from "react-router-dom";
import { Logo, LogoMobile } from '../svgIcons';
import { pathName } from '../../constants/constants';
import { useDispatch } from 'react-redux';
import { removeAnswers } from '../../redux/reducers/questionReducer/questionReducer';
import { isCorrectPage } from '../../helpers/util';
import firebaseService from '../../firebase/firebase';
import Lottie from 'react-lottie';
import animationData from '../../constants/lottie/hamburger.json';

interface HeaderProps {
  onClickModalWindow: (value: any) => void,
  isMobile: boolean,
  isKeynote: boolean,
  modalOpen: boolean,
  playAudio: boolean,
  setPlayAudio: () => void,
  page: Pages,
  setPage: (value: Pages) => void,
  breakHeight: boolean,
}

export const Header: React.FC<HeaderProps> = memo(({
  onClickModalWindow,
  isMobile,
  isKeynote,
  modalOpen,
  playAudio,
  setPlayAudio,
  page,
  setPage,
  breakHeight,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const buttonStyle = !modalOpen ? styles.close : styles.open;
  const location = useLocation();

  const [surveyTotalCount, setSurveyTotalCount] = useState(0);

  const handleLogoClick = () => {
    if (isCorrectPage(location.pathname, pathName.questionPage)) {
       const answer = window.confirm('This will reset the Survey data. Are you sure?');
      if (answer) {
        dispatch(removeAnswers());
        setPage(Pages.GLOBAL);
        onClickModalWindow(false);
        history.push('/');
      }
    } else {
      history.push('/');
    }
  }

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const checkExplorePage = () => {
    const path = location.pathname;
    return isCorrectPage(path, pathName.explore) || isCorrectPage(path, pathName.voices) || isCorrectPage(path, pathName.thinkers) || isCorrectPage(path, pathName.about) ;
  }

  useEffect(() => {
    firebaseService.getTotalSurveyDocuments().then(result => {
      setSurveyTotalCount(result);
    }).catch(error => {
      setSurveyTotalCount(0);
    })
  });

  return (
    <div className={classnames(styles.header, breakHeight ? styles.smallHeader : '', isKeynote ? styles.keynote : '')}>
      {
        !isMobile? (
          <Button
            onClick={handleLogoClick}
          >
            <Logo />
          </Button>
        )
        : (
          <Button
            onClick={handleLogoClick}
          >
            <LogoMobile />
          </Button>
        )
      }
      <div className={styles.headerGroup}>
        {
          !checkExplorePage()
          ? isMobile ? null
          : (
            surveyTotalCount >= 200 ?
            <div className={styles.membersWrapper}>
              <div>
                total number of mentors:
              </div>
              <div className={styles.numbers}>
                {surveyTotalCount}
              </div>
            </div>
            : null
          )
          : !isMobile
          ? (
            <Button
              className={styles.buttonNext}
              buttonStyles={styles.buttonNextWrapper}
              onClick={() => history.push(pathName.questionPage)}
            >
              {`TAKE GRACE'S SURVEY`}
            </Button>
          )
          : null
        }
        {!isMobile ? null
          : (
            <Audio
              className={classnames(styles.menuButton, buttonStyle, styles.volume)}
              playAudio={playAudio}
              setPlayAudio={setPlayAudio}
            />
          )
        }
        <Button 
          onClick={page === Pages.GLOBAL ? () => onClickModalWindow((open: boolean) => !open) : () => setPage(Pages.GLOBAL)}
          className={classnames(styles.menuButton, buttonStyle)}
        >
          <Lottie
            options={defaultOptions}
            width={80}
            height={30}
            direction={modalOpen ? 1 : -1}
          />
        </Button>
      </div>
      </div>
  )
});