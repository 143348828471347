import React, { ChangeEvent } from 'react';
import { TextArea } from '../../input';
import styles from '../questionsType.module.scss';

interface CommentProps {
  label?: string | {
    [key: string]: string,
  },
  setComment: (value: string) => void,
  comment: string,
  answer: string,
  className?: string,
};

export const Comment: React.FC<CommentProps> = ({
  label,
  setComment,
  comment,
  answer,
  className,
}) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setComment(value);
  }

  const isObject = () => {
    return typeof label === 'object';
  }
  return (
    <div className={styles.comment}>
      {
        label? (
          <div className={styles.label}>
            {
              isObject() ? String(label[answer]) : label
            }
          </div>
        )
        : null
      }
      <TextArea
        value={comment}
        onChange={handleChange}
        wrapperClass={className}
        className={styles.textarea}
        placeholder='Type your comment here...'
      />
    </div>
  )
};