import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PageLayout from './components/pageLayout';
import { Route, Switch, useParams, useLocation } from 'react-router-dom';
import StartPage from './components/startPage';
import { HEIGHT_LAYOUT_BREAKPOINT, MOBILE_LAYOUT_BREAKPOINT, pathName, testData, testQuestions } from './constants/constants';
import { QuestionWrapper } from './components/questionWrapper/intex';
import { WebGl } from './components/WebGL';
import { Thinkers } from './components/thinkers';
import { About } from './components/about';
import { SubmitPage } from './components/submitPage';
import { useWindowSize } from './hooks';
import './styles/_global.scss';
import { ModalDescription } from './components/modalDescription';
import { SuccessPage } from './components/successPage';
import { useDispatch } from 'react-redux';
import { setQuestions } from './redux/reducers/questionReducer/questionReducer';
// import firebaseService from './firebase/firebase';
import { ExploreDataType } from './constants/types';
import { stopAllAudio } from './helpers/util';
import KeynotePage from './components/keynotePage/KeynotePage';
import KeynoteThankYou from './components/keynoteThankYou/KeynoteThankYou';
import useIsMobile from './hooks/useIsMobile';
import KeynoteVideoPage from './components/keynoteVideoPage/keynoteVideoPage';
import TeaWithRichVideoPage from './components/teaWithRichVideoPage/teaWithRichVideoPage';

interface ParamTypes {
  uniqueID: string | undefined,
}

function App() {

  const {width, height} = useWindowSize();
  const isMobile = useIsMobile();

  const [currentModalData, setCurrentModalData] = useState<JSX.Element | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openKeynoteModal, setOpenKeynoteModal] = useState(false);
  const [modalClassName, setModalClassName] = useState('');
  // const [isMobile, setIsMobile] = useState(width < MOBILE_LAYOUT_BREAKPOINT);
  const [breakHeight, setBreakHeight] = useState(height < HEIGHT_LAYOUT_BREAKPOINT);

  // const [fullScreen, setFullScreen] = useState(false);
  // const [exploreData, setExploreData] = useState<Map<string, ExploreDataType> | null>(null);

  const dispatch = useDispatch();

  const ref = useRef<HTMLAudioElement>(null);
  const [playAudio, setPlayAudio] = useState(false);
  const { uniqueID } = useParams<ParamTypes>();
  const location = useLocation();
  
  const closeModal = () => {
    setOpenModal(false);
    setCurrentModalData(null);
  }

  const checkAudio = (src: string, data: boolean, event: (value: boolean) => void) => {
    if (ref.current) {
      if (data) {
        ref.current.pause();
        event(false);
      } else {
        stopAllAudio();
        ref.current.currentTime = 0;
        ref.current.loop = true;
        ref.current.src = src;
        ref.current.play()
        .then(() => {
          event(true);
        }).catch((error) => {
          console.log(error.message);
        })
      }
    }
  }

  useEffect(() => {
    // listener for forcing the audio to stop when playing video
    const stopAudioHandler = () => {
      setPlayAudio(false);
      stopAllAudio();
    }
    document.addEventListener("grace_request_stop_audio", stopAudioHandler);

    // setIsMobile(width < MOBILE_LAYOUT_BREAKPOINT);
    // setBreakHeight(height < HEIGHT_LAYOUT_BREAKPOINT);

    // resize hack for using VH on mobile devices
    const resizeFunction = () => {
      // console.log(`resizing: width = ${width} / height = ${height}`);
      // setIsMobile(width < MOBILE_LAYOUT_BREAKPOINT);
      setBreakHeight(height < HEIGHT_LAYOUT_BREAKPOINT);

      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    window.addEventListener('resize', resizeFunction);

    return () => { 
      document.removeEventListener("grace_request_stop_audio", stopAudioHandler);
      window.removeEventListener('resize', resizeFunction);
    }

  }, []);

  useEffect(() => {
    dispatch(setQuestions(testQuestions));
  }, [uniqueID]);

  useLayoutEffect(() => {
    if(ref.current) {
      checkAudio('/audio/music.mp3', playAudio, setPlayAudio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  useEffect(() => {
    // console.log(`Width: ${width} / Mobile width breakpoint:${MOBILE_LAYOUT_BREAKPOINT} / isMobile:${isMobile}`);
  }, [isMobile, width, height]);

  return (
    <>
      <audio id="explore-audio"  ref={ref} src=''/>
      <PageLayout
        isMobile={isMobile}
        isKeynote={location.pathname == pathName.startPage}
        setOpenModal={() => setOpenModal(true)}
        openModal={openModal}
        setCurrentModalData={setCurrentModalData}
        setModalClassName={setModalClassName}
        playAudio={playAudio}
        checkAudio={() => checkAudio('/audio/music.mp3', playAudio, setPlayAudio)}
        breakHeight={breakHeight}
      >
        <Switch>
          <Route 
            path={pathName.questionPage}
            render={() => <QuestionWrapper breakHeight={breakHeight} isMobile={isMobile}/>}
          />
          <Route 
            path={`${pathName.explore}/:uniqueID`}
            render={() => (
              <WebGl
                isMobile={isMobile}
                thinkers={testData}
                // allData={exploreData}
                // setAllData={setExploreData}
                playAudio={playAudio}
                checkAudio={() => checkAudio('/audio/music.mp3', playAudio, setPlayAudio)}
              />
            )}
          />
          <Route 
            path={pathName.explore}
            render={() => (
              <WebGl
                isMobile={isMobile}
                thinkers={testData}
                // allData={exploreData}
                // setAllData={setExploreData}
                playAudio={playAudio}
                checkAudio={() => checkAudio('/audio/music.mp3', playAudio, setPlayAudio)}
              />
            )}
          />
          <Route 
            path={pathName.thinkers}
            render={() => (
            <Thinkers
              isMobile={isMobile}
              thinkersData={testData}
              setOpenModal={() => setOpenModal(true)}
              openModal={openModal}
              setCurrentModalData={setCurrentModalData}
            />
            )}
          />
          <Route 
            path={pathName.about}
            render={() => <About isMobile={isMobile}/>}
          />
          <Route 
            path={pathName.submit}
            render={() => (
              <SubmitPage
                isMobile={isMobile}
                setOpenModal={() => setOpenModal(true)}
                openModal={openModal}
                setCurrentModalData={setCurrentModalData}
              />
            )}
          />
          <Route 
            path={pathName.success}
            render={() => (
              <SuccessPage
                isMobile={isMobile}
              />
            )}
          />
          <Route 
            path={pathName.keynotePage}
            render={() => (
              <KeynoteVideoPage
                isMobile={isMobile}
              />
            )}
          />
          <Route 
            path={pathName.teaWithRichPage}
            render={() => (
              <TeaWithRichVideoPage
                isMobile={isMobile}
              />
            )}
          />
          <Route 
            path={pathName.thanks}
            render={() => (
              <KeynoteThankYou
                isMobile={isMobile}
              />
            )}
          />
          <Route 
            path={pathName.startPage}
            render={() => (
              <StartPage
                isMobile={isMobile}
                thinkers={testData}
                setOpenModal={() => setOpenModal(true)}
                setOpenKeynoteModal={() => setOpenKeynoteModal(true)}
                openModal={openModal}
                openKeynoteModal={openKeynoteModal}
                closeModal={closeModal}
                setCurrentModalData={setCurrentModalData}
                setModalClassName={setModalClassName}
              />
            )}
          />
        </Switch>
      </PageLayout>
      <ModalDescription
          active={openModal}
          setActive={closeModal}
          className={modalClassName}
          isMobile={isMobile}
      >
        {currentModalData}
      </ModalDescription>
    </>
  );
}

export default App;
