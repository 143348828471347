import React, { memo, useEffect, useRef, useState } from 'react';
import { ThinkerData } from '../../constants/types';
import { stopAllAudio } from '../../helpers/util';
import { Thinker } from '../thinker/thinker';
import styles from './thinkers.module.scss';
import classnames from 'classnames';
import { ScrollDrag } from '../../HOC';
import { ThinkerDescription } from '../thinker/thinkerDescription';

interface ThinkersProps {
  thinkersData: ThinkerData[],
  isMobile: boolean,
  setOpenModal: () => void,
  setCurrentModalData: (value: JSX.Element) => void,
  openModal: boolean,
}

export const Thinkers: React.FC<ThinkersProps> = memo(({
  thinkersData,
  isMobile,
  setOpenModal,
  setCurrentModalData,
  openModal,
}) => {
  const [voice, setVoice] = useState('');
  const [currentThinker, setCurrentThinker] = useState<ThinkerData | null>(null);
  const refAudio = useRef<HTMLAudioElement>(null);

  const startAudio = (value: string) => {
    if (refAudio.current) {
      if (value === voice) {
        refAudio.current.pause();
        setVoice('');
      } else {
        setVoice(value);
      }
    }
  }

  const openDeeplink = (value:string | null) => {
    // the thinker CTA is a Deeplink to the Thinker OR a URL to the Explore section generally (if ALL REACTIONS)
    if(value != null){
      window.open(value);
    }
  }

  useEffect(() => {
    // console.log(currentThinker);
    if(currentThinker) {
      setCurrentModalData(
        <ThinkerDescription
          isMobile={isMobile}
          currentThinker={currentThinker}
        />
      )
      setOpenModal();
    }
  }, [currentThinker]);

  useEffect(() => {
    if(refAudio.current && voice) {
      stopAllAudio();
      refAudio.current.currentTime = 0;
      refAudio.current.play()
        .catch((error) => {
          console.log(error.message);
        })
    }
  }, [voice, refAudio])

  return (
    <div className={isMobile ? classnames(styles.thinkers, styles.mobile) : styles.thinkers}>
      <audio ref={refAudio} src={voice}/>
      <div className={styles.title}>
        FEATURED VOICES
      </div>
      <img className={styles.horizontal_line} src='/images/horizontalLine.png' alt='horizontal line' />
      <div className={styles.description}>
        For many, Grace isn’t just a young girl interested in science, 
        she’s different for different people. Some may see a younger 
        version of themselves in Grace, others, perhaps a daughter, 
        or friend who dropped out of university. Here, we listen to 
        scientific thought-leaders share their perspectives on who Grace 
        is to them. We’ll hear how Grace’s challenges as an outsider may 
        resonate, spark opinions and questions, as well as the positive 
        and negative emotions that surface from their own relatable memories. 
      </div>
      <div className={styles.instruction}>
        {`click & drag to see more`}
        <div className={styles.instructionWrapper}>
          <div className={styles.arrowLeft} />
          <div className={styles.circle} />
          <div className={styles.arrowRight} />
        </div>
      </div>
      <ScrollDrag className={styles.thinkerWrapper}>
        <>
        {
          thinkersData.map((thinker) => (
            <Thinker
              setThinker={setCurrentThinker}
              key={thinker.id}
              data={thinker}
              setVoice={startAudio}
              setDeeplink={openDeeplink}
            />
          ))
        }
        </>
      </ScrollDrag>
    </div>
  )
});