import * as THREE from 'three';

export default class SoundEffect {

    constructor() {
        this.audio = null;
    }

    init( srcPath, camera )
    {
        const fthis = this;
        const listener = new THREE.AudioListener();
        camera.add( listener );

        // create the PositionalAudio object (passing in the listener)
        this.audio = new THREE.Audio( listener );

        // load a sound and set it as the PositionalAudio object's buffer
        const audioLoader = new THREE.AudioLoader();
        audioLoader.load( srcPath, function( buffer ) {
            fthis.audio.setBuffer( buffer );
            // fthis.audio.setRefDistance( 20 ); // for positional audio only
        });
    }
}