
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { pathName } from '../../constants/constants';
import KeynotePageHeading from './KeynotePageHeading';
import KeynotePageHeadingImage from './KeynotePageHeadingImage';
import KeynotePageFooter from './KeynotePageFooter';
import KeynotePageFooterImage from './KeynotePageFooterImage';
import FooterImage from '../../images/graceKeynoteFooterHero.png';
import { KeynoteParticleCanvas } from './particleCanvas';
import styles from './KeynotePage.module.scss';
import classnames from 'classnames';
import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, Move, MoveIn, MoveOut, Sticky, StickyIn, Zoom, ZoomIn, ZoomOut } from "react-scroll-motion";
import KeynoteParticleAnimation from './KeynoteParticleAnimation';
import KeynoteParticleCanvasApp from './particleCanvas/KeynoteParticleCanvasApp';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import HeaderImageBg from '../../images/graceKeynoteHeadingHero_bg.png';
import HeaderImageGrace from '../../images/graceKeynoteHeadingHero_grace.png';
import HeaderImageParticles from '../../images/graceKeynoteHeadingHero_particles.png';
import PostcardImageSection4 from '../../images/postcard_scientists.jpg';
import PostcardImageSection8 from '../../images/postcard_roundtable.jpg';
import PostcardImageSection9 from '../../images/postcard_rich.jpg';
import Button from '../button';
import KeynoteImageSequenceAnimation from './KeynoteImageSequenceAnimation';
import KeynoteTransformAnimation from './KeynoteTransformAnimation';

const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
const FadeUp = batch(Fade(), Move(), Sticky());

const particleCanvasApp1 = new KeynoteParticleCanvasApp();
const particleCanvasApp2 = new KeynoteParticleCanvasApp();
const particleCanvasApp3 = new KeynoteParticleCanvasApp();

interface KeynotePageProps {
  isMobile: boolean,
}

const KeynotePage = ({
  isMobile,
}: KeynotePageProps) => {

  const history = useHistory();
  const seq1Frames = 46;
  const seq2Frames = 28;
  const seq3Frames = 97;
  const seq4Frames = 91;
  const seq5Frames = 44;

  const [scrollSpeed, setScrollSpeed] = useState(0);
  const [scrollPos, setScrollPos] = useState(0);
  const [scrollLerp1, setScrollLerp1] = useState(0);
  const [scrollLerp2, setScrollLerp2] = useState(0);
  const [scrollLerp3, setScrollLerp3] = useState(0);
  const [scrollLerp4, setScrollLerp4] = useState(0);
  const [scrollLerp5, setScrollLerp5] = useState(0);
  const [scrollLerp6, setScrollLerp6] = useState(0);
  const [scrollLerp7, setScrollLerp7] = useState(0);
  const [scrollLerp8, setScrollLerp8] = useState(0);
  const [scrollLerp9, setScrollLerp9] = useState(0);
  const [scrollLerp10, setScrollLerp10] = useState(0);

  var scrollLimit = Math.max( document.body.scrollHeight, document.body.offsetHeight, 
    document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );

  /**
   * This hook gets the scroll position on the page. The scroll-motion library doesn't provide any callbacks for the ScrollPage objects so it is impossible to determine when a section of the page becomes visible or has moved in or out
   * 
   * IMPORTANT: the flow and timings of all of the image sequences and particle effects relies on their being exactly 10 main sections (full page height). This is because each section has an in and out point set as a lerped value between the page scroll Y bounds. These values are set in the LERP variables below.
   * 
   */
  useScrollPosition(({ prevPos, currPos }) => {
    const scrollMax = scrollLimit - window.innerHeight;
    const sPos = currPos.y*-1;
    setScrollSpeed(currPos.y - prevPos.y);
    setScrollPos(sPos);
    // console.log(sPos/scrollMax); // use this to debug the page scroll positions

    // util functions for clamp and lerp
    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
    const lerp = (a, b, t) => (t - a) / (b - a);

    // get a clamp value between the two points in the scroll I want to use for the particle animation

    // LERP 1
    const min1 = scrollMax*0.04;
    const max1 = scrollMax*0.09;
    const clamped1 = clamp(sPos, min1, max1)-min1;
    const lerped1 = lerp(0, max1-min1, clamped1);
    setScrollLerp1(lerped1);

    // LERP 2
    const min2 = scrollMax*0.15;
    const max2 = scrollMax*0.20;
    const clamped2 = clamp(sPos, min2, max2)-min2;
    const lerped2 = lerp(0, max2-min2, clamped2);
    setScrollLerp2(lerped2);

    // LERP 3
    const min3 = scrollMax*0.27;
    const max3 = scrollMax*0.32;
    const clamped3 = clamp(sPos, min3, max3)-min3;
    const lerped3 = lerp(0, max3-min3, clamped3);
    setScrollLerp3(lerped3);

    // LERP 4
    const min4 = scrollMax*0.35;
    const max4 = scrollMax*0.40;
    const clamped4 = clamp(sPos, min4, max4)-min4;
    const lerped4 = lerp(0, max4-min4, clamped4);
    setScrollLerp4(lerped4);

    // LERP 5
    const min5 = scrollMax*0.47;
    const max5 = scrollMax*0.49;
    const clamped5 = clamp(sPos, min5, max5)-min5;
    const lerped5 = lerp(0, max5-min5, clamped5);
    setScrollLerp5(lerped5);

    // LERP 6
    const min6 = scrollMax*0.55;
    const max6 = scrollMax*0.60;
    const clamped6 = clamp(sPos, min6, max6)-min6;
    const lerped6 = lerp(0, max6-min6, clamped6);
    setScrollLerp6(lerped6);

    // LERP 7
    const min7 = scrollMax*0.65;
    const max7 = scrollMax*0.70;
    const clamped7 = clamp(sPos, min7, max7)-min7;
    const lerped7 = lerp(0, max7-min7, clamped7);
    setScrollLerp7(lerped7);

    // LERP 8
    const min8 = scrollMax*0.75;
    const max8 = scrollMax*0.80;
    const clamped8 = clamp(sPos, min8, max8)-min8;
    const lerped8 = lerp(0, max8-min8, clamped8);
    setScrollLerp8(lerped8);

    // LERP 9
    const min9 = scrollMax*0.85;
    const max9 = scrollMax*0.90;
    const clamped9 = clamp(sPos, min9, max9)-min9;
    const lerped9 = lerp(0, max9-min9, clamped9);
    setScrollLerp9(lerped9);

    // LERP 10
    const min10 = scrollMax*0.95;
    const max10 = scrollMax*1.00;
    const clamped10 = clamp(sPos, min10, max10)-min10;
    const lerped10 = lerp(0, max10-min10, clamped10);
    setScrollLerp10(lerped10);

    // console.log(`${lerped1} / ${lerped2} / ${lerped3}`);

  }, [scrollSpeed, scrollPos, scrollLerp1, scrollLerp2, scrollLerp3, scrollLerp4, scrollLerp5, scrollLerp6]);

  // useEffect(() => {
  //   console.log(`isMobile: ${isMobile}`);
  // }, [isMobile]);

  return (
    /**
     * Try snap property as 'mandatory' or 'proximity'
     */
    <div className={styles.keynotePage}>
    <ScrollContainer snap="none">
      {/**
       * MAIN Grace heading with multiple layers of imagery
       */}
      <ScrollPage page={0}>
        <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -20), ZoomOut(1,1.3))}>
          <KeynotePageHeadingImage 
            isMobile={isMobile}
            imageUrl={HeaderImageBg}
          />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -25), ZoomOut(1,1.5))}>
          <KeynotePageHeadingImage 
            isMobile={isMobile}
            imageUrl={HeaderImageGrace}
          />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -30), ZoomOut(1,1))}>
          <KeynotePageHeadingImage 
            isMobile={isMobile}
            imageUrl={HeaderImageParticles}
          />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(), MoveOut(0, 0), ZoomOut(1,1.5))}>
          <KeynotePageHeading 
            isMobile={isMobile}
            scrollToElementId={'keynoteFormElement'}
          />
        </Animator>
      </ScrollPage>

      {/** -------------------------------------------------------------------
       *  Section 1: Introducing Extraordinary
       */}
      <ScrollPage page={1}>
        <Animator animation={batch(Fade(), Sticky())}>
          <div style={{height:'200px'}}/>
        </Animator>
      </ScrollPage>
      <ScrollPage page={2}>
        <Animator animation={batch(Fade(), Sticky(), ZoomOut(1,1.5))}>
          <KeynoteParticleCanvas 
            isMobile={isMobile}
            canvasClassName='keyNoteParticleCanvas1'
            imageReference='1'
            scrollSpeed={scrollSpeed}
            scrollPos={scrollPos}
            particleScroll={scrollLerp1}
            particleApp={particleCanvasApp1}
          />
        </Animator>
        <Animator animation={batch(FadeIn(), isMobile ? Sticky() : Sticky(35), ZoomIn(0.5,1), isMobile ? ZoomOut(1,1) : ZoomOut(1,1.5))}>
          <div style={{width: isMobile ? "90vw" : "40vw"}}>
            <div className={isMobile ? styles.contentWrap : styles.contentWrapMobile}>
              <h3>Introducing Extraordinary</h3>
              <p>Take a moment to picture your industry but in thirty years time. What are the problems it's trying to solve? Are diversity, equity, and inclusion a priority? Who are the leaders in your field?</p>
              <p>If there's one certainty, it's that all of STEM (Science, Technology, Engineering, Mathematics) will be familiar with one special scientist, who will have had an influence on all of those questions, and you may have met her already.</p> 
              <p>In 2021, a passionate young scientist called Grace embarked on a journey with a huge ambition - To create real, positive change in diversity, equity, and inclusion (DEI) for STEM.</p>
              <p>To do this, she needed your mentorship and invaluable insight, allowing her to start incredibly impactful conversations that will continue for years to come.</p>
              <p>Now, the importance of nurturing diverse perspectives, in the pursuit of discovering extraordinary answers has never been more evident.</p>
            </div>
          </div>
        </Animator>
      </ScrollPage>

      {/** -------------------------------------------------------------------
       *  Section 2: Grace's Inspiration
       */}
      <ScrollPage page={3}>
        <Animator animation={batch(Fade(), Sticky())}>
          <div style={{height:'200px'}}/>
        </Animator>
      </ScrollPage>
      <ScrollPage page={4}>
        <Animator animation={batch(Fade(), Sticky(isMobile ? 25 : 75,isMobile ? 30 : 30), ZoomOut(1,1.5))}>
            <KeynoteParticleAnimation 
              isMobile={isMobile} 
              isUseScientist={true}
              scientistId={5}
              width={isMobile ? 160 : 240}
              height={isMobile ? 160 : 240}
              shouldRotate={false}
              />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(isMobile ? 50 : 60,isMobile ? 28 : 40), ZoomOut(1,1.5))}>
            <KeynoteParticleAnimation 
              isMobile={isMobile} 
              isUseScientist={true}
              scientistId={2}
              width={isMobile ? 180 : 240}
              height={isMobile ? 180 : 240}
              shouldRotate={false}
              />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(isMobile ? 70 : 70,isMobile ? 70 : 70), ZoomOut(1,1.5))}>
            <KeynoteParticleAnimation 
              isMobile={isMobile} 
              isUseScientist={true}
              scientistId={3}
              width={isMobile ? 240 : 240}
              height={isMobile ? 240 : 240}
              shouldRotate={false}
              />
        </Animator>
        <Animator animation={batch(FadeIn(), isMobile ? Sticky() : Sticky(35), ZoomIn(0.5,1),  isMobile ? ZoomOut(1,1) : ZoomOut(1,1.5))}>
        <div style={{width: isMobile ? "90vw" : "40vw"}}>
          <div className={isMobile ? styles.contentWrap : styles.contentWrapMobile}>
              <h3>Grace's Inspiration</h3>
              <p>Drawing upon their experiences, powerful interviews with world-renowned scientists who have lived through underrepresentation inspired Grace's entire development, from what she should look like to the details of her life story.</p>
            </div>
          </div>
        </Animator>
      </ScrollPage>

      {/** -------------------------------------------------------------------
       *  Section 3: Grace's Plea for Mentorship
       */}
      <ScrollPage page={5}>
        <Animator animation={batch(Fade(), Sticky())}>
          <div style={{height:'200px;'}}/>
        </Animator>
      </ScrollPage>
      <ScrollPage page={6}>
        <Animator animation={batch(Fade(), Sticky(), ZoomOut(1,1.5))}>
            <KeynoteImageSequenceAnimation
              isMobile={isMobile}
              width={960}
              height={540}
              frames={seq3Frames}
              currentFrameLerp={scrollLerp3}
              imagePathPrefix="./images/keynote/seq3/seq3_"
              className={styles.imageSequence}
              opacity={isMobile ? 0.2 : 0.3}
            />
        </Animator>
        <Animator animation={batch(FadeIn(), Sticky(), ZoomIn(0.5,1), isMobile ? ZoomOut(1,1) : ZoomOut(1,1.5))}>
          <div style={{width: isMobile ? "90vw" : "40vw"}}>
            <div className={isMobile ? styles.contentWrap : styles.contentWrapMobile}>
              <h3>Grace's plea for mentorship</h3>
              <p>Though just a young girl with a passion for science, Grace was already anticipating the barriers she'll face on her journey.</p>
              <p>She found herself frequently asking a life-defining question:</p>
              <p>“Should I continue with a career in science?”</p>
              <p>Knowing that she already represents the next generation of scientists, but also, the underrepresented, she felt it was time to introduce herself in a big way.</p>
              <div>
                <Button
                  onClick={() => {
                      history.push(pathName.about);
                  }}
                  className={styles.buttonNext}
                  style={isMobile ? {marginTop:'1em', width:'100%'} : {marginTop:'1.5em'}}
                  buttonStyles={styles.buttonNextWrapper}
                  >
                  ABOUT THE PROJECT
                </Button>
              </div>
            </div>
          </div>
        </Animator>
      </ScrollPage>

      {/** -------------------------------------------------------------------
       *  Section 4: Featured Voices
       */}
      <ScrollPage page={7}>
        <Animator animation={batch(Fade(), Sticky())}>
          <div style={{height:'200px;'}}/>
        </Animator>
      </ScrollPage>
      <ScrollPage page={8}>
        <Animator animation={batch(Fade(), isMobile ? Sticky() : Sticky(70), ZoomOut(1,1.5), MoveIn(isMobile ? 0 : -100,0), MoveOut(isMobile ? 0 : 100,0))}>
          <KeynoteTransformAnimation
            isMobile={isMobile}
            transformFactor={scrollLerp4}
            targetAngle={-45}
            width={isMobile ? '100vw' : 500}
            height={isMobile ? '100vh' : 500}
            srcImage={PostcardImageSection4}
            opacity={isMobile ? 0.2 : 1}
          />
        </Animator>
        <Animator animation={batch(FadeIn(), isMobile ? Sticky() : Sticky(35), ZoomIn(0.5,1), isMobile ? ZoomOut(1,1) : ZoomOut(1,1.5))}>
          <div style={{width: isMobile ? "90vw" : "40vw"}}>
            <div className={isMobile ? styles.contentWrap : styles.contentWrapMobile}>
              <h3>Featured Voices</h3>
              <p>Grace's call for mentorship kickstarted a crucial, global conversation, gaining support from industry leaders. It wasn't long before respected influencers within STEM reflected on what Grace had to say, sharing the similarities and differences they experienced on their journeys.</p>
              <div>
                <Button
                  onClick={() => {
                      history.push(pathName.thinkers);
                  }}
                  className={styles.buttonNext}
                  style={isMobile ? {marginTop:'1em', width:'100%'} : {marginTop:'1.5em'}}
                  buttonStyles={styles.buttonNextWrapper}
                  >
                  FEATURED VOICES
                </Button>
              </div>
            </div>
          </div>
        </Animator>
      </ScrollPage>

      {/** -------------------------------------------------------------------
       *  Section 5: Mentoring Grace
       */}
      <ScrollPage page={9}>
        <Animator animation={batch(Fade(), Sticky())}>
          <div style={{height:'200px'}}/>
        </Animator>
      </ScrollPage>
      <ScrollPage page={10}>
        <Animator animation={batch(Fade(), Sticky(), ZoomOut(1,1.5))}>
          <KeynoteParticleCanvas 
            isMobile={isMobile}
            canvasClassName='keyNoteParticleCanvas2'
            imageReference='4'
            scrollSpeed={scrollSpeed}
            scrollPos={scrollPos}
            particleScroll={scrollLerp5}
            particleApp={particleCanvasApp2}
          />
        </Animator>
        <Animator animation={batch(FadeIn(), Sticky(), ZoomIn(0.5,1), isMobile ? ZoomOut(1,1) : ZoomOut(1,1.5))}>
          <div style={{width: isMobile ? "90vw" : "40vw"}}>
            <div className={isMobile ? styles.contentWrap : styles.contentWrapMobile}>
              <h3>Mentoring Grace</h3>
              <p>Mentors who answered Grace's plea for guidance had the opportunity to get to know her further, answering questions and listening to her worries as she grew up and started her scientific career. Before long, thanks to such a diverse array of mentors, a truly accurate and detailed picture of our industry began to take shape.</p>
              <div>
                <Button
                  onClick={() => {
                      history.push(pathName.submit);
                  }}
                  className={styles.buttonNext}
                  style={isMobile ? {marginTop:'1em', width:'100%'} : {marginTop:'1.5em'}}
                  buttonStyles={styles.buttonNextWrapper}
                  >
                  TAKE THE SURVEY
                </Button>
              </div>
            </div>
          </div>
        </Animator>
      </ScrollPage>

      {/** -------------------------------------------------------------------
       *  Section 6: Exploring Extraordinary
       */}
      <ScrollPage page={11}>
        <Animator animation={batch(Fade(), Sticky())}>
          <div style={{height:'200px'}}/>
        </Animator>
      </ScrollPage>
      <ScrollPage page={12}>
        <Animator animation={batch(Fade(), Sticky(26,28), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(350,0), MoveOut(-350,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isBlue={false}
            width={50}
            height={50}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(30,33), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(360,0), MoveOut(-360,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isBlue={true}
            width={60}
            height={60}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(35,30), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(360,0), MoveOut(-360,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isBlue={true}
            width={60}
            height={60}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(95,28), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(350,0), MoveOut(-350,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isBlue={false}
            width={50}
            height={50}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(75,33), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(360,0), MoveOut(-360,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isBlue={true}
            width={60}
            height={60}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(65,30), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(360,0), MoveOut(-360,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isBlue={true}
            width={60}
            height={60}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(40,32), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(300,0), MoveOut(-300,0))}>
            <KeynoteParticleAnimation 
              isMobile={isMobile} 
              isUseScientist={true}
              scientistId={4}
              width={80}
              height={80}
              shouldRotate={false}
              />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(45,30), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(360,0), MoveOut(-360,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isBlue={true}
            width={60}
            height={60}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(48,28), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(360,0), MoveOut(-360,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isBlue={true}
            width={60}
            height={60}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(50,32), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(350,0), MoveOut(-350,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isBlue={false}
            width={50}
            height={50}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(55,30), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(380,0), MoveOut(-380,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isBlue={true}
            width={80}
            height={80}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(60,32), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(400,0), MoveOut(-400,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile}
            isBlue={false}
            width={100}
            height={100}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(85,30), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(360,0), MoveOut(-360,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isBlue={true}
            width={60}
            height={60}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(80,28), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(360,0), MoveOut(-360,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isBlue={true}
            width={60}
            height={60}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(90,32), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(350,0), MoveOut(-350,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isBlue={false}
            width={50}
            height={50}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(100,30), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(380,0), MoveOut(-380,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isBlue={true}
            width={80}
            height={80}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(110,32), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(400,0), MoveOut(-400,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile}
            isBlue={false}
            width={100}
            height={100}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(130,30), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(360,0), MoveOut(-360,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isBlue={true}
            width={60}
            height={60}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(140,28), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(360,0), MoveOut(-360,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isBlue={true}
            width={60}
            height={60}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(110,32), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(350,0), MoveOut(-350,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isBlue={false}
            width={50}
            height={50}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(120,30), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(380,0), MoveOut(-380,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isBlue={true}
            width={80}
            height={80}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(180,32), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(400,0), MoveOut(-400,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile}
            isBlue={false}
            width={100}
            height={100}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(65,30), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(450,0), MoveOut(-450,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isUseScientist={true}
            scientistId={1}
            width={120}
            height={120}
            shouldRotate={false}
            />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(75,28), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(450,0), MoveOut(-450,0))}>
            <KeynoteParticleAnimation 
              isMobile={isMobile} 
              isBlue={false}
              width={120}
              height={120}
              />
        </Animator>
        <Animator animation={batch(Fade(), Sticky(80,30), ZoomIn(0.5,1), ZoomOut(1,2), MoveIn(500,0), MoveOut(-500,0))}>
          <KeynoteParticleAnimation 
            isMobile={isMobile} 
            isBlue={true}
            width={150}
            height={150}
            />
        </Animator>
        <Animator animation={batch(FadeIn(), isMobile ? Sticky(50,60) : Sticky(50,60), ZoomIn(0.5,1), isMobile ? ZoomOut(1,1) : ZoomOut(1,1.5))}>
          <div style={{width: isMobile ? "90vw" : "40vw"}}>
            <div className={isMobile ? styles.contentWrap : styles.contentWrapMobile}>
              <h3>Exploring Extraordinary</h3>
              <p>Grace's questions are just as relevant today as they've ever been. To continue attracting valuable perspectives and show the importance of mentorship, she wanted to portray the breadth and depth of this global conversation.</p>
              <p>She created an interactive 'perspective universe' that soon became filled with advice, support, and the incredibly honest experiences that helped her. Contributing to this platform had become, in its own right, a source of motivation and pride for her wider mentor community.</p>
              <div>
                <Button
                  onClick={() => {
                      history.push(pathName.explore);
                  }}
                  className={styles.buttonNext}
                  style={isMobile ? {marginTop:'1em', width:'100%'} : {marginTop:'1.5em'}}
                  buttonStyles={styles.buttonNextWrapper}
                  >
                  EXPLORE THE CONVERSATION
                </Button>
              </div>
            </div>
          </div>
        </Animator>
      </ScrollPage>

      {/** -------------------------------------------------------------------
       *  Section 7: Grace's Keynote
       */}
      <ScrollPage page={13}>
        <Animator animation={batch(Fade(), Sticky())}>
          <div style={{height:'200px'}}/>
        </Animator>
      </ScrollPage>
      <ScrollPage page={14}>
        <Animator animation={batch(Fade(), Sticky(), ZoomOut(1,1.5))}>
            <KeynoteImageSequenceAnimation
              isMobile={isMobile}
              width={960}
              height={540}
              frames={seq1Frames}
              currentFrameLerp={scrollLerp7}
              imagePathPrefix="./images/keynote/seq1/seq1_"
              className={styles.imageSequence}
              opacity={isMobile ? 0.2 : 0.5}
            />
        </Animator>
        <Animator animation={batch(FadeIn(), isMobile ? Sticky() : Sticky(65), ZoomIn(0.5,1), isMobile ? ZoomOut(1,1) : ZoomOut(1,1.5))}>
          <div style={{width: isMobile ? "90vw" : "40vw"}}>
            <div className={isMobile ? styles.contentWrap : styles.contentWrapMobile}>
              <h3>Grace's Keynote</h3>
              <p>It was now time for Grace to step into the spotlight.</p>
              <p>Brought to life through state-of-the-art MetaHuman technology, she presented her findings back to her mentors.</p>
              <p>She inspired them with a 'hypothesis for change' developed by thousands of global mentors, but spoken by one shared, universal voice for STEM - her voice.</p>
              <div>
                <Button
                  onClick={() => {
                      history.push(pathName.keynotePage);
                  }}
                  className={styles.buttonNext}
                  style={{marginTop:'1.5em'}}
                  buttonStyles={styles.buttonNextWrapper}
                  >
                  WATCH THE KEYNOTE
                </Button>
              </div>
            </div>
          </div>
        </Animator>
      </ScrollPage>

      {/** -------------------------------------------------------------------
       *  Section 8: An Extraordinary Discussion
       */}
      <ScrollPage page={15}>
        <Animator animation={batch(Fade(), Sticky())}>
          <div style={{height:'200px'}}/>
        </Animator>
      </ScrollPage>
      <ScrollPage page={16}>
        <Animator animation={batch(Fade(), isMobile ? Sticky() : Sticky(75), ZoomOut(1,1.5), MoveIn(isMobile ? 0 : -100,0), MoveOut(isMobile ? 0 : 100,0))}>
          <KeynoteTransformAnimation
            isMobile={isMobile}
            transformFactor={scrollLerp8}
            targetAngle={-45}
            width={isMobile ? '100vw' : 500}
            height={isMobile ? '100vh' : 500}
            srcImage={PostcardImageSection8}
            opacity={isMobile ? 0.2 : 1}
          />
        </Animator>
        <Animator animation={batch(FadeIn(), isMobile ? Sticky() : Sticky(35), ZoomIn(0.5,1), isMobile ? ZoomOut(1,1) : ZoomOut(1,1.5))}>
          <div style={{width: isMobile ? "90vw" : "40vw"}}>
            <div className={isMobile ? styles.contentWrap : styles.contentWrapMobile}>
              <h3>Extraordinary Discussions</h3>
              <p>Premiering at ASMS, one of the biggest analytical science conferences of the year, Grace's keynote became the focal point of a fascinating discussion amongst industry leaders and other attending members of the STEM community, asking themselves how to put her 'Change Hypothesis' into action.</p>
            </div>
            {/* <Button
              onClick={() => {
                  history.push(pathName.keynotePage);
              }}
              className={styles.buttonNext}
              style={{marginTop:'0.5em'}}
              buttonStyles={styles.buttonNextWrapper}
              >
              WATCH THE DISCUSSION
            </Button> */}
          </div>
        </Animator>
      </ScrollPage>

      {/** -------------------------------------------------------------------
       *  Section 9: TEA WITH RICH – EXTRAORDINARY CONTINUES
       */}
      <ScrollPage page={17}>
        <Animator animation={batch(Fade(), Sticky())}>
          <div style={{height:'200px'}}/>
        </Animator>
      </ScrollPage>
      <ScrollPage page={18}>
      <Animator animation={batch(Fade(), isMobile ? Sticky() : Sticky(35), ZoomOut(1,1.5), MoveIn(isMobile ? 0 : 100,0), MoveOut(isMobile ? 0 : -100,0))}>
          <KeynoteTransformAnimation
            isMobile={isMobile}
            transformFactor={scrollLerp9}
            targetAngle={45}
            width={isMobile ? '100vw' : 480}
            height={isMobile ? '100vh' : 333}
            srcImage={PostcardImageSection9}
            opacity={isMobile ? 0.2 : 1}
          />
        </Animator>
        <Animator animation={batch(FadeIn(), isMobile ? Sticky() : Sticky(70), ZoomIn(0.5,1), isMobile ? ZoomOut(1,1) : ZoomOut(1,1.5))}>
          <div style={{width: isMobile ? "90vw" : "40vw"}}>
            <div className={isMobile ? styles.contentWrap : styles.contentWrapMobile}>
              {/* <h3>Tea with Rich - Extraordinary continues</h3> */}
              <p>Grace's keynote received significant traction, resulting in journalist Rich Whitworth following up with Grace on The Analytical Scientist's well-known interview series 'Tea with Rich'.</p>
            </div>
            <Button
              onClick={() => {
                  history.push(pathName.teaWithRichPage);
              }}
              className={styles.buttonNext}
              style={{marginTop:'0.5em'}}
              buttonStyles={styles.buttonNextWrapper}
              >
              VIEW THE INTERVIEW
            </Button>
          </div>
        </Animator>
      </ScrollPage>

      {/** -------------------------------------------------------------------
       *  Section 10: AN EXTRAORDINARY FUTURE
       */}
      <ScrollPage page={19}>
        <Animator animation={batch(Fade(), Sticky())}>
          <div style={{height:'200px'}}/>
        </Animator>
      </ScrollPage>
      <ScrollPage page={20}>
        <Animator animation={batch(Fade(), Sticky(), ZoomOut(1,1.5))}>
            <KeynoteImageSequenceAnimation
              isMobile={isMobile}
              width={960}
              height={540}
              frames={seq4Frames}
              currentFrameLerp={scrollLerp10}
              imagePathPrefix="./images/keynote/seq4/seq4_"
              className={styles.imageSequence}
              opacity={0.5}
            />
        </Animator>
        <Animator animation={batch(FadeIn(), isMobile ? Sticky() : Sticky(30), ZoomIn(0.5,1), isMobile ? ZoomOut(1,1) : ZoomOut(1,1.5))}>
          <div style={{width: isMobile ? "90vw" : "40vw"}}>
            <div className={isMobile ? styles.contentWrap : styles.contentWrapMobile}>
              <h3>An Extraordinary Future</h3>
              <p>Grace's mentorship found that diversity of perspective isn't just needed in STEM, but in fact, it's enormously desired.</p>
              <p>Grace may be a MetaHuman, but she has become a very real symbol for change, and she's only just getting started…</p>
            </div>
            {/* <Button
              onClick={() => {
                  history.push(pathName.keynotePage);
              }}
              className={styles.buttonNext}
              style={{marginTop:'0.5em'}}
              buttonStyles={styles.buttonNextWrapper}
              >
              WATCH THE KEYNOTE
            </Button> */}
          </div>
        </Animator>
      </ScrollPage>
    </ScrollContainer>
    </div>
  )
}
export default memo(KeynotePage);
        