import React, { memo, useEffect } from 'react';
import particleApp from './ParticleApp';
import styles from './backgroundParticleAnimation.module.scss';
import classnames from 'classnames';

interface BackgroundParticleAnimationProps { 
  isMobile: boolean,  
}
export const BackgroundParticleAnimation: React.FC<BackgroundParticleAnimationProps> = memo(({
    isMobile,
  }) => {

    useEffect(() => {
      if(particleApp.isInitialised){
        // the app instance is already created so just resume
        particleApp.resume();
      }else{
        // app instance doesn't exist so initialise it
        particleApp.init();
      }

      // cleanup - this is where we need to suspend the ParticleApp
      return () => {
        particleApp.suspend();
      }
    }, []);

    return (
      <div className={isMobile ? classnames('backgroundParticleAnimation', styles.animation, styles.mobile_animation) : classnames('backgroundParticleAnimation', styles.animation)}></div>
    )
  })