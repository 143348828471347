import React, { memo, useLayoutEffect, useState } from 'react';
import explorerApp from './ExplorerApp';
import styles from './explorer.module.scss';
import classnames from 'classnames';

interface ExplorerProps {   
}
export const Explorer: React.FC<ExplorerProps> = memo(({
    
  }) => {

    const [isInitialised, setIsInitialised] = useState(false);

    useLayoutEffect(() => {
      if(explorerApp.isInitialised){
        // the app instance is already created so just resume
        explorerApp.resume();
      }else{
        // app instance doesn't exist so initialise it
        explorerApp.init();
      }

      // cleanup - this is where we need to suspend the ParticleApp
      return () => {
        explorerApp.suspend();
      }
    }, []);

    return (
      <div className={classnames('explorer', styles.explorer)}></div>
    )
  })