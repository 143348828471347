import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import { pathName } from "../../constants/constants";
import { Pages } from "../../constants/types";
import Button from "../button";
import styles from "./menu.module.scss";
import classnames from 'classnames';
import { SocialButtons } from "../footer/socialButtons";
import { Cookie, Privacy, Terms } from "../policyPages";
import { goToPage } from "../../helpers/util";

interface MenuProps {
  page: Pages,
  setPage: (value: Pages) => void,
  setOpen: (value: boolean) => void,
  isMobile: boolean,
  active: boolean,
  setOpenModal: () => void,
  setCurrentModalData: (value: JSX.Element) => void,
  openModal: boolean,
  setModalClassName: (value: string) => void,
}

export const Menu: React.FC<MenuProps> = memo((
  {
    page,
    setPage,
    setOpen,
    isMobile,
    active,
    setOpenModal,
    setCurrentModalData,
    openModal,
    setModalClassName,
  }) => {
  const history = useHistory();
  
  const clickMenu = (value: string) => {
    history.push(value);
    setOpen(false);
  };

  const handleOpenModal = (policy: JSX.Element) => {
    setModalClassName(styles.modal_open);
    setCurrentModalData(policy);
    setOpenModal();
    setOpen(false);
  }

  return (
    <div className={!isMobile ? styles.menu : classnames(styles.menu, styles.modal_menu)}> 
      <Button
        onClick={() => clickMenu(pathName.keynotePage)}
        className={styles.menuButton}
      >
        GRACE'S KEYNOTE
      </Button>
      <img className={styles.horizontal_line} src='/images/horizontalLine.png' alt='horizontal line' />
      <Button
        onClick={() => clickMenu(pathName.about)}
        className={styles.menuButton}
      >
        ABOUT THE PROJECT
      </Button>
       <img className={styles.horizontal_line} src='/images/horizontalLine.png' alt='horizontal line' />
      <Button
        onClick={() => clickMenu(pathName.thinkers)}
        className={styles.menuButton}
      >
        FEATURED VOICES
      </Button>
       <img className={styles.horizontal_line} src='/images/horizontalLine.png' alt='horizontal line' />
      <Button
        onClick={() => clickMenu(pathName.explore)}
        className={styles.menuButton}
      >
        EXPLORE THE CONVERSATION
      </Button>
       <img className={styles.horizontal_line} src='/images/horizontalLine.png' alt='horizontal line' />
      <Button
        onClick={() => clickMenu(pathName.questionPage)}
        className={styles.menuButton}
      >
        INSPIRE EXTRAORDINARY: GRACE'S QUESTIONS
      </Button>
      {
        !isMobile
        ? (
          <>
            <div className={styles.share}>
              {`Share this & help Grace on her journey`}
            </div>
            <div className={styles.social}>
              <SocialButtons
                isMobile={isMobile}
                isExploreUrl={false}
              />
            </div>
            <div className={styles.privacy}>
              <Button
               onClick={() => goToPage('https://sciex.com/legal-pages/privacy-policy')}
              >
                Privacy Policy
              </Button>
              <div />
              <Button
               onClick={() => goToPage('https://sciex.com/')}
              >
                Cookie Policy
              </Button>
              <div />
              <Button
               onClick={() => goToPage('https://sciex.com/legal-pages/legal-information')}
              >
                {`Terms & Conditions`}
              </Button>
            </div>
          </>
        )
        : (
          <>
          <div className={styles.privacy}>
              <Button
               onClick={() => goToPage('https://sciex.com/legal-pages/privacy-policy')}
              >
                Privacy Policy
              </Button>
              <Button
               onClick={() =>  goToPage('https://sciex.com/')}
              >
                Cookie Policy
              </Button>
              <Button
               onClick={() => goToPage('https://sciex.com/legal-pages/legal-information')}
              >
                {`Terms & Conditions`}
              </Button>
            </div>
            <div className={styles.share}>
              Share
            </div>
            <div className={styles.social}>
              <SocialButtons
                isMobile={isMobile}
                isExploreUrl={false}
              />
            </div>
          </>
        )
      }
    </div>
  );
});
