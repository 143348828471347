// import THREE, { Object3D } from 'three';
import * as THREE from 'three';

//import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { MeshLine, MeshLineMaterial, MeshLineRaycast } from 'threejs-meshline';

import { TweenMax, Back, Expo, Sine, Bounce } from 'gsap';
import { OrbitControls } from './controls/OrbitControls';
import Stars from './stars/Stars';
import Audio from './audio/Audio';
import SoundEffect from './audio/SoundEffect';
import Spritesheet from './spritesheet/Spritesheet';
import ParticleBackground from './particles/ParticleBackground';
// import { getGPUTier } from 'detect-gpu';

export default class WebGLView {

	constructor(app,tiers) {
		this.app          = app;
		this.audioTexture = null;
		this.textTexture  = null;
        this.initThree( tiers );
		this.isSuspended = false;
		this.isLaunched = false;
	}

	initThree( tiers ) {

		let checkForSafari = navigator.userAgent.toLowerCase();
		// scene
		this.bgScene        = new THREE.Scene();
		this.backScene      = new THREE.Scene();
		this.scene          = new THREE.Scene();
		let _self           = this;
		let fogColor        = new THREE.Color(0x003c69);
 		this.polarAngle     = -100;
 		this.azimuthAngle   = -100;
 		this.surveyData     = [];
 		this.originMarker   = null;
 		this.filtering      = false;
 		this.fromFilter     = false;
 		this.contentOpened  = false;
 		this.tiers          = tiers;
 		this.deeplink       = false;
 		this.deeplinkTarget = null;

        this.scene.fog = new THREE.FogExp2(fogColor, 0.20);


        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // setup: background.scene                    /////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        this.bgScene  = new THREE.Scene();
    	this.bgCamera = new THREE.OrthographicCamera( -1, 1, 1, -1, 0, 1 );

		let bgTexture = null;
		const quad = new THREE.Mesh( new THREE.PlaneBufferGeometry( 2, 2, 1, 1 ), new THREE.MeshBasicMaterial({ map:bgTexture }) );
		this.bgScene.add(quad);

        let loadTexture = new THREE.TextureLoader(); 
        loadTexture.load( './images/background1.png', function ( texture ) {

            texture.wrapS      = THREE.RepeatWrapping;
            texture.wrapT      = THREE.RepeatWrapping;
            texture.minFilter  = THREE.LinearMipMapLinearFilter;
            texture.magFilter  = THREE.LinearFilter;

            texture.minFilter  = THREE.LinearMipMapLinearFilter;
            texture.magFilter  = THREE.LinearFilter;
            texture.anisotropy = 16;    

            bgTexture          = texture;

            quad.material.map  = bgTexture;
            quad.material.needsUpdate = true;
        });    


		this.camera = new THREE.PerspectiveCamera( 70, window.innerWidth / window.innerHeight, .001, 10000);
		this.camera.position.z = -1;
    


		// renderer
        let ratio = window.devicePixelRatio
        if ( ratio > 1 ) ratio = 1;

        ratio *= 0.7;

        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha:false, preserveDrawingBuffer: false, powerPreference:'high-performance' });
        this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.renderer.setPixelRatio( ratio );
        this.renderer.autoClear = false; 

		///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // setup: load Sound FX                       /////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

		this.fxHover = new SoundEffect();
		this.fxHover.init('./audio/hover.ogg', this.camera);
		this.fxClick = new SoundEffect();
		this.fxClick.init('./audio/click.ogg', this.camera);
		this.isHoverFXPlaying = false;

        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // compose: background                          ///////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    	let bgRenderTargetParams = {
      		minFilter: THREE.LinearFilter,
      		magFilter: THREE.LinearFilter,
      		format: THREE.RGBAFormat,
    	};

      	let bgRenderTarget = new THREE.WebGLRenderTarget(
        	window.innerWidth,
        	window.innerHeight,
        	bgRenderTargetParams
      	);

		this.backgroundComposer = new EffectComposer(this.renderer,bgRenderTarget);
        this.backgroundComposer.renderTarget2.texture.format = this.backgroundComposer.renderTarget1.texture.format = THREE.RGBAFormat;		

    	const bgRenderPass = new RenderPass(this.bgScene, this.bgCamera);
    	this.backgroundComposer.addPass(bgRenderPass);

	    this.simpleCopyBgShader = {

	      uniforms: {
	        tDiffuse: { value: null },
	      },

	      vertexShader: [
	        'varying vec2 vUv;',

	        'void main() {',
	        'vUv = uv;',
	        'gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);',
	        '}',
	      ].join('\n'),

	      fragmentShader: [
	        'uniform sampler2D tDiffuse;',
	        'varying vec2 vUv;',

	        'void main() {',

	        'vec4 color = texture2D( tDiffuse, vUv );',

	        'gl_FragColor = color;',

	        '}',
	      ].join('\n'),
	    };

	    this.simpleCopyBgPass = new ShaderPass(this.simpleCopyBgShader);
	    this.backgroundComposer.addPass(this.simpleCopyBgPass);








        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // compose: background.particles                ///////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    	
		this.particleBackground = new ParticleBackground(this,this.tiers);

    	let raysRenderTargetParams = {
      		minFilter: THREE.LinearFilter,
      		magFilter: THREE.LinearFilter,
      		format: THREE.RGBAFormat,
    	};

      	let raysRenderTarget = new THREE.WebGLRenderTarget(
        	window.innerWidth,
        	window.innerHeight,
        	raysRenderTargetParams
      	);

		this.composer = new EffectComposer(this.renderer,raysRenderTarget);
        this.composer.renderTarget2.texture.format = this.composer.renderTarget1.texture.format = THREE.RGBAFormat;		

    	const renderPass = new RenderPass(this.backScene, this.camera);
    	this.composer.addPass(renderPass);

	    this.volumetricLightShader = {
	        vertexShader: [

	            "varying vec2 vUv;",

	            "void main() {",
	                "vUv = uv;",
	                "gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);",
	            "}"

	        ].join( "\n" ),
	        fragmentShader: [

	            "const float SAMPLES = 20.;",
	            "varying vec2 vUv;",
	            "uniform sampler2D tDiffuse;",
	            "uniform sampler2D tDiffuse1;",

	            "void main() {", 

	                "vec2 uv = vUv;",
	                //so we need some coefficients
	                "float weight = 0.08;", //weighting factor to get a weighted of each sample orback to 0.08
	                        
	                "float decay = 0.915;", //used to decrease the weighting fact so each step adds less to the sum,
	                //to model scattering and absortion
	                "float density = 0.7;",//0.3 //used to scale the step size of the samples and step size(or totaal distance)
	                //determines how far in each ray direction calulations and bluring is done for
	                //higher density means longer streaks of light and also more blur

	                "float exposure = 2.1;",  //or back to 1.1
	                //we subtract 0.5 to shift it to -0.5 to +0.5 so 0.0, 0.0 is center of screen
	                //so the blur goes out in all directions
	                "vec2 tuv = uv-0.5;", //also we use a new vec2 because we still need uv for sampling and "jitter"
	                "tuv.y -= 0.50;",
	                "vec2 duv = tuv/SAMPLES*density;",
	    
	                "vec4 initColor = texture2D(tDiffuse, uv.xy);",//portion of total color to start with.
	                //color in this case comes from channel0 which is a buffer that returns the scene color
	    
	                //jitter is a way of reducing banding which is a type of artifact... which we don't want
	                //shane's version uses time to change the jitter which seems like a good idea but I'm
	                //going for simple right now so I'm going with Passions which is just muliplying duv and random
	    
	                //this is added as an offset to uv.  uv which will be used to sample each texture in the alg loop.
	                //so we add a random percentage of duv to uv.
	                //random is key because the issue is banding is you get these weird lines or waves
	                //so to smooth them out you can offset each point by a random amount.
	                "uv+=duv*fract(sin(dot(uv, vec2(12.9898, 78.233)))*43758.5453);",

	                "for(float i=0.;i<SAMPLES;i++)",
	                "{",
	                    
	                 
	                    //for each step move along the ray towards the center(uv - stepsize for all steps
	                    //where step is fraction of uv, will result in 0, 0 the middle
	                    "uv-=duv;",
	                    
	                    //add weighted percentage of scene color
	                    "initColor+=texture2D(tDiffuse, uv)*weight;",
	                    
	                    "weight*=decay;",//decay the weight.
	                    
	                "}",
	    
	                "initColor*=exposure;",
	                "initColor*= (1. - dot(tuv, tuv)*.975);",
	                "gl_FragColor = vec4(initColor.rgb,1.0);",
	            "}"

	        ].join( "\n" ),  
	        uniforms: {
	            tDiffuse: { type: "t", value: 0, texture: null },
	        }
	    };


	    this.volumetricLightPass = new ShaderPass( this.volumetricLightShader );
	    this.composer.addPass(this.volumetricLightPass);

	    this.tiltShiftShader = {

	        uniforms: {
	        	tDiffuse: { type: 't', value: null },
	       		tDiffuse1: { type: "t", value: null },
	       		resolution: { type: 'v2', value: new THREE.Vector2(window.innerWidth * ratio, window.innerHeight * ratio) },
	        },
	        vertexShader: [

	          'varying vec2 vUv;',

	          'void main() {',
	              'vUv = uv;',
	              'gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);',
	          '}'

	        ].join( '\n' ),

	        fragmentShader: [

	            'uniform sampler2D tDiffuse;',
	            'uniform sampler2D tDiffuse1;',
	            'uniform vec2 resolution;',
	            'varying vec2 vUv;',

	            'float normpdf(in float x, in float sigma)',
	            '{',
	                'return 0.39894*exp(-0.5*x*x/(sigma*sigma))/sigma;',
	            '}',


		        'mat4 brightnessMatrix( float brightness ) {',
		        'return mat4( 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, brightness, brightness, brightness, 1 );',
		        '}',

		        'mat4 contrastMatrix( float contrast ) {',
		        'float t = ( 1.0 - contrast ) / 2.0;',

		        'return mat4( contrast, 0, 0, 0, 0, contrast, 0, 0, 0, 0, contrast, 0, t, t, t, 1 );',

		        '}',

		        'mat4 saturationMatrix( float saturation ) {',
		        'vec3 luminance = vec3( 0.3086, 0.6094, 0.0820 );',

		        'float oneMinusSat = 1.0 - saturation;',

		        'vec3 red = vec3( luminance.x * oneMinusSat );',
		        'red+= vec3( saturation, 0, 0 );',

		        'vec3 green = vec3( luminance.y * oneMinusSat );',
		        'green += vec3( 0, saturation, 0 );',

		        'vec3 blue = vec3( luminance.z * oneMinusSat );',
		        'blue += vec3( 0, 0, saturation );',

		        'return mat4( red,     0, green,   0, blue,    0, 0, 0, 0, 1 );',
		        '}',


		        'vec3 uncharted2Tonemap(vec3 x) {',
		        'float A = 0.15;',
		        'float B = 0.50;',
		        'float C = 0.10;',
		        'float D = 0.20;',
		        'float E = 0.02;',
		        'float F = 0.30;',
		        'float W = 11.2;',
		        'return ((x * (A * x + C * B) + D * E) / (x * (A * x + B) + D * F)) - E / F;',
		        '}',

		        'vec3 uncharted2(vec3 color) {',
		        'const float W = 11.2;',
		        'float exposureBias = 9.5;',
		        'vec3 curr = uncharted2Tonemap(exposureBias * color);',
		        'vec3 whiteScale = 1.0 / uncharted2Tonemap(vec3(W));',
		        'return curr * whiteScale;',
		        '}',

		        'float uncharted2Tonemap(float x) {',
		        'float A = 0.15;',
		        'float B = 0.50;',
		        'float C = 0.10;',
		        'float D = 0.20;',
		        'float E = 0.02;',
		        'float F = 0.30;',
		        'float W = 11.2;',
		        'return ((x * (A * x + C * B) + D * E) / (x * (A * x + B) + D * F)) - E / F;',
		        '}',

		        'float uncharted2(float color) {',
		        'const float W = 11.2;',
		        'const float exposureBias = 2.0;',
		        'float curr = uncharted2Tonemap(exposureBias * color);',
		        'float whiteScale = 1.0 / uncharted2Tonemap(W);',
		        'return curr * whiteScale;',
		        '}',


	            'void main() {',

	                'vec4 sum = vec4(0);',
	                // How much the shift is visible.
	                'const float shiftPower = 1.3;',
	                 
	                'vec4 color = vec4(texture2D(tDiffuse, vUv).rgb, 1.0);',
	                "vec4 bg = texture2D(tDiffuse1, vUv);",
	                //declare stuff
	                'const int mSize = 20;',
	                'const int kSize = (mSize-1)/2;',
	                'float kernel[mSize];',
	                'vec3 final_colour = vec3(0.0);',
	                //create the 1-D kernel
	                'float sigma = 7.0;',
	                'float Z = 0.0;',

	                'for (int j = 0; j <= kSize; ++j)',
	                    'kernel[kSize+j] = kernel[kSize-j] = normpdf(float(j), sigma);',

	                //get the normalization factor (as the gaussian has been clamped)
	                'for (int j = 0; j < mSize; ++j)',
	                    'Z += kernel[j];',
	                
	                //read out the texels
	                'for (int i=-kSize; i <= kSize; ++i)',
	                '{',
	                    'for (int j=-kSize; j <= kSize; ++j)',
	                        'final_colour += kernel[kSize+j]*kernel[kSize+i]*texture2D(tDiffuse, (gl_FragCoord.xy+vec2(float(i),float(j))) / resolution.xy).rgb;',
	                '}',

	                'float val = clamp(shiftPower * abs(resolution.y/2.0 - gl_FragCoord.y) / (resolution.y/2.0), 0.0, 1.0);',
	                'vec4 preColour = vec4(final_colour/(Z*Z), 1.0) * val + color * (1.0 - val);',
	                'preColour *= contrastMatrix( 1.5 );',

	                'gl_FragColor = vec4(bg.rgb + preColour.rgb, 1.0);',



	          '}'

	        ].join( '\n' ) 
	    };
	    this.tiltShiftShader.uniforms['tDiffuse1'].value = this.backgroundComposer.renderTarget2;
	    this.tiltShiftPass = new ShaderPass( this.tiltShiftShader );

	    this.composer.addPass(this.tiltShiftPass);




        // clock
		this.clock = new THREE.Clock(true);

		// orbit-controls
    	this.controls  = new OrbitControls( this.camera, this.renderer.domElement );
    	this.controls.autoRotate = true;
    	this.controls.autoRotateSpeed = 0.3;
    	this.controls.rotateSpeed *= -1;
    	this.controls.dIn(.05);

        this.phi   = 0;
        this.theta = 0;



        //////////////////////////////////////////////////////////////////////////////////////////////////////
        // set: survey.particle(s) base.object
        ////////////////////////////////////////////////////////////////////////////////////////////////////// 

        this.markerContainer = new THREE.Object3D();
        this.scene.add( this.markerContainer );



        //////////////////////////////////////////////////////////////////////////////////////////////////////
        // set: audio.reactive.circle
        ////////////////////////////////////////////////////////////////////////////////////////////////////// 

        this.circleContainer = new THREE.Object3D();


	    this.lineVector = [];
	    this.baseVector = [];

	    let segmentCount = 64;
	    let radius = 0.25;

		for (var i = 0; i <= segmentCount; i++) {
    		 var theta = (i / segmentCount) * Math.PI * 2;
    		this.lineVector.push(
		        new THREE.Vector3(
		            Math.cos(theta) * radius,
		            Math.sin(theta) * radius,
		            0)
        	);  

        	this.baseVector.push(
		        new THREE.Vector3(
		            Math.cos(theta) * radius,
		            Math.sin(theta) * radius,
		            0)
        	);            
		}

		this.lineVector.push( this.lineVector[0] );
		this.baseVector.push( this.baseVector[0] );

    	const linePoints = new THREE.CatmullRomCurve3(this.lineVector).getPoints( 50 );

	    const mline = new MeshLine();
	    mline.setVertices(linePoints);

	    const mlmaterial = new MeshLineMaterial({
	      map:new THREE.TextureLoader().load('./images/line.png'),
	      useMap:true,
	      transparent: true,
	      lineWidth: 0.0015,
	      sizeAttenuation: true,
	      opacity: 1.0
	    });

	    const mlmaterial1 = new MeshLineMaterial({
	      map:new THREE.TextureLoader().load('./images/line1.png'),
	      useMap:true,
	      transparent: true,
	      lineWidth: 0.0015,
	      sizeAttenuation: true,
	      opacity: 1.0
	    });	    

	    const mlmaterial2 = new MeshLineMaterial({
	      map:new THREE.TextureLoader().load('./images/line2.png'),
	      useMap:true,
	      transparent: true,
	      lineWidth: 0.0015,
	      sizeAttenuation: true,
	      opacity: 1.0
	    });	

	    const mlmaterial3 = new MeshLineMaterial({
	      map:new THREE.TextureLoader().load('./images/line3.png'),
	      useMap:true,
	      transparent: true,
	      lineWidth: 0.0015,
	      sizeAttenuation: true,
	      opacity: 1.0
	    });		    

	    this.lineMesh  = new THREE.Mesh(mline, mlmaterial);
	    this.lineMesh1 = new THREE.Mesh(mline, mlmaterial1);
	    this.lineMesh2 = new THREE.Mesh(mline, mlmaterial2);
	    this.lineMesh3 = new THREE.Mesh(mline, mlmaterial3);
	    
	    this.lineMesh.visible  = false;
	    this.lineMesh.material.opacity = 0.0;
	    this.lineMesh1.visible = false;
	    this.lineMesh1.material.opacity = 0.0;
	    this.lineMesh1.rotation.z = Math.random() * Math.PI;
	    this.lineMesh1.scale.set( 1.05, 1.05, 1.05 );
	    this.lineMesh2.visible = false;
	    this.lineMesh2.rotation.z = Math.random() * Math.PI;
	    this.lineMesh2.scale.set( 1.02, 1.02, 1.02 );
	    this.lineMesh2.material.opacity = 0.0;
	    this.lineMesh3.visible = false;
	    this.lineMesh3.rotation.z = Math.random() * Math.PI;
	    this.lineMesh3.scale.set( 0.95, 0.95, 0.95 );	    
	    this.lineMesh3.material.opacity = 0.0;
	    
	    this.circleContainer.add(this.lineMesh);
	    this.circleContainer.add(this.lineMesh1);
	    this.circleContainer.add(this.lineMesh2);
	    this.circleContainer.add(this.lineMesh3);
        
        this.scene.add( this.circleContainer );



        //////////////////////////////////////////////////////////////////////////////////////////////////////
        // set: raycast clicks on surveys
        ////////////////////////////////////////////////////////////////////////////////////////////////////// 

	    this.mouse = new THREE.Vector2();
	    this.raycaster = new THREE.Raycaster();
	    this.selectedSurvey = null;
	    this.previousSurvey = null;


	    let skipPointerMove = false;

	    if( _self.tiers.isMobile ) {

	        skipPointerMove = true;

	        this.renderer.domElement.addEventListener( 'touchstart', event => {

				if(_self.isSuspended || !_self.isLaunched) { return };

		        let touchobj = event.changedTouches[0];

			    if ( _self.previousSurvey )  {
		 			 if( !_self.previousSurvey.visible ) return;
			    }	

			    if( _self.filtering ) return;
			    if( _self.contentOpened ) return;

			    if( this.audio ) {

			      	if( !this.audio.close ) return;

			    }   

	      		checkIntersection(parseInt(touchobj.clientX), parseInt(touchobj.clientY));
	        
	        });

	    }

	    if( !skipPointerMove ) {

		    window.addEventListener('pointerdown', event => {

				if(_self.isSuspended || !_self.isLaunched) { return };
		      
		      if ( _self.previousSurvey )  {
	 			   if( !_self.previousSurvey.visible ) return;
		      }

		      if( _self.filtering ) return;
		      if( _self.contentOpened ) return;


		      _self._zoomToParticle( _self );


		      this.mouseIsDown = true;

		    });

		    window.addEventListener('pointermove', event => {

				if(_self.isSuspended || !_self.isLaunched) { return };
				
		      if ( _self.previousSurvey )  {
	 			   if( !_self.previousSurvey.visible ) return;
		      }	

		      if( _self.filtering ) return;
		      if( _self.contentOpened ) return;

		      if( this.audio ) {

		      	  if( !this.audio.close ) return;

		      }   

		      checkIntersection(event.clientX, event.clientY);

		    });

	    }


	    function checkIntersection(x, y) {

	      _self.mouse.x = (x / window.innerWidth) * 2 - 1;
	      _self.mouse.y = -(y / window.innerHeight) * 2 + 1;

	      _self.raycaster.setFromCamera(_self.mouse, _self.camera);

		  const intersects = _self.raycaster.intersectObject( _self.markerContainer, true );

		  if ( intersects.length > 0 ) {

				const res = intersects.filter( function ( res ) {

					return res && res.object;

				} )[ 0 ];

				if ( res && res.object && res.object.name !== "deco" ) {

					if( res.object.parent.userData.btnType === 1 ) return;
					_self.selectedSurvey = res.object;

					if( _self.selectedSurvey.parent ) {

             			let vec = new THREE.Vector3();
             			vec.setFromMatrixPosition( _self.selectedSurvey.parent.matrixWorld );

            			let distance_ = vec.distanceTo( _self.controls.target );

						if( !_self.tiers.isMobile ) {	
							
							TweenMax.to( _self.selectedSurvey.parent.scale, 0.5, { x:1.5, y:1.5, z:1.5, ease:Sine.easeOut });
							_self.renderer.domElement.style.cursor = "pointer";
							
							if(!_self.isHoverFXPlaying){
								_self.fxHover.audio.play();
							}
							_self.isHoverFXPlaying = true;
							document.dispatchEvent(new CustomEvent('grace_request_particle_mouse_over', {
								detail: {
								  id:_self.selectedSurvey.parent.userData.id,
								  position:_self.toScreenPosition( _self.selectedSurvey.parent, _self.camera, _self.renderer ),
								  distance:distance_
								}	

							} ) );

							//_self.controls.autoRotate = false;

							document.dispatchEvent(new CustomEvent('grace_request_particle_mouse_move', {
								detail: {
								  id:_self.selectedSurvey.parent.userData.id,	
								  position:_self.toScreenPosition( _self.selectedSurvey.parent, _self.camera, _self.renderer ),
								  distance:distance_
								}							
					  		}));	

						} else {

							//if( skipPointerMove ) {

								_self._zoomToParticle( _self );
							//	return;
							//}

						}

					}

				}

		  } else {

			_self.isHoverFXPlaying = false;
		  	if( _self.selectedSurvey ) {
			  	
			  	if( _self.selectedSurvey.parent ) {

			  		//TweenMax.to( _self.selectedSurvey.parent.scale, 0.5, { x:1, y:1, z:1, ease:Sine.easeOut, onComplete:function() { _self.selectedSurvey = null ; } } );
			  		_self.renderer.domElement.style.cursor = "";


			  		document.dispatchEvent(new CustomEvent('grace_request_particle_mouse_out', {

						detail: {
						  id:_self.selectedSurvey.parent.userData.id,	
						  position:_self.toScreenPosition( _self.selectedSurvey.parent, _self.camera, _self.renderer )
						}	

			  		} ) );

			  		_self.selectedSurvey = null;
			  		//_self.controls.autoRotate = true;

			  	}
		  	
		  	}

    		for( let i=0; i < _self.markerContainer.children.length; i++ ) {

    			 TweenMax.to( _self.markerContainer.children[i].scale, 0.5, { x:1, y:1, z:1, ease:Sine.easeOut, onComplete:function() { _self.selectedSurvey = null ; } } );

    		}

		  }

	    }

		//////////////////////////////////////////////////////////////////////////////////////////////////////
        // set: document even handlers
        //////////////////////////////////////////////////////////////////////////////////////////////////////
		document.addEventListener('grace_request_close_explorer_detail', event => {

		  _self.contentOpened = false;

		  for( let i=0; i < _self.markerContainer.children.length; i++ ) {

		  	   _self.markerContainer.children[i].visible = true;
		  	   TweenMax.to( _self.markerContainer.children[i].children[0].material, 1.0, { opacity:1.0, delay:1.0, ease:Expo.easeOut } );

		  }

		  _self.audio.close = true;
		  _self.audio.end( _self.circleContainer );

		  TweenMax.to(_self.controls.target, 2.0, {
	          x: _self.originMarker.position.x,
	          y: _self.originMarker.position.y,
	          z: _self.originMarker.position.z,
	          ease: Expo.easeInOut,
	          delay:0.5,
	          onUpdate:function() {
	          	  _self.controls.dOut(.05);
	          }
		  } ); 

		});


		document.addEventListener('grace_request_show_previous_explorer_detail', event => {

			_self.contentOpened = false;

			for( let i=0; i < _self.markerContainer.children.length; i++ ) {

		  	    _self.markerContainer.children[i].visible = true;
		  	    TweenMax.to( _self.markerContainer.children[i].children[0].material, 1.0, { opacity:1.0, delay:1.0, ease:Expo.easeOut } );

			}

			_self.audio.close = true;
			_self.audio.end( _self.circleContainer );

			let getIndex = -1;

			for( let i=0; i < _self.surveyData.length; i++ ) {

				 if( _self.surveyData[i].id === _self.previousSurvey.parent.userData.id ) {

				 	 if( _self.previousSurvey.parent.userData.btnType !== 1  ) {

				 	 	 getIndex = i;
				 	 	 break;
				 	 
				 	 }

				 }

			}

			if( getIndex === 0 ) {

				getIndex = _self.surveyData.length-1;

			}

			for( i=0; i < _self.markerContainer.children.length; i++ ) {

				 if( _self.markerContainer.children[i].userData.id === _self.surveyData[(getIndex+1) ].id ) {

					 
					 setTimeout(() => {
					 	_self.selectedSurvey = _self.markerContainer.children[i].children[0];
					 	_self._zoomToParticle( _self );
					 }, 2000 );

				 	 break;
				 }
			}
		
		});

		document.addEventListener('grace_request_show_random_explorer_detail', event => {

			_self.contentOpened = false;

			for( let i=0; i < _self.markerContainer.children.length; i++ ) {

		  	    _self.markerContainer.children[i].visible = true;
		  	    TweenMax.to( _self.markerContainer.children[i].children[0].material, 1.0, { opacity:1.0, delay:1.0, ease:Expo.easeOut } );

			}

			_self.audio.close = true;
			_self.audio.end( _self.circleContainer );

			let getIndex  = -1;
			let rndSurvey = [];

			for( let i=0; i < _self.surveyData.length; i++ ) {

			 	 if( _self.surveyData[i].type !== 1  ) {

			 	 	 rndSurvey.push( _self.surveyData[i] );
			 	 
			 	 }

			}


			function shuffle(a) {
			    for (let i = a.length - 1; i > 0; i--) {
			        const j = Math.floor(Math.random() * (i + 1));
			        [a[i], a[j]] = [a[j], a[i]];
			    }
			    return a;
			}


			shuffle(rndSurvey);


			let rnd = parseInt(Math.random() * (rndSurvey.length));

			for( i=0; i < _self.markerContainer.children.length; i++ ) {

				 if( _self.markerContainer.children[i].userData.id === rndSurvey[rnd].id ) {

					 setTimeout(() => {
					 	_self.selectedSurvey = _self.markerContainer.children[i].children[0];
					 	_self._zoomToParticle( _self );
					 }, 2000 );

				 	 break;
				 }
			}


		});

		document.addEventListener('grace_request_show_next_explorer_detail', event => {

			_self.contentOpened = false;

			for( let i=0; i < _self.markerContainer.children.length; i++ ) {

		  	    _self.markerContainer.children[i].visible = true;
		  	    TweenMax.to( _self.markerContainer.children[i].children[0].material, 1.0, { opacity:1.0, delay:1.0, ease:Expo.easeOut } );

			}

			_self.audio.close = true;
			_self.audio.end( _self.circleContainer );

			let getIndex = -1;

			for( let i=0; i < _self.surveyData.length; i++ ) {

				 if( _self.surveyData[i].id === _self.previousSurvey.parent.userData.id ) {

				 	 if( _self.previousSurvey.parent.userData.btnType !== 1  ) {

				 	 	 getIndex = i;
				 	 	 break;
				 	 
				 	 }

				 }

			}

			if( getIndex === ( _self.surveyData.length-1 ) ) {

				getIndex = 0;

			}

			for( i=0; i < _self.markerContainer.children.length; i++ ) {

				 if( _self.markerContainer.children[i].userData.id === _self.surveyData[(getIndex+1) ].id ) {

					 setTimeout(() => {
					 	_self.selectedSurvey = _self.markerContainer.children[i].children[0];
					 	_self._zoomToParticle( _self );
					 }, 2000 );

				 	 break;
				 }
			}


		});


		document.addEventListener('grace_request_filter_open', event => {

			_self.filtering = true;

		});

		document.addEventListener('grace_request_filter_closed', event => {

			_self.fromFilter = true;
			_self.filtering  = false;

		});	

		document.addEventListener('grace_request_deeplink_launch', event => {

			if( _self.deeplink ) {
				_self.selectedSurvey = _self.deeplinkTarget;
				_self._zoomToParticle( _self );
			}
		});	

		document.addEventListener('grace_request_start_launching', event => {

			_self.isLaunched = true;
		});	
		
		

		let initFocus     = 1;
        let loadTexture_  = new THREE.TextureLoader(); 
        
        loadTexture_.load( './images/audioMarker2.png', function ( texture ) {

            texture.anisotropy = 16;    
            _self.audioTexture = texture;

            let loadTexture1  = new THREE.TextureLoader();
			loadTexture1.load( './images/textMarker1.png', function ( texture ) {

			            texture.anisotropy = 16;    
			            _self.textTexture = texture;

						setTimeout(() => {
							document.addEventListener('grace_request_data_response', event => {

								if( !_self.fromFilter ) {

									_self._createInitialPos(  -30 + Math.random() * 60, -180 + Math.random() *360 );

									event.detail.forEach( (index) => {

										_self.surveyData.push( index );
										_self._createMarker( -30 + Math.random() * 60, -180 + Math.random() *360, index, initFocus, 0 );

										initFocus = 0;

									});

									_self._checkMarkersAmount();
									_self._addDecoParticles();

									document.dispatchEvent( new CustomEvent("grace_request_explorer_loaded", {} ));


									// if( _self.deeplink ) {

									// 	_self.selectedSurvey = _self.deeplinkTarget;
									// 	_self._zoomToParticle( _self );
									
									// }
								} else {

									for( i = _self.markerContainer.children.length - 1; i >= 0; i-- ) {

										 let obj = _self.markerContainer.children[i];
										 TweenMax.to( obj.children[0].material, 1.5, { opacity:1.0, ease:Expo.easeOut } );

										 let nx = 0;
										 let ny = 0;
										 let nz = 0;

										 if( obj.position.x < 0 ) { 

										 	 nx -= 10;

										 } else {

										 	 nx += 10;
										 }


										 if( obj.position.y < 0 ) { 

										 	 ny -= 10;

										 } else {

										 	 ny += 10;
										 }

										 if( obj.position.z < 0 ) { 

										 	 nz -= 10;

										 } else {

										 	 nz += 10;
										 }

										 TweenMax.to( obj.position, 1.4, { x:obj.position.x * 8, y: obj.position.y * 8, z: obj.position.z * 8, ease:Sine.easeInOut, delay:0.1, onComplete:function() { _self.markerContainer.remove( obj ); } } );

									}

									let count = 0
									
									event.detail.forEach( (index) => {

										count++;

									});

									event.detail.forEach( (index) => {

										_self.surveyData.push( index );
										_self._createMarker( -30 + Math.random() * 60, -180 + Math.random() *360, index, initFocus, count );

										initFocus = 0;

									});

									_self._checkMarkersAmount(true);
									_self._addDecoParticles();

									if( count === 1 ) {

										setTimeout(() => {
										  _self.selectedSurvey = _self.markerContainer.children[0].children[0];
										  _self._zoomToParticle( _self );
										}, 2000 );

									}
								}

							} );
							document.dispatchEvent( new CustomEvent("grace_request_explorer_ready", {} ));
						}, 5000 );

			});

        });

	}

    

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // setup: markers/buttons in 3d-space (lat,lon to correspont easier with orbit-control´s logic)     ///////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	_createInitialPos( lat, lon ) {

        let surveyPnt = new THREE.Object3D();

        let lonRad = -lon * (Math.PI / 180);
        let latRad = lat * (Math.PI / 180);
        let r = 0.2;


        surveyPnt.position.set(Math.cos(latRad) * Math.cos(lonRad) * r, Math.sin(latRad) * r, Math.cos(latRad) * Math.sin(lonRad) * r);

        let spriteMaterial, spriteTexture;
		spriteMaterial = new THREE.SpriteMaterial( { color:0xffff00, opacity:0 } );
        
        
        var sprite = new THREE.Sprite( spriteMaterial );

        sprite.scale.set( 0.22, 0.22, 1 ); 

        this.scene.add( surveyPnt );

        surveyPnt.add( sprite );

        this.originMarker = surveyPnt;
  
    }


	_createMarker( lat, lon, data, focus, filterResults ) {

        let surveyPnt = new THREE.Object3D();

        let lonRad = -lon * (Math.PI / 180);
        let latRad = lat * (Math.PI / 180);
        let r = 1.0 + Math.random() * 3;

        if( (filterResults > 0 && filterResults < 30) || data.imageURL ) r = 1.0 + Math.random() * 1.0;

        surveyPnt.position.set(Math.cos(latRad) * Math.cos(lonRad) * r, Math.sin(latRad) * r, Math.cos(latRad) * Math.sin(lonRad) * r);

        let spriteMaterial, spriteTexture;

       	if( data.type === 0 ) {
       		
       		spriteTexture = new Spritesheet();
       		
       		if( data.imageURL ) {
       			
       			const map_     = new THREE.TextureLoader().load( data.imageURL );
				spriteMaterial = new THREE.SpriteMaterial( { map:map_, transparent:true, opacity:1.0, fog:true } );
       		
       		} else {
				
				spriteTexture.init( this.audioTexture, 4, 3, 75, 4 );
				spriteMaterial = new THREE.SpriteMaterial( { map:spriteTexture.canvasTexture, transparent:true, opacity:1.0, fog:true } );
       		
       		}

        } else if( data.type === 2) {

       		spriteTexture = new Spritesheet();

       		if( data.imageURL ) {
       			
       			const map_     = new THREE.TextureLoader().load( data.imageURL );
				spriteMaterial = new THREE.SpriteMaterial( { map:map_, transparent:true, opacity:1.0, fog:true } );
       		
       		} else {
				
				spriteTexture.init( this.textTexture, 4, 3, 75, 4 );   
				spriteMaterial = new THREE.SpriteMaterial( { map:spriteTexture.canvasTexture, transparent:true, opacity:1.0, fog:true } );
       		
       		}

        } else {
        	
        	spriteMaterial = new THREE.SpriteMaterial( { map: new THREE.TextureLoader().load('./images/deadMarker.png'), transparent:true, opacity:0.3 + Math.random() * 0.5, fog:true } );
        
        }
        
        var sprite = new THREE.Sprite( spriteMaterial );

        sprite.scale.set( 0.22, 0.22, 1 ); 

        surveyPnt.userData = { id:data.id, x:surveyPnt.position.x, y:surveyPnt.position.y, z:surveyPnt.position.z, texture:spriteTexture, btnType:data.type, audioUrl:data.audioUrl, blur:false };

        if( spriteTexture ) {

        	if( !data.imageURL ) spriteTexture._addRoot( sprite, this );

        }  


        if( data.refName ) {

        	sprite.name = data.refName;

        }
        

        if( filterResults > 0 && filterResults > 1 ) {

        	spriteMaterial.opacity = 0.0;
        	TweenMax.to( spriteMaterial, 1.4, { opacity:1.0, ease:Sine.easeOut } );

        }

        this.markerContainer.add( surveyPnt );

        surveyPnt.add( sprite );

        if( data.deeplink ) {

        	this.deeplinkTarget = sprite;
        	this.deeplink       = true;

        }

    }



    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // check: amount of survey´s available, if too less ...duplicate/multiply current buttons with factor-n       /////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    _checkMarkersAmount(filter) {

    	let additionalParticles = [];

    	if( !filter ) {

	    	if( this.markerContainer.children.length <= 30 ) {

	    		for( let i=0; i < this.markerContainer.children.length; i++ ) {

	    			 let getParticle = this.markerContainer.children[i];
	    			 if( getParticle.userData.btnType === 0 || getParticle.userData.btnType === 2 ) {

	    			 	 for( let j=0; j < 2; j++ ) {

	    			 	 	  let data = { id:getParticle.userData.id, type:getParticle.userData.btnType, audioUrl:getParticle.userData.audioUrl };

	    			 	 	  additionalParticles.push(data);

	    			 	 }
	    			 
	    			 }

	    		}

	    	} 

    	}
		// else if ( this.markerContainer.children.length > 10 && this.markerContainer.children.length <= 20  ) {

    	// 	for( let i=0; i < this.markerContainer.children.length; i++ ) {

    	// 		 let getParticle = this.markerContainer.children[i];
    	// 		 if( getParticle.userData.btnType === 0 || getParticle.userData.btnType === 2) {

    	// 		 	 for( let j=0; j < 5; j++ ) {

    	// 		 	 	  let data = { id:getParticle.userData.id, type:getParticle.userData.btnType, audioUrl:getParticle.userData.audioUrl };

    	// 		 	 	  additionalParticles.push(data);

    	// 		 	 }
    			 
    	// 		 }

    	// 	}

    	// }
    	
    	for( let i=0; i < additionalParticles.length; i++ ) {
 			
 		     this._createMarker( -30 + Math.random() * 60, -180 + Math.random() *360, additionalParticles[i] );
    	
    	}

    }


    _addDecoParticles() {
    	
    	for( let i=0; i < 50; i++ ) {
 			
 		     this._createMarker( -30 + Math.random() * 60, -180 + Math.random() *360, { id:"", type:1, audioUrl:"", refName:"deco" } );
    	
    	}

    }




    _zoomToParticle( _self ) {

	  if( !_self.audio ) {
	  	  _self.audio = new Audio();
	  	  _self.audio.init(_self.circleContainer, _self.tiers);
	  }

	  
      if( _self.selectedSurvey ) {
		  
		  _self.contentOpened = true;

		  // play the click audio
		  _self.fxClick.audio.play();

      	  let survey = _self.selectedSurvey;

	  	  for( let i=0; i < _self.markerContainer.children.length; i++ ) {

		  	   TweenMax.to( _self.markerContainer.children[i].children[0].material, 1.0, { opacity:0.0, delay:0.6, ease:Sine.easeOut, onComplete:function() { _self.markerContainer.children[i].visible = false; } } );

		  }

      	  TweenMax.to( survey.material, 1.0, {
      	  	  
      	  	  //opacity:0.0,
      	  	  //delay:1.3,
      	  	  //ease: Expo.easeOut

      	  } );

		document.dispatchEvent(new CustomEvent('grace_request_view_explorer_data_is_selected'));

		  TweenMax.to(_self.controls.target, 2.0, {
	          x: _self.selectedSurvey.parent.position.x,
	          y: _self.selectedSurvey.parent.position.y,
	          z: _self.selectedSurvey.parent.position.z,
	          ease: Expo.easeInOut,
	          onUpdate:function() {
	        	  _self.controls.dIn(.05);
	          },
	          onComplete:function() {
	        	  _self.previousSurvey = survey;
				  
	        	  if( survey.parent.userData.btnType === 0 ) {
				      _self.audio.currentObj = survey.parent;
				      _self.audio.noSound = false;
				      _self.audio.setup();
				      _self.audio.close = false;
				  } else {
				  	  _self.audio.noSound = true;
				  }

				  if( _self.tiers.isMobile ) {
				      document.dispatchEvent(new CustomEvent('grace_request_audio_circle_position', {
				        detail: {
				          position:_self.toScreenPosition( _self.circleContainer, _self.camera, _self.renderer )
				        }
				      }));
			      }

				  document.dispatchEvent(new CustomEvent('grace_request_view_explorer_data', {
					detail: {
					  id: survey.parent.userData.id,
					}
				  }));

	        	  _self.previousSurvey = survey;
	        	  _self.selectedSurvey = null;
	          }
		  } ); 	
      }

    }

	toScreenPosition(obj, camera, renderer) {
	  
	  const vector = new THREE.Vector3();

	  const widthHalf = 0.5 * renderer.getContext().canvas.width;
	  const heightHalf = 0.5 * renderer.getContext().canvas.height;

	  obj.updateMatrixWorld();
	  vector.setFromMatrixPosition(obj.matrixWorld);
	  vector.project(camera);

	  vector.x = vector.x * widthHalf + widthHalf;
	  vector.y = -(vector.y * heightHalf) + heightHalf;

	  return {
	    x: vector.x,
	    y: vector.y,
	    ratio:renderer.getPixelRatio()
	  };

	};

	// ---------------------------------------------------------------------------------------------
	// PUBLIC
	// ---------------------------------------------------------------------------------------------

	update() {

		const delta = this.clock.getDelta();

		this.particleBackground.update();
		this.controls.update();
		
		if( this.selectedSurvey && !this.tiers.isMobile ) {

			if( this.selectedSurvey.parent ) {

     			let vec = new THREE.Vector3();
     			vec.setFromMatrixPosition( this.selectedSurvey.parent.matrixWorld );

    			let distance_ = vec.distanceTo( this.controls.target );

				// TODO: find out why this is sending both over and move events on every frame
				// I think we have these events being dispatched somewhere else too

				document.dispatchEvent(new CustomEvent('grace_request_particle_mouse_over', {
					detail: {
					  id:this.selectedSurvey.parent.userData.id,
					  position:this.toScreenPosition( this.selectedSurvey.parent, this.camera, this.renderer ),
					  distance:distance_
					}	

				} ) );

				//_self.controls.autoRotate = false;

				document.dispatchEvent(new CustomEvent('grace_request_particle_mouse_move', {
					detail: {
					  id:this.selectedSurvey.parent.userData.id,
					  position:this.toScreenPosition( this.selectedSurvey.parent, this.camera, this.renderer ),
					  distance:distance_
					}							
		  		}));	
			}

		}

		if( this.audio ) {

			if( !this.audio.close ) this.audio.update( this.circleContainer, this.lineVector, this.baseVector );

		}
		this.circleContainer.quaternion.copy( this.camera.quaternion );

	}

	draw() {

		this.particleBackground.update();
		
		this.renderer.clear();
		this.backgroundComposer.render();
		this.composer.render();
		this.renderer.clearDepth();
		this.renderer.render(this.scene, this.camera);
        
	}

	// ---------------------------------------------------------------------------------------------
	// EVENT HANDLERS
	// ---------------------------------------------------------------------------------------------

	resize() {
		if (!this.renderer) return;
		
		this.camera.aspect = window.innerWidth / window.innerHeight;
		this.camera.updateProjectionMatrix();
		this.fovHeight = 2 * Math.tan((this.camera.fov * Math.PI) / 180 / 2) * this.camera.position.z;
		this.renderer.setSize(window.innerWidth, window.innerHeight);
		this.composer.setSize(window.innerWidth, window.innerHeight);
		this.backgroundComposer.setSize(window.innerWidth, window.innerHeight);
	}
}
