import React, { memo } from 'react';
import { ThinkerData } from '../../constants/types';
import Button from '../button';
import styles from './thinker.module.scss';
import cloneDeep from 'lodash.clonedeep';

interface ThinkerProps {
  data: ThinkerData,
  setVoice: (value: string) => void,
  setDeeplink: (value: string | null) => void,
  setThinker: (value: ThinkerData) => void,
}

export const Thinker: React.FC<ThinkerProps> = memo(({
  data,
  setVoice,
  setDeeplink,
  setThinker,
}) => {
  const { avatar, firstName, lastName, cta, deeplink, voice } = data;

  const clickHandler = () => {
    // console.log(`Click on Thinker: ${data.firstName}`);
    // Create a clone of the data so the hook always gets a new object
    // This is for when the user clicks on the same Thinker twice.
    const newData = cloneDeep(data);
    setThinker(newData);
  }

  return (
    <div className={styles.thinker}>
      <img onClick={clickHandler} src={avatar} alt={firstName}/>
      <div className={styles.text}>
        {`${firstName} ${lastName}`}
      </div>
      {
        deeplink != null ?
      <Button
        onClick={() => setDeeplink(deeplink)}
        className={styles.voiceButton}
      >
        <>
        {`${cta}`}
        <div className={styles.hrefLine}/>
        </>
      </Button>
      : null
      }
    </div>
  )
});