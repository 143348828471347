import React, { useEffect, useState } from 'react';
import { SelectProps } from '../../constants/types';
import styles from './questionsType.module.scss';
import classnames from 'classnames';
import Button from '../button';
import { ConfirmButton } from './confirmButtons';
import cloneDeep from 'lodash.clonedeep';
import firebaseService from '../../firebase/firebase';
import { closeQuestion } from '../../helpers/util';

let timer;

export const RadioQuestion: React.FC<SelectProps> = ({
  question,
  setAnswer,
  answer,
  setQuestionComplete,
  currentQuestion,
  isMobile,
  setModal,
  mobileClass,
}) => {
  const [isComplete, setIsComplete] = useState(false);
  const [isOpacity, setIsOpacity] = useState(false);
  const { Confirm } = question;

  const handleSetAnswer = (value: string, number: number) => {
    if (answer) {
      const newAnswer = cloneDeep(answer);
      newAnswer.data.answer[number] = value;
      setAnswer(newAnswer);
    }
  }

  const handleConfirm = () => {
    if (Confirm && setModal) {
      setIsComplete(true);
      setModal(
        <Confirm
          title={`I just want to be seen for my potential instead of my appearance.`}
          name={`- Grace`}
          setQuestionComplete={setQuestionComplete}
        />
      )
    } else {
      setIsComplete(true);
      closeQuestion(timer, setQuestionComplete);
    }
  };

  const handleSkip = () => {
    firebaseService.handleSkipEvent({"question": currentQuestion});
    setIsComplete(true);
    closeQuestion(timer, setQuestionComplete);
  };

  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    timer = setTimeout(() => setIsOpacity(true), 100);
  }, []);

  useEffect(() => {
    if(!answer) {
      const newAnswer = {};
      for (let i = 0; i < question.data.attributes.length; i++) {
        newAnswer[i] = question.data.attributes[i][0];
      }
      setAnswer({
        id: question.id,
        data: {
          answer: newAnswer,
          comment: '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, answer]);

  return (
    <>
    <div className={isComplete ? classnames(styles.typeQuestion, styles.typeConfirm, mobileClass(styles)) : classnames(styles.typeQuestion, isOpacity ? styles.typeAnimation : '', mobileClass(styles))}>
      {question && (
        <>
          <div className={styles.question_title}>
            {
            question.data.question.map((q, index) => (
              <div key={index} className={styles.inner_title}>
                {q}
              </div>
            ))
            }
          </div>
          <div className={styles.radio}>
            {
              question.data.attributes.map((attr, index) => (
                <div className={styles.radio_wrapper} key={index}>
                  <div className={styles.radio_container}>
                  <Button
                    className={answer && answer.data.answer[index] === attr[0]
                      ? classnames(styles.radioButton, styles.radioButton_active) : styles.radioButton}
                    onClick={() => handleSetAnswer(attr[0], index)}
                    buttonStyles={styles.radioButtonContainer}
                  />
                  <div className={styles.radio_content}>
                    {attr[0]}
                  </div>
                  </div>
                  <div className={styles.radio_container}>
                  <Button 
                    className={answer && answer.data.answer[index] === attr[1]
                    ? classnames(styles.radioButton, styles.radioButton_active) : styles.radioButton}
                    onClick={() => handleSetAnswer(attr[1], index)}
                    buttonStyles={styles.radioButtonContainer}
                  />
                  <div className={styles.radio_content}>
                    {attr[1]}
                  </div>
                  </div>
                </div>
              ))
            }
          </div>
          {
            question && question.data.under 
            ? (
              <div className={classnames(styles.title, styles.title_under)}>
                {
                question.data.under.map((q, index) => (
                  <div key={index} className={styles.inner_title}>
                    {q}
                  </div>
                ))
                }
              </div>
            )
            : null
          }
        </>
      )}
      <ConfirmButton
        className={isComplete ? styles.radio_confirm : ''}
        isComplete={true}
        isConfirm={!Confirm}
        handleConfirm={handleConfirm}
        handleSkip={handleSkip}
        hideConfirm={!answer?.data.answer && !!Confirm}
        isMobile={isMobile}
        disabled={isComplete}
        currentQuestion={currentQuestion}
      />
    </div>
    </>
  )
}