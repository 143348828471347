import React, { memo, useState, useEffect } from 'react';
import styles from './footer.module.scss';
import { SocialButtons } from './socialButtons';
import { Audio } from '../audio';
import Button, { FilterVoices } from '../button';
import { FeaturedThinkersImage } from './featuredThinkersImage';
import { ThinkerData } from '../../constants/types';
import { Filter } from '../svgIcons';
import classnames from 'classnames';
import firebaseService from '../../firebase/firebase';

interface ExploreFooterProps {
  modalOpen: boolean,
  playAudio: boolean,
  setPlayAudio: () => void,
  isMobile: boolean,
  thinkers: ThinkerData[],
  active: boolean,
  openFilter: () => void,
  isFilter: boolean,
  isShowFilterButton: boolean,
}

export const ExploreFooter: React.FC<ExploreFooterProps> = memo(({
  modalOpen,
  playAudio,
  setPlayAudio,
  isMobile,
  thinkers,
  active,
  openFilter,
  isFilter,
  isShowFilterButton,
}) => {

  const [surveyTotalCount, setSurveyTotalCount] = useState(0);

  useEffect(() => {
    firebaseService.getTotalSurveyDocuments().then(result => {
      setSurveyTotalCount(result);
    }).catch(error => {
      setSurveyTotalCount(0);
    })
  });

  return (
    <div className={styles.exploreFooterContainer}>
      {
        !isMobile
        ? (
          <>
            <div className={styles.exploreFooter_thinkers}>
              {/* <FeaturedThinkersImage 
                thinkers={thinkers}
              /> */}
            </div>
            {
              isMobile
              ? null
              : (
                <>
                { 
                isShowFilterButton ?
                <div className={classnames(styles.exploreFooter_voices, isFilter || active ? styles.exploreFooter_closeFilter : '')}>
                  <FilterVoices
                    onClick={openFilter}
                  />
                  { surveyTotalCount >= 200 ?
                  <div className={styles.voices_description}>
                    total number of mentors:
                    <p>{surveyTotalCount}</p>
                  </div>
                  : null
                  }
                </div>
                : null
                }
                <div className={styles.exploreFooter_socials}>
                  <SocialButtons
                    isMobile={isMobile}
                    isExploreUrl={true}
                    className={modalOpen ? styles.disabled : ''}
                  />
                  <Audio
                    playAudio={playAudio}
                    setPlayAudio={setPlayAudio}
                    className={styles.exploreFooter_audio}
                  />
                </div>
                </>
              )
            }
          </>
        )
        : (
          <>
            <div className={styles.exploreFooter_thinkers}>
              {/* <FeaturedThinkersImage 
                thinkers={thinkers}
              /> */}
            </div>
            {/* <Button
              onClick={openFilter}
              className={classnames(styles.buttonNext, isFilter || active ? styles.buttonNextClose : '')}
            >
              <Filter />
            </Button> */}
          </>
        )
      }
    </div>
  )
});