import React, { memo, useEffect, useRef, useState } from 'react';
import styles from './crumbs.module.scss';
import classnames from 'classnames';
import { MapOfAnswer, MapOfQuestion } from '../../redux/reducers/questionReducer/questionReducer';
import { ScrollDrag } from '../../HOC';
import Lottie from 'react-lottie';
import crumbsAnimation from '../../constants/lottie/crumbs.json';

interface CrumbsProps {
  questions: MapOfQuestion | null,
  current: number | null,
  answers: MapOfAnswer | null,
  setCurrent: (value: number) => void,
  isMobile: boolean,
};

let timer;

export const Crumbs: React.FC<CrumbsProps> = memo(({
  questions,
  answers,
  current,
  setCurrent,
  isMobile,
}) => {
  const [lastCurrent, setLastCurrent] = useState<number | null>(null);
  const [lastLength, setLastLength] = useState(0);
  const [newQuestion, setNewQuestion] = useState(false);
  const [animCoord, setAnimCoord] = useState(0);
  const [animWidth, setAnimWidth] = useState(0);
  const [animationStart, setAnimationStart] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const defaultOptions = {
    loop: false,
    autoplay: true, 
    animationData: crumbsAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const activeHrWidth = (): [number, string] => {
    if (questions && answers) {
      let computation = (Object.keys(answers).length - 1) / (Object.keys(questions).length - 1);
      if (computation < 0) {
        computation = 0;
      }

      return [computation, `calc(((100% - 36px) * ${computation}`]
    }
    return [0, '0'];
  }

  const getCoordinate = (element: HTMLDivElement | null, id: number) => {
    if (element && current && id === current - 1 && current !== lastCurrent) {
      clearTimeout(timer);
      setAnimationStart(false);
      setLastCurrent(current);
      const coordinate = element.getBoundingClientRect();
      const left = coordinate.left;
      const top = coordinate.top;
      document.dispatchEvent(new CustomEvent('grace_request_crumb_particle', {
        detail: {
          x: left,
          y: top,
        }
      }))

      setAnimCoord(element.offsetLeft);
    }
  }

  useEffect(() => {
    if (ref.current) {
      let length = ref.current.offsetWidth / 11;
      if (length < 60) {
        length = 60;
      }
      if (!isMobile) {
        length = length * 3;
      } else {
        length = length * 2;
      }
      
      setAnimWidth(length);
    }
  }, [ref, lastLength]);

  useEffect(() => {
    if(current !== null && current >= 0) {
      const length = 100 * activeHrWidth()[0];
      if (lastLength < length) {
        setLastLength(length);
        setNewQuestion(true);
      }
    }
  }, [current, answers]);
  
  useEffect(() => {
    if (lastLength > 0) {
      setNewQuestion(true);
      setAnimationStart(true);
      clearTimeout(timer);
      timer = setTimeout(() => {
        setNewQuestion(false);
        setAnimationStart(false);
      }, 2500);
    }
  }, [lastLength]);
  return (
    <ScrollDrag className={isMobile ? classnames(styles.crumbs, styles.mobile) : styles.crumbs}>
      <>
      <div className={styles.animationWrapper} style={{left: animCoord}}>
        <Lottie
          options={defaultOptions}
          width={animWidth}
          height={!isMobile ? 80 : 40}
          isStopped={!animationStart}
        />
      </div>
      {
        questions && Object.entries(questions).map((question, index) => {
          const id = question[1].id;
          const idCheck = answers && (answers[id] || id === current);
          const length = answers ? Object.keys(answers).length : 0;
            return (
              <React.Fragment key={id}>
                <div
                  ref={(element) => getCoordinate(element, id)}
                  className={styles.crumbWrapper}
                  onClick={() => idCheck ? setCurrent(id) : null}
                >
                  <div className={idCheck ? styles.crumbGradient : ''}/>
                  <div
                    className={
                      idCheck
                      ? current === id
                      ? newQuestion
                      ? classnames(styles.crumb, styles.crumb_animation)
                      : classnames(styles.crumb, styles.crumb_active, styles.crumb_current)
                      : classnames(styles.crumb, styles.crumb_active)
                      : styles.crumb
                    }
                  />
                </div>
              </React.Fragment>
            )
        })
      }
      <div className={styles.customHr} ref={ref} />
      <div className={styles.customHr_active}style={{width: activeHrWidth()[1]}}/>
      </>
    </ScrollDrag>
  )
});