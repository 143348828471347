import React, { memo, useEffect, useState } from 'react';
import blueSprite from '../../images/blue_marker_linear_spritesheet.png';
import greenSprite from '../../images/green_marker_linear_spritesheet.png';
import sci1Sprite from '../../images/blue_marker_linear_sci1_spritesheet.png';
import sci2Sprite from '../../images/blue_marker_linear_sci2_spritesheet.png';
import sci3Sprite from '../../images/blue_marker_linear_sci3_spritesheet.png';
import sci4Sprite from '../../images/blue_marker_linear_sci4_spritesheet.png';
import sci5Sprite from '../../images/blue_marker_linear_sci5_spritesheet.png';

interface KeynoteParticleAnimationProps {
    isMobile: boolean,
    isUseScientist?: boolean,
    scientistId?: number,
    isBlue?: boolean, // if not it is green
    width: number,
    height: number,
    shouldRotate?: boolean
}

const KeynoteParticleAnimation = ({
    isMobile,
    isUseScientist = false,
    scientistId = 1,
    isBlue = false,
    width,
    height,
    shouldRotate = true,
}: KeynoteParticleAnimationProps) => {
    
    const MAX_FRAMES = 12;
    const SRC_WIDTH = 512;
    const SRC_HEIGHT = 512;
    
    // The counter
    const [count, setCount] = useState<number>(0)
    // The counter
    const [rot, setRot] = useState<number>(0)
    // Dynamic delay
    const [delay, setDelay] = useState<number>(50)

    useEffect(() => {
        const intervalId = setInterval(() => {
            var i = count + 1;
            var r = rot + 10;
            if(i >= MAX_FRAMES){
                i = 0;
            }
            if(r > 360){
                r = 0
            }
          setCount(i);
          if(shouldRotate){
            setRot(r);
          }
        //   console.log(count);
        }, delay) // in milliseconds
        return () => clearInterval(intervalId)
      }, [count])

    const getSrc = ():string => {
        if(isUseScientist && scientistId){
            switch(scientistId){
                case 1: return sci1Sprite;
                case 2: return sci2Sprite;
                case 3: return sci3Sprite;
                case 4: return sci4Sprite;
                case 5: default: return sci5Sprite;
            } 
        }else{
            if(isBlue){
                return blueSprite;
            }else{
                return greenSprite;
            }
        }
    }

    return (
        <img src={getSrc()} 
            alt="my-sprite" 
            width={SRC_WIDTH} 
            height={SRC_HEIGHT}
            style={{
                objectFit: 'none',
                objectPosition: `${(SRC_WIDTH * count)*-1}px 0`,
                transform: `scale(${width/SRC_WIDTH}, ${height/SRC_HEIGHT}) rotate(${rot}deg)`,
            }}
            />
    );
}
export default memo(KeynoteParticleAnimation);