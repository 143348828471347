import React, { memo, useEffect, useState } from 'react';

/**
 * 
 * NOTE: this component currently ONLY works with sprite sheets of ONE row
 */

interface KeynoteImageSequenceAnimationProps {
    isMobile: boolean,
    width: number,
    height: number,
    frames: number,
    currentFrameLerp: number,
    imagePathPrefix: string,
    className?: string,
    opacity?: number,
}

const KeynoteImageSequenceAnimation = ({
    isMobile,
    width,
    height,
    frames,
    currentFrameLerp,
    imagePathPrefix,
    className,
    opacity = 1
}: KeynoteImageSequenceAnimationProps) => {
    
    // The current src file to show
    const [src, setSrc] = useState<string | null>()

    useEffect(() => {
        // lerp is between 0 and 1 so calculate what the actual frame number of the sequence is
        const currentFrame = Math.floor(currentFrameLerp * (frames-1))+1;
        let finalFrame = Number.isNaN(currentFrame) ? 1 : currentFrame;
        let zerofilled = ('00'+finalFrame).slice(-3);
        let filePath = `${imagePathPrefix}${zerofilled}.jpg`;
        // console.log(`Setting src to ${filePath}`);
        setSrc(`${filePath}`);
    }, [currentFrameLerp]);

    return (
        src != null ?
        <div className={className}>
            <img src={src}
                alt="image sequence sprite"
                width={width} 
                height={height}
                style={{
                    objectFit: 'cover',
                    opacity: opacity,
                }}
                />
        </div>
            : <></>
    );
}
export default memo(KeynoteImageSequenceAnimation);