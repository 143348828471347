import React, { memo, useEffect, useState } from "react";
import styles from "./keynoteVideoPage.module.scss";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import ReactPlayer from "react-player";
import { Play } from "../svgIcons";
import Button from "../button";
import { pathName } from "../../constants/constants";
import Lottie from "react-lottie";
import animData from "../../constants/lottie/scrollIcon.json";

interface KeynoteVideoPageProps {
  isMobile: boolean;
}

const KeynoteVideoPage = ({ isMobile }: KeynoteVideoPageProps) => {
  const history = useHistory();
  const videoClickHandler = () => {
    document.dispatchEvent(new CustomEvent("grace_request_stop_audio"));
  };
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: animData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={styles.aboutWrapper}>
      <div className={styles.about}>
          <div className={styles.about_title}>Grace's keynote</div>
          <img className={styles.horizontal_line} src='/images/horizontalLine.png' alt='horizontal line' />
          <div
              className={
                  isMobile
                  ? classnames(styles.thinkerVideo, styles.mobileVideo)
                  : styles.thinkerVideo
              }
              >
              <ReactPlayer
                  url="https://www.youtube.com/watch?v=6TIOmoy0Ld8"
                  light="./images/graces_keynote_poster_image.jpg"
                  playIcon={<div className={styles.videoPlayButton}><Play /></div>}
                  controls={true}
                  onPlay={videoClickHandler}
              />
              </div>
          <div className={styles.description}>
              <p style={{marginBottom:'2em'}}>Last year, I called upon the scientific community for mentorship with the question: 'Can I be a scientist, but can I still be me?''. I was truly amazed by what followed. I heard from thousands of unique perspectives, and now I want to share what they taught me, including how your insights can help us create a more inclusive and diverse science industry.</p> 
          </div>

          {/* <div className={styles.headerScrollIcon}>
              <Lottie
                  options={animationOptions}
                  width={32}
                  height={32}
              />
          </div>
          <div className={styles.about_title}>Roundtable Discussion</div>
          <img className={styles.horizontal_line} src='/images/horizontalLine.png' alt='horizontal line' />
          <div
              className={
                  isMobile
                  ? classnames(styles.thinkerVideo, styles.mobileVideo)
                  : styles.thinkerVideo
              }
              >
              <ReactPlayer
                  url="https://www.youtube.com/watch?v=kjRhYSDT8VA"
                  light="./images/about_video_placeholder.png"
                  playIcon={<div className={styles.videoPlayButton}><Play /></div>}
                  controls={true}
                  onPlay={videoClickHandler}
              />
              </div>
          <div className={styles.description}>
              <p style={{marginBottom:'2em'}}>Last year, I called upon the scientific community for mentorship with the question: 'Can I be a scientist, but can I still be me?''. I was truly amazed by what followed. I heard from thousands of unique perspectives, and now I want to share what they taught me, including how your insights can help us create a more inclusive and diverse science industry.</p> 
          </div> */}
      </div>
    </div>
  );
};
export default memo(KeynoteVideoPage);
