import React, { memo, useEffect, useState } from 'react';
import { Answer, Question } from '../../constants/types';
import styles from './question.module.scss';
import classnames from 'classnames';

interface QuestionProps {
  question: Question,
  length: number,
  current: number,
  answer: Answer | null,
  setAnswer: (value: Answer) => void,
  setQuestionComplete: (value: boolean) => void,
  currentQuestion: number | null,
  isMobile: boolean,
};

export const QuestionComponent: React.FC<QuestionProps> = memo(({
  question,
  length,
  current,
  answer,
  setAnswer,
  setQuestionComplete,
  currentQuestion,
  isMobile,
}) => {

  const { Component, data } = question;
  const [modal, setModal] = useState<JSX.Element | null>(null);
  const [newQuestion, setNewQuestion] = useState(false);

  const mobileClass = (scss) => {
    if (isMobile) {
      return scss.type_mobile;
    } else {
      return ''
    }
  }

  useEffect(() => {
    let timer;
    if (modal) {
      timer = setTimeout(() => {
        setModal(null);
      }, 6500);
    }
    return () => clearTimeout(timer);
  }, [modal]);

  useEffect(() => {
    if (current && !modal) {
      setNewQuestion(true);
      setTimeout(() => setNewQuestion(false), 100);
    }
  }, [current]);

  return (
    <div className={isMobile ? classnames(styles.question, styles.question_mobile) : styles.question}>
      <div className={styles.titleWrapper}>
         <div className={styles.title}>
          {`QUESTION ${current} of ${length}`}
        </div>
        {/* <div className={styles.age}>
          {
            data && `Grace Age ${data.age}`
          }
        </div> */}
      </div>
     
      {
        Component && !newQuestion
        ? (
          <Component
            question={question}
            setAnswer={setAnswer}
            answer={answer}
            setQuestionComplete={setQuestionComplete}
            currentQuestion={currentQuestion}
            isMobile={isMobile}
            setModal={setModal}
            mobileClass={mobileClass}
          />
        )
        : null
      }
      {modal}
    </div>
  )
});