import React from 'react';
import styles from './input.module.scss';
import classnames from 'classnames';

interface InputProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  isInvalid?: boolean,
  wrapperClass?: string,
}

const TextAreaComponent = ({
  className,
  isInvalid,
  wrapperClass,
  ...rest
}: InputProps) => {

  return (
    <div className={classnames(styles.textareaWrapper, wrapperClass)}>
       <textarea
        className={className}
        {...rest}
      />
    </div>

   
  );
};

TextAreaComponent.defaultProps = { isInvalid: false, };
TextAreaComponent.displayName = 'Input';

export const TextArea = React.memo(TextAreaComponent);
