import React, { memo, useEffect, useState } from 'react';
import styles from './KeynotePage.module.scss';
// import BackgroundImage from '../../images/graceKeynoteHeadingHero.png';
import Button from '../button';
// import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { pathName } from '../../constants/constants';
import Lottie from 'react-lottie';
import animData from '../../constants/lottie/scrollIcon.json';

interface KeynotePageHeadingProps {
    isMobile: boolean,
    scrollToElementId: string,
}

const KeynotePageHeading = ({
    isMobile,
    scrollToElementId,
  }: KeynotePageHeadingProps) => {

    const history = useHistory();

    const animationOptions = {
        loop: true,
        autoplay: true, 
        animationData: animData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
    <div className={styles.header} >
        <div className={styles.innerContainer}>
            <h2>Grace’s keynote</h2>
            <h1 style={{textAlign: 'center', marginBottom: '1em'}}>The Change Hypothesis</h1>
            <Button
                onClick={() => {
                    history.push(pathName.keynotePage);
                }}
                className={styles.buttonNext}
                style={{marginTop:'0.5em'}}
                buttonStyles={styles.buttonNextWrapper}
                >
                WATCH THE KEYNOTE
            </Button>
            <div className={styles.headerScrollIcon}>
                <p className={styles.scrollPromptText}>Scroll down to follow Grace's Journey</p>
                <Lottie
                    options={animationOptions}
                    width={32}
                    height={32}
                />
            </div>
            {/* <div className={styles.headerFooterLinkContainer}>
                <p>Every voice matters.
                    <span className={styles.check_terms} onClick={() => history.push(pathName.questionPage) }>
                    {`Answer Grace's questions`}
                    </span> and join the conversation.
                </p>
            </div> */}
        </div>
    </div>
    )
  }
export default memo(KeynotePageHeading);

