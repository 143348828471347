import React, { useEffect, useState } from 'react';
import { SelectProps } from '../../constants/types';
import styles from './questionsType.module.scss';
import classnames from 'classnames';
import Button from '../button';
import { ConfirmButton } from './confirmButtons';
import cloneDeep from 'lodash.clonedeep';
import { Comment } from './isComplete/comment';
import firebaseService from '../../firebase/firebase';
import { closeQuestion, dispatchImageChange } from '../../helpers/util';

let timer;

export const OneRadio: React.FC<SelectProps> = ({
  question,
  setAnswer,
  answer,
  setQuestionComplete,
  currentQuestion,
  isMobile,
  mobileClass,
}) => {
  const [isComplete, setIsComplete] = useState(false);
  const [secondStep, setSecondStep] = useState(false);
  const [isOpacity, setIsOpacity] = useState(false);

  const handleSetAnswer = (value: string, number: number) => {
    if (answer) {
      const newAnswer = cloneDeep(answer);
      newAnswer.data.answer = value;
      if(newAnswer.data.comment) {
        newAnswer.data.comment = '';
      }
      setAnswer(newAnswer);
    }
  }

  const handleConfirm = () => {
    setIsComplete(true);
    closeQuestion(timer, setQuestionComplete);
  };

  const handleSkip = () => {
    firebaseService.handleSkipEvent({"question": currentQuestion});
    setIsComplete(true);
    closeQuestion(timer, setQuestionComplete);
  };

  const handleSetComment = (value: string) => {
    if (answer) {
      const newAnswer = cloneDeep(answer);
      newAnswer.data.comment = value;
      if(newAnswer.data.answer) {
        newAnswer.data.answer = '';
      }
      setAnswer(newAnswer);
    }
  };

  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    timer = setTimeout(() => setIsOpacity(true), 100);
  }, []);

  useEffect(() => {
    if(!answer) {
      setAnswer({
        id: question.id,
        data: {
          answer: '',
          comment: '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, answer]);

  useEffect(() => {
    if (answer) {
      if (answer.data.comment.length === 1 && !secondStep) {
        dispatchImageChange(question.id, 2);
        setSecondStep(true);
      }
    }
  }, [answer, secondStep, question]);

  return (
    <div className={isComplete ? classnames(styles.typeQuestion, styles.typeConfirm, mobileClass(styles)) : classnames(styles.typeQuestion, isOpacity ? styles.typeAnimation : '', mobileClass(styles))}>
      {question && (
        <>
          <div className={styles.question_title}>
            {
            question.data.question.map((q, index) => (
              <div key={index} className={styles.inner_title}>
                {q}
              </div>
            ))
            }
          </div>
          <div className={classnames(styles.radio, styles.radio_one)}>
            {
              question.data.attributes.map((attr, index) => (
                <div className={styles.radio_wrapper} key={index}>
                  <div className={styles.radio_container}>
                  <Button
                    className={answer && answer.data.answer === attr
                      ? classnames(styles.radioButton, styles.radioButton_active) : styles.radioButton}
                    onClick={() => handleSetAnswer(attr, index)}
                    buttonStyles={styles.radioButtonContainer}
                  />
                  <div className={styles.radio_content}>
                    {attr}
                  </div>
                  </div>
                </div>
              ))
            }
          </div>
        </>
      )}
      <Comment
        answer={answer ? answer.data.answer : ''}
        setComment={handleSetComment}
        comment={answer ? answer.data.comment : ''}
        label={question ? question.data.label : ''}
      />
      <ConfirmButton
        isComplete={true}
        isConfirm={false}
        handleConfirm={handleConfirm}
        handleSkip={handleSkip}
        isMobile={isMobile}
        disabled={isComplete}
        currentQuestion={currentQuestion}
      />
    </div>
  )
}