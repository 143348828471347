
import React, { memo, useEffect, useState } from 'react';
import { ThinkerData } from '../../constants/types';
import Button from '../button';
import styles from './startPage.module.scss';
import { pathName, testData } from '../../constants/constants';
import { useHistory } from "react-router-dom";
import classnames from 'classnames';
import { ThinkerDescription } from '../thinker';
import { ThinkerVideoWrapper } from '../thinkerVideoWrapper';
import KeynotePopup from '../keynotePopup';
import KeynoteBanner from '../keynoteBanner';
import KeynotePage from '../keynotePage/KeynotePage';

interface StartPageProps {
  thinkers: ThinkerData[],
  isMobile: boolean,
  setOpenModal: () => void,
  setOpenKeynoteModal: () => void,
  setCurrentModalData: (value: JSX.Element) => void,
  setModalClassName: (value: string) => void,
  openModal: boolean,
  openKeynoteModal: boolean,
  closeModal: () => void,
}

const StartPage = ({
  thinkers,
  isMobile,
  setOpenModal,
  setOpenKeynoteModal,
  setCurrentModalData,
  setModalClassName,
  openModal,
  openKeynoteModal,
  closeModal,
}: StartPageProps) => {
  const [currentThinker, setCurrentThinker] = useState<ThinkerData | null>(null);
  const history = useHistory();

  useEffect(() => {
    if (!openModal) {
      setCurrentThinker(null);
    }
  }, [openModal])

  useEffect(() => {
    if(currentThinker) {
      setCurrentModalData(
        <ThinkerDescription
          isMobile={isMobile}
          currentThinker={currentThinker}
        />
      );
      setOpenModal();
    }
  }, [currentThinker]);

  useEffect(() => {
    // console.log(`openKeynoteModal:${openKeynoteModal}`);
    if(!openKeynoteModal && !isMobile){
      setModalClassName(styles.keynoteModal);
      setCurrentModalData(
        <KeynotePopup 
        isMobile={isMobile}
        closeModal={closeModal}
        />
      );
      setOpenKeynoteModal();
      setOpenModal();
    }
  },[openKeynoteModal])

  return (
    <>
      {/*
          The Keynote page was in another section and is now being moved up to the front page. We'll be replacing the old Keynote page with the new `KeynoteVideoPage` component.
      */}
      <KeynotePage isMobile={isMobile} />
      {/*
          They may want to add this little banner at the bottom of the screen. It is supposed to stick to the footer but this page now scrolls so you'll notice that it slides up the page if enabled. We'll try to avoid using it.
      */}
      {/* <KeynoteBanner
          isMobile={isMobile}
          buttonClassName={styles.aboutButton}
          underlineClassName={styles.hrefLine}
          openCloseHandler={(isOpen) => console.log(isOpen)}
        /> */}
    </>
  )
}

export default memo(StartPage);