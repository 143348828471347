import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './questionsType.module.scss';
import cloneDeep from 'lodash.clonedeep';
import { SelectProps } from '../../constants/types';
import { ConfirmButton } from './confirmButtons';
import { TextArea } from '../input';
import classnames from 'classnames';
import firebaseService from '../../firebase/firebase';
import { closeQuestion, dispatchImageChange } from '../../helpers/util';

let timer;

export const EnterTextArea: React.FC<SelectProps> = ({
  question,
  setAnswer,
  answer,
  setQuestionComplete,
  currentQuestion,
  isMobile,
  mobileClass,
}) => {
  const [isComplete, setIsComplete] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [secondStep, setSecondStep] = useState(false);
  const [isSetStep, setIsSetStep] = useState(false);
  const [isOpacity, setIsOpacity] = useState(false);
  const { Confirm } = question;

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (answer) {
      const value = event.target.value;
      const newAnswer = cloneDeep(answer);
      newAnswer.data.answer = value;
      setAnswer(newAnswer);
    }
  };

  const handleConfirm = () => {
    if (!isConfirm && Confirm) {
      setIsConfirm(true);
    } else {
      setIsComplete(true);
      closeQuestion(timer, setQuestionComplete);
    }
  };

  const handleSkip = () => {
    firebaseService.handleSkipEvent({"question": currentQuestion});
    setIsComplete(true);
    closeQuestion(timer, setQuestionComplete);
  };

  useEffect(() => {
    setIsComplete(false);
  }, [currentQuestion])

  useEffect(() => {
    if(!answer) {
      setAnswer({
        id: question.id,
        data: {
          answer: '',
          comment: '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, answer]);

  useEffect(() => {
    if (answer) {
      if (answer.data.answer.length === 1 && secondStep) {
        dispatchImageChange(question.id, 2);
        setSecondStep(false);
      }
    }
  }, [answer, secondStep, question]);

  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    timer = setTimeout(() => setIsOpacity(true), 100);
  }, []);

  useEffect(() => {
    if (question && question.data.step && !isSetStep) {
      setSecondStep(question.data.step);
      setIsSetStep(true);
    }
  }, [question]);

  return (
    <div className={isComplete ? classnames(styles.typeQuestion, styles.typeConfirm, mobileClass(styles)) : classnames(styles.typeQuestion, isOpacity ? styles.typeAnimation : '', mobileClass(styles))}>
      {
        !isConfirm
        ? (
          question && (
            <>
            <div className={styles.question_title}>
              {
              question.data.question.map((q, index) => (
                <div key={index} className={styles.inner_title}>
                  {q}
                </div>
              ))
              }
            </div>
            <div className={styles.textareaWrapper}>
              <TextArea 
                value={answer ? answer.data.answer : ''}
                onChange={handleChange}
                className={styles.textarea}
                placeholder='Type your comment here...'
              />
            </div>
            </>
          )
        )
          : (
            question && Confirm && (
              <Confirm
                question={question}
                answer={answer}
                setIsComplete={setIsComplete}
                setAnswer={setAnswer}
              >
                <div className={styles.title}>
                  {answer?.data.answer}
                </div>
              </Confirm>
            )
          )
      }
      <ConfirmButton 
        isComplete={isComplete}
        isConfirm={!Confirm}
        handleConfirm={handleConfirm}
        handleSkip={handleSkip}
        isMobile={isMobile}
        disabled={isComplete}
        currentQuestion={currentQuestion}
      />
    </div>
  )
};