import React, { memo, useEffect, useState } from 'react';
import styles from './KeynotePage.module.scss';

interface KeynotePageHeadingImageProps {
    isMobile: boolean,
    imageUrl:string,
}

const KeynotePageHeadingImage = ({
    isMobile,
    imageUrl
  }: KeynotePageHeadingImageProps) => {
  
    return (
    <div className={styles.header} style={{backgroundImage: `url(${imageUrl})`}}>
    </div>
    )

  }
export default memo(KeynotePageHeadingImage);

