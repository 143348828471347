import React, { memo } from 'react';
import Button from '../button';
import { MuteIcon } from '../svgIcons/icons';
import styles from './audio.module.scss';
import Lottie from 'react-lottie';
import musicData from '../../constants/lottie/music.json';
import noMusicData from '../../constants/lottie/no_music.json';

interface AudioProps {
  playAudio: boolean,
  setPlayAudio: () => void,
  className?: string,
}

export const Audio: React.FC<AudioProps> = memo(({
  playAudio,
  setPlayAudio,
  className,
}) => {
  const musicOptions = {
    loop: true,
    autoplay: true, 
    animationData: musicData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const noMusicOptions = {
    loop: true,
    autoplay: true, 
    animationData: noMusicData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <div className={styles.audio}>
      <Button
        onClick={setPlayAudio}
        className={className}
      >
        { playAudio ? (
          <Lottie
            options={musicOptions}
            width={32}
            height={32}
          />
        ) 
        : (
          <Lottie
            options={noMusicOptions}
            width={32}
            height={32}
          />
        )  }
      </Button>
    </div>
  )
})