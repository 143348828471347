import React, { memo, useEffect, useRef, useState } from 'react';
import { Modal } from '../modal';
import styles from './greeting.module.scss';
import classnames from 'classnames';
import Button from '../button';

interface GreetingProps {
  active: boolean,
  setActive: (value: boolean) => void,
  isMobile: boolean,
}

export const GreetingPage: React.FC<GreetingProps> = memo(({
  active,
  setActive,
  isMobile,
}) => {
  
  const ref = useRef<HTMLVideoElement | null>(null);
  const [complete, setComplete] = useState(false);

  const handleClick = () => {
    if (ref.current) {
      ref.current.pause();
      setComplete(true);
      setTimeout(() => {
        setActive(false);
      }, 1200);
    }
  }


  useEffect(() => {
    if(ref.current) {
        ref.current.play().catch(error => {
          console.log(error.message);
          setActive(false);
        })

        ref.current.addEventListener('ended', () => {
          setComplete(true);
          setTimeout(() => {
            setActive(false);
          }, 1200);
        })
    }
  }, [ref]);
  return (
    <Modal
      className={!complete ? styles.greeting : classnames(styles.greeting, styles.greeting_close)}
    >
      <video onClick={handleClick} ref={ref} src={`/video/Intro_${window.innerWidth > window.innerHeight ? "Landscape" : "Portrait"}.mp4`} playsInline muted />
      <div className={styles.video_buttonWrapper}>
        <Button
          onClick={handleClick}
          className={styles.skipButton}
          >
          Skip intro
        </Button>
      </div>
    </Modal>
  )
});