import React from 'react';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  isInvalid?: boolean;
}

const InputComponent = ({
  label,
  className,
  isInvalid,
  ...rest
}: InputProps) => (
  <div>
    {label && <div>{label}</div>}
    <input
      className={className}
      {...rest}
    />
  </div>
);

InputComponent.defaultProps = { isInvalid: false };
InputComponent.displayName = 'Input';

export const Input = React.memo(InputComponent);
