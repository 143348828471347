import React, { memo, useEffect, useRef, useState } from "react";
import Button from "../button";
import styles from "./audio.module.scss";
import classnames from "classnames";
import { Micro, Pause, Play } from "../svgIcons";
import Countdown from "react-countdown";
import { stopAllAudio } from "../../helpers/util";

const maxTime = 30;

interface RecordSoundProps {
  active: boolean,
  onClick: (value: number) => void,
  blob: string,
  deleteAudio: () => void,
  isMobile: boolean,
  answerTime?: string | number | null,
  isBlocked?: boolean,
}

const timeToString = (number: string | number | null) => {
  let value: string | number | null = number;
  if (value === null) {
    return value;
  }
  if (String(value).length === 1) {
    value = "0" + value;
  }
  if (isNaN(Number(value))) {
    value = maxTime;
  }
  return value;
}

export const RecordSound: React.FC<RecordSoundProps> = memo(
  ({
    active,
    blob,
    deleteAudio,
    onClick,
    isMobile,
    answerTime = null,
    isBlocked,
     ...rest 
    }) => {
    const [currentTime, setCurrentTime] = useState<string | number | null>(timeToString(answerTime));
    const [complete, setComplete] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [audioStart, setAudioStart] = useState(false);
    const [animationStopped, setAnimationStopped] = useState(false);
    const [animationPaused, setAnimationPaused] = useState(true);

    const ref = useRef<HTMLAudioElement | null>(null);
    const clockRef = useRef<Countdown | null>(null);
    const checkAudio = () => {
      if (!blob ) {
        if (!active) {
          onClick(Number(currentTime));
        } else {
          handleStop();
        }
      } else if (ref.current) {
        if (audioStart) {
          ref.current.pause();
          // console.log("paused!");
          setAnimationPaused(true);
          setAudioStart(false);
        } else {
          stopAllAudio();
          ref.current.currentTime = 0;
          ref.current.play()
          .then(() => {
            // console.log("playing!");
            setAudioStart(true);
            setAnimationPaused(false);
          }).catch((error) => {
            console.log(error.message);
          })
        }
      }
    }

    const tryAgain = () => {
      deleteAudio();
      onClick(Number(currentTime));
    }

    const countUpdate = (time: string | number) => {
      if (!answerTime) {
        let value: string | number | null = maxTime - Number(time);
        value = timeToString(value);
        setCurrentTime(value);
        onClick(Number(value));
        setComplete(true);
        setDeleted(true);
      }
      setComplete(false);
    }

    const renderer = ({ seconds, completed }) => {
      let value: string | number | null = maxTime - seconds;
      value = timeToString(value);
      if (active) {
        // console.log(`active:${currentTime}`);
        return (
          <div className={classnames(styles.timer, Number(value) > 26 ? styles.timerError : '')}>
            00:{value}
          </div>
        );
      } else {
        // console.log(`NULL`);
        return null;
      }
    };

    const audioRenderer = ({ seconds, completed }) => {
      let value: string | number | null = Number(currentTime) - seconds;
      value = timeToString(value);
      if (completed) {
        value = timeToString(maxTime);
        // console.log(`completed:${value}`);
      }
      return (
        <div className={styles.timer}>
          00:{value}
        </div>
      );
    };

    const handleStop = () => {
      if (clockRef.current) {
        clockRef.current?.pause();
      }
    }

    useEffect(() => {
      if (!blob) {
        setCurrentTime(null);
        setDeleted(false);
      }
    }, [blob]);

    useEffect(() => {
      if (ref.current) {
        ref.current.addEventListener('ended', () => {
          // console.log("ended!");
          setAudioStart(false);
          setAnimationPaused(true);
        });
      }
    }, [ref]);
    return (
      <div className={classnames(styles.recordWrapper, isMobile ? styles.mobileRecord : '')}>
        <audio ref={ref} src={blob} />
        <Button
          onClick={checkAudio}
          {...rest}
          className={
            isBlocked
            ? classnames(styles.record_button, styles.error_button)
            : blob
              ? classnames(styles.record_button, styles.disabled_button)
              : active
              ? classnames(styles.record_button, styles.active_button)
              : styles.record_button
          }
        >
          {
            !blob
            ? !active
            ? <Micro />
            : <Pause />
            : audioStart
            ? <Pause />
            : <Play />
          }
        </Button>
        <div className={styles.description}>
          {
            isBlocked? (
              <>
              <div className={styles.record_error}>
                To leave a message you need to enable your browser microphone permissions
              </div>
              </>
            )
            : (
              <>
                <div className={styles.tutorial}>
                  {
                    !blob
                    ? !active
                    ? (
                      <>
                        <p>
                          {
                            isMobile ? 'Tap' : 'Click'
                          }
                          {' the microphone to start recording '}
                        </p>
                        max length {maxTime}secs
                      </>
                    )
                    : <p>                          
                      {
                        isMobile ? 'Tap ' : 'Click '
                      } 
                      to stop recording
                      </p>
                    : (
                      <p>Thank you for adding your voice. Want to re-record? Click below.</p>
                    )
                  }
                </div>
                {active || blob ? (
                  <div className={styles.timerContainer}>
                      {
                        !currentTime
                        ? (
                          <Countdown 
                            onPause={(event) => countUpdate(event?.seconds)}
                            ref={clockRef}
                            date={Date.now() + maxTime * 1000}
                            renderer={renderer}
                            onComplete={checkAudio}
                          />
                        )
                        : !audioStart
                        ? (
                          <div className={styles.timer}>
                            00:{currentTime}
                          </div>
                        )
                        : (
                          <Countdown
                            date={Date.now() + Number(currentTime) * 1000}
                            renderer={audioRenderer}
                            onComplete={() => setAudioStart(false)}
                          />
                        )
                      }
                    {blob && !audioStart ? (
                      <div>
                      <Button onClick={deleteAudio} className={styles.deleteButton}>
                        DELETE
                      </Button>
                      <Button onClick={tryAgain} className={styles.deleteButton}>
                        TRY AGAIN
                      </Button>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className={styles.rules}>
                    
                  </div>
                )}
              </>
            )
          }
        </div>
      </div>
    );
  }
);