// import THREE, { Object3D } from 'three';
import * as THREE from 'three';
import InteractiveControls from './controls/InteractiveControls';
import Particles from './particles/Particles';

export default class WebGLView {

	constructor(app, tiers, particleRandomness, scrollLerp) {
		this.app = app;
		this.tiers = tiers;
		this.particleRandomness = particleRandomness;
		this.scrollLerp = scrollLerp;
		this.isSuspended = false;
		this.imageData = {
			'landscape': {
				'1':'./images/keynote_particles/keynote_particles_1.jpg',
				'2':'./images/keynote_particles/keynote_particles_2.jpg',
				'3':'./images/keynote_particles/keynote_particles_3.jpg',
				'4':'./images/keynote_particles/keynote_particles_4.jpg',
			},
			'portrait': {
				'1':'./images/keynote_particles/keynote_particles_1.jpg',
				'2':'./images/keynote_particles/keynote_particles_2.jpg',
				'3':'./images/keynote_particles/keynote_particles_3.jpg',
				'4':'./images/keynote_particles/keynote_particles_4.jpg',
			}
		};
		this.detailImageData = {
			'landscape': {
				'1':'./images/keynote_particles/keynote_particles_1_D.jpg',
				'2':'./images/keynote_particles/keynote_particles_2_D.jpg',
				'3':'./images/keynote_particles/keynote_particles_3_D.jpg',
				'4':'./images/keynote_particles/keynote_particles_4_D.jpg',
			},
			'portrait': {
				'1':'./images/keynote_particles/keynote_particles_1_D.jpg',
				'2':'./images/keynote_particles/keynote_particles_2_D.jpg',
				'3':'./images/keynote_particles/keynote_particles_3_D.jpg',
				'4':'./images/keynote_particles/keynote_particles_4_D.jpg',
			}
		};
		this.initThree();
		this.initParticles();
		this.initControls();
	}

	initThree() {
		// scene
		this.scene                = new THREE.Scene();
		// this.scene.background = new THREE.Color('rgb(36, 42, 59)');
		// this.backScene            = new THREE.Scene();
		// this.backScene.background = new THREE.Color(0x242A3B);
		// this.efxScene = new THREE.Scene();

		// camera
		this.camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 1, 10000);
		this.camera.position.z = 300;
		this.camera.position.x = 0;

		// renderer
        let ratio = window.devicePixelRatio
        if ( ratio > 1 ) ratio = 1;

        ratio *= 0.7;

        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha:false, preserveDrawingBuffer: false, powerPreference:'high-performance' });
        this.renderer.outputEncoding = THREE.sRGBEncoding;
		this.renderer.setClearColor( 0x141221, 1 );
        this.renderer.setPixelRatio( ratio );
        this.renderer.autoClear = false; 

        // clock
		this.clock = new THREE.Clock(true);
	}

	initControls() {
		this.interactive = new InteractiveControls(this.camera, this.renderer.domElement);
	}

	initParticles() {
		if(this.tiers.isMobile == false){
			this.particles = new Particles(this, 8, 10, 0.4, this.particleRandomness/8, this.scrollLerp);
			this.scene.add(this.particles.container);
		}
		this.midParticles = new Particles(this, 4, 8, 0.6, this.particleRandomness/4, this.scrollLerp);
		this.scene.add(this.midParticles.container);
		this.detailParticles = new Particles(this, 1, 2, 1.0, this.particleRandomness, this.scrollLerp);
		this.scene.add(this.detailParticles.container);
	}
	// ---------------------------------------------------------------------------------------------
	// PUBLIC
	// ---------------------------------------------------------------------------------------------

	update(updatedParticleRandomness, updatedScrollLerp) {
		// console.log(`${this.currImage} / ${updatedScrollLerp}`);
		this.particleRandomness = updatedParticleRandomness;
		this.scrollLerp = updatedScrollLerp;
		if (!this.renderer || this.isSuspended) return;
		const delta = this.clock.getDelta();
		if (this.particles) this.particles.update(delta, this.particleRandomness/8, this.scrollLerp);
		if (this.midParticles) this.midParticles.update(delta, this.particleRandomness/4, this.scrollLerp);
		if (this.detailParticles) this.detailParticles.update(delta, this.particleRandomness, this.scrollLerp);
	}

	draw() {
		if (!this.renderer || this.isSuspended) return;
		this.renderer.clear();
		this.renderer.clearDepth();
		this.renderer.render(this.scene, this.camera);
	}

	goto(imagereference) {
		if (!this.renderer || this.isSuspended) return;
		// grab the image URI using the device orientation and Question id as Keys
		let isLandscape = window.innerWidth > window.innerHeight;
		let imagePath = this.imageData[isLandscape ? 'landscape' : 'portrait'][imagereference];
		let detailImagePath = this.detailImageData[isLandscape ? 'landscape' : 'portrait'][imagereference];
		if(imagePath == null || detailImagePath == null) return;

		// init particles
		if(this.particles) this.particles.init(imagePath);
		if(this.midParticles) this.midParticles.init(imagePath);
		if(this.detailParticles) this.detailParticles.init(detailImagePath);

		this.currImage = imagePath;
		this.currDetailImage = detailImagePath;
	}

	// ---------------------------------------------------------------------------------------------
	// EVENT HANDLERS
	// ---------------------------------------------------------------------------------------------

	resize() {
		if (!this.renderer || this.isSuspended) return;
		this.camera.aspect = window.innerWidth / window.innerHeight;
		this.camera.updateProjectionMatrix();
		this.fovHeight = 2 * Math.tan((this.camera.fov * Math.PI) / 180 / 2) * this.camera.position.z;
		this.renderer.setSize(window.innerWidth, window.innerHeight);

		if (this.interactive) this.interactive.resize();
		if (this.particles) this.particles.resize();
		if (this.midParticles) this.midParticles.resize();
		if (this.detailParticles) this.detailParticles.resize();
	}
}
