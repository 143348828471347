import React, { memo } from 'react';
import Button from '..';
import { Filter } from '../../svgIcons';
import styles from '../button.module.scss';

interface FilterVoicesProps {
  onClick?: () => void,
}

export const FilterVoices: React.FC<FilterVoicesProps> = memo(({
  onClick
}) => {
  return (
      <Button
        onClick={onClick}
        className={styles.filterButton}
      >
        <>
        <Filter />
        <p>filter voices</p>
        </>
      </Button>
  )
});