
import WebGLView from './webgl/WebGLView';
import { getGPUTier } from 'detect-gpu';

class ParticleApp {

	constructor() {
		this.currentQuestion = 0;
		this.isInitialised = false;
		this.webgl = null;
	}

	init() {
		let _self = this;
		(async () => {
			const tiers = await getGPUTier();
		   _self.initWebGL( tiers );
		   _self.initCanvas();
		   _self.addListeners();
		   _self.animate();
		   _self.resize();
		   _self.isInitialised = true;
		//    console.log("PARTICLE BACKGROUND INIT");
	   })();
	}

	suspend(){
		// console.log("PARTICLE BACKGROUND SUSPENDING");
		// TODO: stop rendering, processing and clear the data
		this.webgl.isSuspended = true;
	}

	resume(){
		// console.log("PARTICLE BACKGROUND RESUMING");
		// TODO: resume rendering, processing and also get the data again
		this.webgl.isSuspended = false;
		this.initCanvas();
		this.animate();
	}

	initWebGL(tiers) {
		this.webgl = new WebGLView(this, tiers);
	}
	
	initCanvas(){
		document.querySelector('.backgroundParticleAnimation').appendChild(this.webgl.renderer.domElement);
	}

	addListeners() {
		this.handlerAnimate = this.animate.bind(this);
		window.addEventListener('resize', this.resize.bind(this));
		const el = this.webgl.renderer.domElement;
		// el.addEventListener('click', this.click.bind(this));
		document.addEventListener('grace_request_image_change', this.requestImageHandler.bind(this));
		document.addEventListener('grace_request_image_removal', this.requestImageRemoveHandler.bind(this));
		// document.addEventListener('grace_request_crumb_particle', this.requestCrumbParticleHandler.bind(this));
	}

	animate() {
		this.update();
		this.draw();
		this.raf = requestAnimationFrame(this.handlerAnimate);
	}

	// ---------------------------------------------------------------------------------------------
	// PUBLIC
	// ---------------------------------------------------------------------------------------------

	update() {
		if (this.webgl) this.webgl.update();
	}

	draw() {
		if (this.webgl) this.webgl.draw();
	}

	// ---------------------------------------------------------------------------------------------
	// EVENT HANDLERS
	// ---------------------------------------------------------------------------------------------

	resize() {
		if (this.webgl) this.webgl.resize();
	}

	// click(e) {
	// 	this.webgl.next();
	// }

	requestImageHandler(e) {
		if(this.currentQuestion !== e.detail.question){
			// console.log(`Question number: ${e.detail.question}`);
			this.currentQuestion = e.detail.question;
			this.webgl.goto(this.currentQuestion);
		}
	}
		
	requestImageRemoveHandler(e) {
		this.webgl.hideCurrentImage();
	}

	// requestCrumbParticleHandler(e) {
	// 	// console.log(`Question coordinates: ${e.detail.x}/${e.detail.y}`);
	// 	this.webgl.greenParticle.showGreenParticleSpriteAt(e.detail.x, e.detail.y);
	// }
}

const particleApp = new ParticleApp();
export default particleApp;
