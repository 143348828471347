import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ExploreDataType, ThinkerData } from '../../constants/types';
import { controlExploreAudio, goToFullScreen } from '../../helpers/util';
import { FilterPage } from '../filterPage/filterPage';
import { ExploreFooter } from '../footer';
import { VoicesPage } from '../voicesPage';
import { LoadedPage } from './loadedPage';
import styles from './webGL.module.scss';
import firebaseService from '../../firebase/firebase';

interface ParamTypes {
  uniqueID: string | undefined,
}

interface WebGLProps {
  isMobile: boolean,
  thinkers: ThinkerData[],
  // allData: Map<string, ExploreDataType> | null,
  // setAllData: (value: Map<string, ExploreDataType> | null) => void,
  playAudio: boolean,
  checkAudio: () => void,
}

interface DataEvent extends Event {
  detail?: {
    id: string,
  },
}

enum CurrentPage {
  VOICE,
  FILTER,
}

let timer;

export const WebGl: React.FC<WebGLProps> = ({
  isMobile,
  thinkers,
  // allData,
  // setAllData,
  playAudio,
  checkAudio,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isReadyForLaunch, setIsReadyForLaunch] = useState(false);
  const [isDataSelected, setIsDataSelected] = useState(false);
  const [voiceOpen, setVoiceOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [id, setId] = useState<string | null | undefined>(null);
  const [exploreData, setExploreData] = useState<Map<string, ExploreDataType> | null>(null);
  const [currentData, setCurrentData] = useState<ExploreDataType | null>(null);
  const [page, setPage] = useState<CurrentPage | null>(null);
  const [showUser, setShowUser] = useState(false);
  const [hoverData, setHoverData] = useState<ExploreDataType | null | undefined>(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [position, setPosition] = useState({
    top: '0px',
    left: '0px',
  })
  const history = useHistory();
  const { uniqueID } = useParams<ParamTypes>();

  const handleOpenFilter = () => {
    clearTimeout(timer);
    setVoiceOpen(false);
    setFilterOpen(true);
    setIsDataSelected(true);
    setIsModal(true);
    setPage(CurrentPage.FILTER);
    document.dispatchEvent(new CustomEvent('grace_request_filter_open'));
  }
  
  const handleCloseFilter = (event?: () => void) => {
    try {
      timer = setTimeout(() => {
        if (event && event !== undefined) {
          event();
        }
        setIsModal(false);
      }, 2200);
      setFilterOpen(false);
      setShowUser(false);
      setIsDataSelected(false);
      document.dispatchEvent(new CustomEvent('grace_request_filter_closed'));
    } catch (error) {
      if(error instanceof Error){
        console.log(error.message);
      }
    }
  }

  const handleCloseVoice = (event?: () => void) => {
    try {
      timer = setTimeout(() => {
        setCurrentData(null);
        setId(null);
        if (event && event !== undefined) {
          event();
        }
        setIsModal(false);
      }, 2200);
      controlExploreAudio();
    } catch (error) {
      if(error instanceof Error){
        console.log(error.message);
      }
    }
    setVoiceOpen(false);
    setShowUser(false);
    setIsDataSelected(false);
  }

  // useLayoutEffect(() => {
  //   // console.log("EXPLORER MOUNTED");
  //   // console.log(`isLoaded: ${isLoaded} / isReadyForLaunch: ${isReadyForLaunch}`);
  //   // setIsLoaded(true);
  //   // setIsReadyForLaunch(true);
  // }, []);
  
  useEffect(() => {
    // console.log(`DEEPLINK: ${uniqueID}`);
    const exploreResponse = () => {
      firebaseService.getSurveyDocuments(uniqueID).then( (data: Map<string, ExploreDataType>) => {
        setExploreData(data);
        setIsLoaded(true);
        // console.log(data);
        document.dispatchEvent(new CustomEvent('grace_request_data_response',{detail:data}));
      })
      .catch((error) => {
        console.log("Error getting Survey documents: ", error);
      });
    }
    
    // dispatch(setQuestions(testQuestions));
    document.addEventListener('grace_request_explorer_ready', exploreResponse);
    
    return () => {
      document.removeEventListener('grace_request_explorer_ready', exploreResponse);
    }
  }, [uniqueID]);
  
  useEffect(() => {
    const exploreData = (data: DataEvent) => {
      // console.log("EXPLORER DATA");
      setId(data.detail?.id);
      setVoiceOpen(true);
      setFilterOpen(false);
      setIsModal(true);
    }
    const exploreDataIsSelected = () => {
      setIsDataSelected(true);
    }
    const exploreLoaded = () => {
      // console.log("EXPLORER LOADED");
      setIsLoaded(true);
      if(uniqueID == null){
        document.dispatchEvent(new CustomEvent('grace_request_start_launching',));
      }
    }
    const launchRequestHandler = () => {
      // console.log("REQUEST LAUNCH");
      setIsReadyForLaunch(true);
    }
    
    document.addEventListener('grace_request_explorer_loaded', exploreLoaded)
    document.addEventListener('grace_request_view_explorer_data', exploreData);
    document.addEventListener('grace_request_view_explorer_data_is_selected', exploreDataIsSelected);
    document.addEventListener('grace_request_start_launching', launchRequestHandler);
    
    return () => {
      document.removeEventListener('grace_request_view_explorer_data', exploreData);
      document.removeEventListener('grace_request_explorer_loaded', exploreLoaded);
      document.removeEventListener('grace_request_view_explorer_data_is_selected', exploreDataIsSelected);
      document.removeEventListener('grace_request_start_launching', launchRequestHandler);
    }
  }, []);

  useEffect(() => {
    const setPositionUserInfo = (event: any) => {
      const surveyInfoContainerElement = document.querySelector("#SurveyInfoContainer") as HTMLElement;
      if(surveyInfoContainerElement){
        const elementWidth = surveyInfoContainerElement!.offsetWidth;
        const newPosition = event.detail.position;
        const maxLeft = window.screen.width;
        let left = newPosition.x / newPosition.ratio - (elementWidth/2);
        const distance = event.detail.distance;
        let yChange = 180*(distance/1.5);
        let top = newPosition.y / newPosition.ratio - yChange / distance;
        if (left - 150 >= maxLeft) {
          left = left - 200;
        }
        setPosition({
          left: `${left}px`,
          top: `${top}px`
        });
      }
    }

    const mouseOverHandler = (event: any) => {
      const id: string = event.detail.id;
      const user = exploreData?.get(id);
      setHoverData(user);
      if(!isDataSelected){
        setShowUser(true);
      }
    }
    const mouseOutHandler = (event: any) => {
      setShowUser(false);
    }

    if (exploreData) {
      document.addEventListener('grace_request_particle_mouse_over', mouseOverHandler)
      document.addEventListener('grace_request_particle_mouse_move', setPositionUserInfo)
      document.addEventListener('grace_request_particle_mouse_out', mouseOutHandler)
    }
    return () => {
      document.removeEventListener('grace_request_particle_mouse_over', mouseOverHandler)
      document.removeEventListener('grace_request_particle_mouse_move', setPositionUserInfo)
      document.removeEventListener('grace_request_particle_mouse_out', mouseOutHandler)
    }
  }, [exploreData])

  useEffect(() => {
    if (id && exploreData) {
      const newData = exploreData.get(id);
      if (newData) {
        setCurrentData(newData);
        setIsModal(true);
        setVoiceOpen(true);
        setPage(CurrentPage.VOICE);
      }
      
    }
  }, [id, exploreData]);

  const launchButtonHandler = () => {
    // console.log("LAUNCH EXPLORER");
    setIsReadyForLaunch(true);
    document.dispatchEvent(new CustomEvent('grace_request_start_launching',));
    document.dispatchEvent(new CustomEvent('grace_request_deeplink_launch',));
  }

  return (
    <>
      <LoadedPage
        isMobile={isMobile}
        isLoaded={isLoaded}
        isReadyForLaunch={isReadyForLaunch}
        isUseLaunchButton={uniqueID != null}
        launchButtonHandler={launchButtonHandler}
        isShowLoadScreen={true}
      />
        {
          isModal
          ? (
            <>
            {
              page === CurrentPage.VOICE
              ? (
                <VoicesPage
                    isMobile={isMobile}
                    active={voiceOpen}
                    setActive={setVoiceOpen}
                    // autoPlay={uniqueID == null}
                    autoPlay={true}
                    setId={setId}
                    currentData={currentData}
                    setCurrentData={setCurrentData}
                    handleClose={handleCloseVoice}
                />
              )
              : (
                <FilterPage
                    isMobile={isMobile}
                    active={filterOpen}
                    setActive={setFilterOpen}
                    handleClose={handleCloseFilter}
                    setAllData={setExploreData}
                />
              )
            }
            </>
          )
          : null
        }
        <ExploreFooter
          modalOpen={false}
          playAudio={playAudio}
          setPlayAudio={checkAudio}
          isMobile={isMobile}
          thinkers={thinkers}
          active={isModal}
          openFilter={handleOpenFilter}
          isFilter={filterOpen}
          isShowFilterButton={isReadyForLaunch}
      /> 
      {
        !isDataSelected && showUser && !voiceOpen && !filterOpen && !isModal
        ? (
          <div id="SurveyInfoContainer" className={styles.user_info} style={{left: position.left, top: position.top}}>
            <div className={styles.fullName}>
              {`${hoverData?.userData?.firstName} ${hoverData?.userData?.lastName}`}
            </div>
            <div className={styles.location}>
              {hoverData?.userData?.location}
            </div>
          </div>
        )
        : null
      }
    </>
  )
};