import React, { memo, useEffect, useState } from 'react';
import styles from './KeynotePopup.module.scss';
import { useHistory } from "react-router-dom";
import classnames from 'classnames';
import Button from '../button';
import { pathName } from '../../constants/constants';
import HeroImage from '../../images/graceKeynotePopupHero.png';

interface KeynotePopupProps {
  isMobile: boolean,
  closeModal: () => void,
}

const KeynotePopup = ({
  isMobile,
  closeModal,
}: KeynotePopupProps) => {
  const history = useHistory();

  return (
    <div 
        className={
          isMobile
          ? classnames(styles.keynotePopupWrapper, styles.mobileKeynotePopupWrapper)
          : styles.keynotePopupWrapper
        }
      >
      <img src={HeroImage} alt="Grace Keynote hero image" className={
        isMobile
        ? styles.mobileHeroImage
        : styles.heroImage
        }
        />
      <div className={styles.headerText}>
        {/* <h2>July 21, 2022<br />World Premiere</h2> */}
        <h2>World Premiere</h2>
      </div>
      <h2>Grace's keynote</h2>
      <h1>The Change Hypothesis</h1>
      <p style={{marginBottom:'1em'}}>Last year, I called upon the scientific community for mentorship with the question: 'Can I be a scientist, but can I still be me?''. I was truly amazed by what followed. I heard from thousands of unique perspectives, and now I want to share what they taught me, including how your insights can help us create a more inclusive and diverse science industry.</p>
      <Button
        onClick={() => {
          closeModal();
          history.push(pathName.keynotePage);
        }}
        className={styles.buttonNext}
        buttonStyles={styles.buttonNextWrapper}
        >
        WATCH THE EVENT
      </Button>
    </div>
  )
}

export default memo(KeynotePopup);