import React, { memo, useEffect, useState } from 'react';
import { ExploreDataType, SubType } from '../../constants/types';
import firebaseService from '../../firebase/firebase';
import { controlExploreAudio } from '../../helpers/util';
import { ExploreModal } from '../modal';
import styles from './voicesPage.module.scss';
import { AudioType } from './voicesTypes/audioType';
import { MessageType } from './voicesTypes/messageType';
import classnames from 'classnames';

interface VoicesPageProps {
  isMobile: boolean,
  active: boolean,
  setActive: (value: boolean) => void,
  setId: (value: string | null | undefined) => void,
  autoPlay: boolean,
  currentData: ExploreDataType | null,
  setCurrentData: (value: ExploreDataType | null) => void,
  handleClose: (value?: () => void) => void,
};

export const VoicesPage: React.FC<VoicesPageProps> = memo(({
  isMobile,
  active,
  setId,
  autoPlay,
  currentData,
  setCurrentData,
  handleClose,
}) => {
  const [isSocial, setIsSocial] = useState(false);
  const [audioStart, setAudioStart] = useState(false);
  const [audioLoading, setAudioLoading] = useState(true);
  const [subtitles, setSubtitles] = useState<SubType[]>([]);

  const closeWindowEvent = () => {
    document.dispatchEvent(new CustomEvent('grace_request_close_explorer_detail', {
      detail: {
        data: 'close voice page',
      }
    }));
  }

  const closeVoice = () => {
    setIsSocial(false);
    handleClose(closeWindowEvent);
  }

  useEffect(() => {
    const endAudio = () => {
      closeVoice();
    }

    const audio = document.querySelector('.explore-audio');
    audio?.addEventListener('ended', () => endAudio());

    return () => {
      document.querySelector('.explore-audio')?.removeEventListener('ended', () => endAudio());
    }
  }, []);

  useEffect(() => {
    const audio = document.querySelector('.explore-audio');

    const audioStateHandler = (event: any) => {
      // console.log(`AUDIO STATE = ${event}`);
    }
    const loadedAudioHandler = (event: any) => {
      // console.log('AUDIO HAS NOW LOADED DATA');
      setAudioLoading(false);
    }
    const audioPlayingHandler = (event: any) => {
      if (event.target.currentTime > 0.1){
        document.dispatchEvent(new CustomEvent('grace_request_audio_play'));
        // console.log('AUDIO IS NOW PLAYING');
        // document.dispatchEvent(new CustomEvent('grace_request_audio_context'));
        setAudioLoading(false);
        setAudioStart(true);
        audio?.removeEventListener('timeupdate', audioPlayingHandler);
      }
    }
    const initAudio = async () => {
      if (currentData) {

        if (currentData.type === 0 && currentData.audioUrl) {
          // subtitles
          try {
            const newSubs = await firebaseService.parseSRTTextToObjectFromDocumentId(String(currentData.id));
            if (newSubs) {
              setSubtitles(newSubs);
            }
          } catch (error) {
            if(error instanceof Error){
              console.log(error.message);
            }
          }

          // we ask the audio to play by passing in a URL but we need to listen to make sure it is loaded
          setAudioLoading(true);
          setAudioStart(true);
          audio?.addEventListener('timeupdate', audioPlayingHandler);
          audio?.addEventListener('loadeddata', loadedAudioHandler);
          audio?.addEventListener('state', audioStateHandler);

          // Load the audio file (could error if autoplay policy is in force)
          if(autoPlay){
            controlExploreAudio(currentData.audioUrl)
            .catch(error => {
              console.log(error.message);
              setAudioLoading(false);
              setAudioStart(false);
            })
          }else{
            setAudioLoading(false);
            setAudioStart(false);
          }
        }
      }
    }
    initAudio();
    return () => {
      audio?.removeEventListener('loadeddata', loadedAudioHandler);
      audio?.removeEventListener('timeupdate', audioPlayingHandler);
      audio?.removeEventListener('state', audioStateHandler);
    }

  }, [currentData]);

  useEffect(() => {
    return () => {
      if (active) {
        setCurrentData(null);
        setId(null);
        document.dispatchEvent(new CustomEvent('grace_request_close_explorer_detail', {
          detail: {
            data: 'close voice page',
          }
        }));
      }
    }
  }, []);

  const navigatorClick = (event: string) => {
    handleClose(() => document.dispatchEvent(new CustomEvent(event)));
  }

  return (
    <ExploreModal
      isMobile={isMobile}
      active={active}
      setActive={closeVoice}
      title='Back to voices'
    >
      <div className={classnames(styles.voicesContainer, currentData && currentData.type !== 0 ? styles.voicesMessage : '')}>
        {
          currentData
          ? currentData.type === 0
          ? (
            <AudioType
              isMobile={isMobile}
              isSocial={isSocial}
              setIsSocial={() => setIsSocial(s => !s)}
              navigatorClick={navigatorClick}
              audioStart={audioStart}
              setAudioStart={setAudioStart}
              audioLoading={audioLoading}
              setAudioLoading={setAudioLoading}
              user={currentData.userData}
              docId={currentData.id}
              audio={currentData.audioUrl}
              errorClose={closeVoice}
              sub={subtitles}
            />
          )
          : (
            <MessageType
              isMobile={isMobile}
              isSocial={isSocial}
              setIsSocial={() => setIsSocial(s => !s)}
              navigatorClick={navigatorClick}
              user={currentData.userData}
              docId={currentData.id}
              message={currentData.message}
            />
          )
          : null
        }
      </div>
    </ExploreModal>
  )
});