import React, { ChangeEvent, FocusEvent, memo, useEffect, useRef, useState } from 'react';
import styles from '../submitPage.module.scss';
import { useField } from 'formik';
import classnames from 'classnames';
import { useWindowSize } from '../../../hooks';
import { HIDDEN_ERROR_BREAKPOINT } from '../../../constants/constants';

interface SubmitInputProps {
  title: string,
  name: string,
  type: string,
  className?: string,
  pattern?: string,
  min?: number,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  customBlur?: (event: FocusEvent<HTMLInputElement>) => void,
  error?: string,
  touched?: true | '',
  required?: string,
  isMobile?: boolean,
  placeholder?: string,
}

export const SubmitInput: React.FC<SubmitInputProps> = memo(({
  title,
  error,
  touched,
  required,
  customBlur,
  onChange,
  isMobile,
  placeholder = 'Select...',
  ...props
}) => {
  const [field] = useField(props);
  const ref = useRef<HTMLInputElement | null>(null);
  const [errors, setErrors] = useState('');
  const {width} = useWindowSize();
  const hiddenError = width < HIDDEN_ERROR_BREAKPOINT;
  
  useEffect(() => {
    if (error && touched) {
      setErrors(error);
    } else {
      setErrors('');
    }
  }, [error, touched])

  return (
    <div className={classnames(styles.submitInput, errors ? styles.inputError : ref.current && ref.current.value ? styles.inputTouched : '')}>
      <div className={styles.title}>
        <div className={required}>
        {title}
        </div>
        <div className={styles.errors}>
          {
            !hiddenError || isMobile ? errors : null
          }
        </div>
      </div>
      <div className={styles.inputWrapper}>
        <div className={styles.inputAnimation}/>
        <input
          ref={ref}
          placeholder={placeholder}
          {...field}
          {...props}
          onBlur={customBlur}
        />
      </div>
    </div>
  )
});