import React, { memo } from 'react';
import Button from '../button';
import { CloseVoice } from '../svgIcons';
import styles from './modal.module.scss';
import classnames from 'classnames';

interface ExploreModalProps {
  children:React.ReactNode,
  active: boolean,
  setActive: () => void,
  isMobile: boolean,
  title: string,
  hideButton?: boolean,
}

export const ExploreModal: React.FC<ExploreModalProps> = memo(({
  active,
  setActive,
  children,
  isMobile,
  title,
  hideButton,
}) => {

  return (
    <div
      className={active ? styles.exploreModal : classnames(styles.exploreModal, styles.exploreModal_close)}
    >
      <div className={styles.modal_container}>
          {
            hideButton
            ? null
            : (
              <div
                className={styles.modal_closeButton}
              >
                <Button
                  onClick={setActive}
                  className={styles.closeExplore}
                >
                  <>
                  <p>{title}</p>
                  <CloseVoice />
                  </>
                </Button>
              </div>
            )
          }
          {children}
      </div>
    </div>
  )
});