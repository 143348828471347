import React, { memo, useEffect, useRef, useState } from 'react';
import { stopAllAudio } from '../../helpers/util';
import Button from '../button';
import styles from './submitPage.module.scss';
import classnames from 'classnames';
import { Micro, Pause, Play } from '../svgIcons';
import { Answer } from '../../constants/types';

interface AudioMessageProps {
  answer: Answer | null,
  audio: string,
  setEdit: () => void,
}

export const AudioMessage: React.FC<AudioMessageProps> = memo(
({
  answer,
  audio,
  setEdit,
}) => {
  const [audioStart, setAudioStart] = useState(false);
  const [firstStart, setFirstStart] = useState(false);
  const ref = useRef<HTMLAudioElement>(null);

  const checkAudio = () => {
    if (!firstStart) {
      setFirstStart(true);
    }
    if (ref.current) {
      if (audioStart) {
        ref.current.pause();
        setAudioStart(false);
      } else {
        stopAllAudio();
        ref.current.currentTime = 0;
        ref.current.play()
        .then(() => {
          setAudioStart(true);
        }).catch((error) => {
          console.log(error.message);
        })
      }
    }
  }

  const addTime = () => {
    if (answer && answer.data.time) {
      let time = String(answer.data.time);
      if (time === null) {
        time = '0';
      }
      if (time.length === 1) {
        return `00:0${time}`;
      } else {
        return `00:${time}`;
      }
    } else {
      return `00:00`;
    }
  }

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('ended', () => setAudioStart(false));
    }
  }, [ref]);

  return (
    <div className={styles.audio}>
      <audio ref={ref} src={audio} />
      <div className={styles.audioButton}>
        <Button
          onClick={checkAudio}
          className={audioStart
              ? classnames(styles.record_button, styles.active_button)
              : styles.record_button
          }
        >
          {
            !firstStart
            ? <Micro />
            : audioStart
            ? <Pause />
            : <Play />
          }
        </Button>
        <div className={styles.audio_change}>
          <div className={styles.time}>
            {addTime()}
          </div>
          <Button
            className={styles.audio_edit}
            onClick={setEdit}
          >
            CLICK TO EDIT
          </Button>
        </div>
      </div>
      <div className={styles.message}>
          Your message
      </div>
      <div className={styles.comment}>
        {
          answer ? answer.data.comment : ''
        }
      </div>
    </div>
  )
}
)