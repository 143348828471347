import React, { memo, useEffect, useRef} from 'react';
import { ref } from 'yup';
import { Answer } from '../../constants/types';
import { MapOfAnswer, MapOfQuestion } from '../../redux/reducers/questionReducer/questionReducer';
import { Crumbs } from '../crumbs';
import { QuestionComponent } from '../question';
import styles from './mobileQuestion.module.scss';

interface MobileQuestionProps {
  questions: MapOfQuestion,
  answers: MapOfAnswer,
  setCurrentQuestion: (value: number) => void,
  currentQuestion: number | null,
  handleAnswer: (value: Answer) => void,
  setQuestionComplete: (value: boolean) => void,
  isMobile: boolean,
};

export const MobileQuestion: React.FC<MobileQuestionProps> = memo(({
  questions,
  answers,
  setCurrentQuestion,
  currentQuestion,
  handleAnswer,
  setQuestionComplete,
  isMobile,
}) => {
  const refDiv = useRef<HTMLDivElement>(null);

  return (
    <div ref={refDiv} className={styles.mobileDescription}>
      <div className={styles.crumb}>
        <Crumbs
          questions={questions}
          current={currentQuestion}
          answers={answers}
          setCurrent={setCurrentQuestion}
          isMobile={isMobile}
        />
      </div>
      <div className={styles.questionWrapper}>
        <div className={styles.question}>
          {
            questions && currentQuestion &&
            <QuestionComponent
              question={questions[currentQuestion]}
              length={Object.keys(questions).length}
              current={currentQuestion}
              answer={answers && currentQuestion ? answers[currentQuestion] : null}
              setAnswer={handleAnswer}
              setQuestionComplete={setQuestionComplete}
              currentQuestion={currentQuestion}
              isMobile={isMobile}
            />
          }
        </div>
      </div>
    </div>
  )
});