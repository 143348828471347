import React, { useEffect, useState } from 'react';
import styles from './questionsType.module.scss';
import cloneDeep from 'lodash.clonedeep';
import { SelectProps } from '../../constants/types';
import { ConfirmButton } from './confirmButtons';
import { useRecorder } from '../../hooks/useRecorder';
import { RecordSound } from '../audio';
import { useHistory } from 'react-router-dom';
import { pathName } from '../../constants/constants';
import { Comment } from './isComplete/comment';
import { checkMicrophone, dispatchImageChange } from '../../helpers/util';
import { audioTrackConstraints } from '../../helpers/util';
import classnames from 'classnames';
import Button from '../button';
import firebaseService from '../../firebase/firebase';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder'

let timer;

export const AudioQuestion: React.FC<SelectProps> = ({
  question,
  setAnswer,
  answer,
  setQuestionComplete,
  currentQuestion,
  isMobile,
  mobileClass,
}) => {
  const [isComplete, setIsComplete] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const [isOpacity, setIsOpacity] = useState(false);
  // const [recorder] = useRecorder(64);
  const [recordState, setRecordState] = useState<RecordState | null>(null);
  const history = useHistory();


  const deleteAudio = () => {
    if (answer) {
      setIsRecording(false);
      const newAnswer = cloneDeep(answer);
      newAnswer.data.answer = null;
      newAnswer.data.time = null;
      setAnswer(newAnswer);
    }
  }

  const start = async (time: number) => {
    const isMicrophone = await checkMicrophone(setIsBlocked);
    if (isMicrophone) {
      try {
        setRecordState(RecordState.START)
        setIsRecording(true);
        setIsBlocked(false);
      } catch (error) {
        setIsBlocked(true);
      }
    }
    // if (recorder) {
    //   recorder
    //     .start()
    //     .then(() => {
    //       setIsRecording(true);
    //       setIsBlocked(false);
    //     }).catch(error => {
    //       setIsBlocked(true);
    //       console.log(error.message);
    //     });
    // }
  }

  const stop = (time: number) => {
    try {
      setRecordState(RecordState.STOP)
      setIsRecording(false);
      setIsBlocked(false);
      if (answer) {
        const newAnswer = cloneDeep(answer);
        newAnswer.data.time = time;
        setAnswer(newAnswer);
      }
    } catch (error) {
      setIsBlocked(true);
    }
    // recorder
    //   .stop()
    //   .getMp3()
    //   .then(([buffer, blob]) => {
    //     const mp3Blob = URL.createObjectURL(blob);
    //     const arrayAnswers = Object.entries(answers);
    //     const audioAnswer = arrayAnswers[arrayAnswers.length - 1][1];
    //     if (audioAnswer) {
    //       const newAnswer = cloneDeep(audioAnswer);
    //       newAnswer.data.answer = mp3Blob;
    //       newAnswer.data.time = time;
    //       dispatch(setAnswer(String(arrayAnswers.length), newAnswer));
    //     }
    //     setIsBlocked(false);
    //     setIsRecording(false);
    //   }).catch(error => {
    //     setIsBlocked(true);
    //     console.log(error.message);
    //   });
  }

  const handleConfirm = () => {
    history.push(pathName.submit);
    setIsComplete(false);
  };

  const handleSetComment = (value: string) => {
    if (answer) {
      if (answer.data.comment === '') {
        dispatchImageChange(currentQuestion ? currentQuestion : 1, 2);
      } 
      const newAnswer = cloneDeep(answer);
      newAnswer.data.comment = value;
      setAnswer(newAnswer);
    }
  };

  const handleSkip = () => {
    firebaseService.handleSkipEvent({"question": currentQuestion});
    history.push(pathName.submit);
  };

  const onStop = (audioData) => {
    const mp3Blob = URL.createObjectURL(audioData.blob);
    if (answer) {
      const newAnswer = cloneDeep(answer);
      newAnswer.data.answer = mp3Blob;
      setAnswer(newAnswer);
    }
  }

  const handleChange = () => {
    setIsMessage(m => !m);
    setIsRecording(false);
  }

  useEffect(() => {
    setIsComplete(false);
  }, [currentQuestion]);

  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    timer = setTimeout(() => setIsOpacity(true), 100);
  }, []);

  useEffect(() => {
    checkMicrophone(setIsBlocked);
  }, [])

  useEffect(() => {
    if(!answer) {
      setAnswer({
        id: question.id,
        data: {
          answer: null,
          time: null,
          comment: '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, answer]);
  return (
    <div className={classnames(styles.typeQuestion, isOpacity ? styles.typeAnimation : '', mobileClass(styles))}>
      <div style={{opacity: 0, position: 'absolute'}}>
        <AudioReactRecorder state={recordState} onStop={onStop} />
      </div>
      {
          question && (
            <>
              <div className={styles.question_title}>
                {
                question.data.question.map((q, index) => (
                  <div key={index} className={styles.inner_title}>
                    {q}
                  </div>
                ))
                }
              </div>
              <div className={styles.audioWrapper}>
                <div className={styles.label}>
                  Leave your message below:
                </div>
                {
                  !isMobile? (
                    <div className={styles.audioDesktopWrapper}>
                      {
                        !isMessage
                        ? (
                          <RecordSound
                            active={isRecording}
                            onClick={isRecording ? stop : start}
                            blob={answer ? answer.data.answer : ''}
                            deleteAudio={deleteAudio}
                            isMobile={isMobile}
                            answerTime={answer?.data.time}
                            isBlocked={isBlocked}
                          />
                        )
                        : (
                          <Comment
                            answer={answer ? answer.data.answer : ''}
                            setComment={handleSetComment}
                            comment={answer ? answer.data.comment : ''}
                            className={styles.commentWrapper}
                          />
                        )
                      }
                      <Button
                        onClick={handleChange}
                        buttonStyles={styles.leaveMessageButton}
                      >
                        {isMessage ? 'LEAVE A MESSAGE AS A VOICE RECORDING' : 'LEAVE A MESSAGE AS TEXT'}
                      </Button>
                    </div>
                  )
                  : (
                    <div className={styles.audioDesktopWrapper}>
                      <RecordSound
                        active={isRecording}
                        onClick={isRecording ? stop : start}
                        blob={answer ? answer.data.answer : ''}
                        deleteAudio={deleteAudio}
                        isMobile={isMobile}
                      />
                      <div className={styles.question_mobileMessage}>
                        OR TYPE YOUR MESSAGE
                      </div>
                      <Comment
                        answer={answer ? answer.data.answer : ''}
                        setComment={handleSetComment}
                        comment={answer ? answer.data.comment : ''}
                        className={styles.commentWrapper}
                      />
                    </div>
                  )
                }
              </div>
            </>
          )
      }
      <ConfirmButton 
        isComplete={isComplete}
        isConfirm={false}
        handleConfirm={handleConfirm}
        handleSkip={handleSkip}
        isMobile={isMobile}
        disabled={isComplete}
        currentQuestion={currentQuestion}
      />
    </div>
  )
};