import { Question, Answer } from './../../../constants/types';
import cloneDeep from 'lodash.clonedeep';
import { SET_ANSWER, SET_QUESTION, SET_CURRENT_QUESTION, REMOVE_ALL_ANSWERS, SET_TIME, SET_SKIPPED } from './questionAction';
import { AnyAction } from 'redux';

export interface MapOfAnswer {
  [id: string]: Answer,
}

export interface MapOfQuestion {
  [id: string]: Question,
}

export interface MapOfTime {
  [id: string]: number,
}

export interface MapOfSkipped {
  [id: string]: boolean,
}


export interface QuestionState {
  questions: MapOfQuestion,
  answers: MapOfAnswer,
  currentQuestion: string,
  times: MapOfTime,
  skipped: MapOfSkipped,
}



export const initialState = {
  questions: {},
  answers: {},
  currentQuestion: '',
  times: {},
  skipped: {},
};

export const questionReducer = (state: QuestionState = initialState, action: AnyAction) => {
  let newState = cloneDeep(state);
  switch (action.type) {
    case SET_QUESTION: {
      newState.questions = action.questions;
      return newState;
    }
    case SET_ANSWER: {
      newState.answers = {...newState.answers, [action.id]: action.answer};
      const localAnswer = JSON.stringify(newState.answers);
      localStorage.setItem('answers', localAnswer);
      return newState;
    }
    case SET_CURRENT_QUESTION: {
      newState.currentQuestion = action.question;
      return newState;
    }
    case REMOVE_ALL_ANSWERS: {
      newState.answers = {};
      newState.times = {};
      localStorage.removeItem('answers');
      return newState;
    }
    case SET_TIME: {
      newState.times[action.question] = action.time;
      return newState;
    }
    case SET_SKIPPED: {
      newState.skipped[action.question] = true;
      return newState;
    }
    default: return newState;
  }
};

export const setQuestions = (questions: MapOfAnswer) => ({ type: SET_QUESTION, questions});
export const setSkippedQuestion = (question: string) => ({ type: SET_SKIPPED, question});
export const setQuestionTime = (time: number, question: string) => ({ type: SET_TIME, time, question });
export const removeAnswers = () => ({ type: REMOVE_ALL_ANSWERS });
export const setAnswer = (id: string, answer: Answer) => ({ type: SET_ANSWER, id, answer });
export const setCurrentQuestionAction = (question: string) => ({ type: SET_CURRENT_QUESTION, question });