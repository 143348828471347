import React, { memo, useEffect, useState } from 'react';
import { SubType, UserData } from '../../../constants/types';
import { controlExploreAudio } from '../../../helpers/util';
import Button, { Navigator, ShareButton } from '../../button';
import Lottie from 'react-lottie';
// import loadingAnim from '../../../constants/lottie/audioLoadingLoop.json';
import loadingAnim from '../../../constants/lottie/no_music.json';
import { SocialButtons } from '../../footer/socialButtons';
import { Back, Pause, Play } from '../../svgIcons';
import styles from '../voicesPage.module.scss';
import classnames from 'classnames';
import { useWindowSize } from '../../../hooks';

interface AudioTypeProps {
  isMobile: boolean,
  isSocial: boolean,
  setIsSocial: () => void,
  navigatorClick: (value: string) => void,
  audioStart: boolean,
  setAudioStart: (value: boolean) => void,
  audioLoading:boolean,
  setAudioLoading: (value: boolean) => void,
  user: UserData | null,
  docId: string | null,
  audio: string | null,
  errorClose: () => void,
  sub: SubType[],
}

let time = 0;

export const AudioType: React.FC<AudioTypeProps> = memo(({
  isMobile,
  isSocial,
  setIsSocial,
  navigatorClick,
  audioStart,
  setAudioStart,
  audioLoading,
  setAudioLoading,
  user,
  docId,
  audio,
  errorClose,
  sub,
}) => {
  const [currentSub, setCurrentSub] = useState('');
  const [oldSub, setOldSub] = useState('');
  const [nextSub, setNextSub] = useState('');
  const [interval, setInterval] = useState(0);
  const {width, height} = useWindowSize();
  //const [currentId, setCurrentId] = useState(1);

  const handlePlay = () => {
    if (audio) {

      try { 
        if(audioStart) {
          controlExploreAudio(audio);
          setAudioStart(false);
          document.dispatchEvent(new CustomEvent('grace_request_audio_pause'));
        } else {
          controlExploreAudio(audio);
          setAudioStart(true);
          document.dispatchEvent(new CustomEvent('grace_request_audio_play'));
        }
      } catch (error) {
        console.log(error.message);
        errorClose();
      }
    }
  }

  const getTime = (date: string) => {
    const times = date.split(':');
    const realTime = Number(times[0]) * 60 * 60 + Number(times[1]) * 60 + parseFloat(times[2]);
    return realTime * 1000;
  }

  useEffect(() => {
    
    const timeUpdateHandler = (event: any) => {
      time = event.target.currentTime * 1000;
      for (let i = 0; i < sub.length; i++) {
        const start = getTime(sub[i].startTime);
        const end = getTime(sub[i].endTime);
        if (start < time && time < end) {
          setCurrentSub(sub[i].text);
          if (sub[i - 1]) {
            setOldSub(sub[i - 1].text);
          } else {
            setOldSub('');
          }
          if (sub[i + 1]) {
            setNextSub(sub[i + 1].text);
          } else {
            setNextSub('');
          }
          break;
        }
      }
    }
    if (sub.length > 0) {
      document.querySelector('.explore-audio')?.addEventListener('timeupdate', timeUpdateHandler);
    }
    return () => {
      time = 0;
      document.querySelector('.explore-audio')?.removeEventListener('timeupdate', timeUpdateHandler);
    };
  }, [sub]);

  // useEffect(() => {
  //   // document.addEventListener('grace_request_audio_circle_position', (data) => {
  //     // console.log(data);
  //   // })
  // }, []);2w

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: loadingAnim,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
      <div className={classnames(styles.voices_buttonGroup, isMobile ? styles.voices_buttonMobile : '')}>
        <div className={styles.voices_userInfo}>
          <div className={styles.voices_name}>
            {`${user?.firstName} ${user?.lastName}`}
          </div>
          <div className={styles.voices_country}>
            {user?.location}
          </div>
        </div>
        <div 
          className={styles.voices_playWrapper}
        >
          <Button
            className={styles.voices_playButton}
            disabled={audioLoading}
            onClick={handlePlay}
            >
            {
              audioLoading
              ?
                <Lottie
                options={defaultOptions}
                width={110}
                height={110}
              />
              :
              audioStart 
              ? (
                <>
                  <Pause />
                  <p>Pause</p>
                </>
              )
              : (
                <>
                  <Play />
                  <p>Play</p>
                </>
              )
            }
          </Button>
        </div>
          
        <div className={styles.shareContainer}>
          <div className={styles.share}>
            <ShareButton 
              icon={<Back />}
              title='Share this voice'
              onClick={setIsSocial}
            />
          </div>
          
          {
            isSocial
            ? (
              <div className={styles.voices_social}>
                <SocialButtons
                  isMobile={isMobile}
                  isExploreUrl={true}
                  docId={docId}
                />
              </div>
            )
            : null
          }
        </div>
      </div>
      <div className={classnames(styles.voices_description, isMobile ? styles.voices_mobileDescription : '')}>
        {/* {
          isMobile ? null
          : (
             <div>
              {oldSub}
            </div>
          )
        } */}
          <div className={styles.voices_someone}>
            {currentSub}
          </div>
          {/* {
            isMobile ? null
            : (
              <div>
                {nextSub}
              </div>
            )
          } */}
      </div>
      <div className={classnames(styles.voices_navigatorWrapper, isMobile ? styles.voices_navigatorMobile : '')}>
        <Navigator
          isMobile={isMobile}
          leftClick={() => navigatorClick('grace_request_show_previous_explorer_detail')}
          rightClick={() => navigatorClick('grace_request_show_next_explorer_detail')}
          shuffleClick={() => navigatorClick('grace_request_show_random_explorer_detail')}
        />
      </div>
    </>
  )
});